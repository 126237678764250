import { Component, OnInit } from '@angular/core';
import { Products, ColorFilter, TagFilter } from '../../model/product.model';
import { ProductsService } from '../../service/products.service';

@Component({
  selector: 'vrgo-product-tab',
  templateUrl: './vrgo-product-tab.component.html',
  styleUrls: ['./vrgo-product-tab.component.scss']
})
export class VRGoProductTabComponent implements OnInit {
  public products     :   Products[] = [];
  public activeCategory      : string = 'all';

  constructor(private productsService: ProductsService) { 
        this.getCategoryProduct(this.activeCategory);
  }

  ngOnInit() {
  }

  getCategoryProduct(category){
    this.productsService.getProductByCategory(category).subscribe(products => {           
      this.products = products.slice(0,8)
   })
   this.activeCategory = category
  }

}
