import { Component, OnInit } from '@angular/core';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vrgo-payments-result',
  templateUrl: './vrgo-payments-result.component.html',
  styleUrls: ['./vrgo-payments-result.component.scss']
})
export class VrgoPaymentsResultComponent implements OnInit {

  constructor(
    private param: ParameterUrl,
    private rounter: Router,
    private activeRoute : ActivatedRoute
    ) { }
    

  order : any;
  orderNumber : any;

  ngOnInit() {
    
    this.activeRoute.queryParams.subscribe(params => {
      let date = params['orderNumber'];
      // console.log(date); // Print the parameter to the console. 
    });

    if(this.param.data != null && this.param.data.order != undefined){
      this.order = this.param.data.order;
      // console.log('....',this.order);
    }
  }

  onClickto(){
    if(this.order.status == 'PENDING_PAYMENT'){
      this.rounter.navigate(['./userOrderTransaction']);
    }
    if(this.order.status == 'PAID'){
      this.rounter.navigate(['./upload-vr']);
    }
  }

}
