import { OrderObject } from './orderObject.model';

export class OrderDetail {
    orderDetailId: number;
	orderId: number;
	userId: number;
	orderNumber: string;
	firstName: string;
	lastName: string;
	birthDate: string;
	tel: string;
	email: string;
	gender: string;
	idCard: string;
	note: string;
	pathQr: string;
	status: string;
	createDate: string;
	createBy: string;
	updateDate: string;
	updateBy: string;
	remark: string;
    listOrderDetailObject: OrderObject[];
}