import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { Order } from '../../model/order.model';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
import { EventModel } from '../../share-class/EventModel';
import { OrderDetail } from '../../model/orderDetail.model';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CouponCodeModel } from '../../model/CouponCode.model';
import { CouponModel } from '../../model/coupon.model';

@Component({
  selector: 'app-vrgo-summary-register',
  templateUrl: './vrgo-summary-register.component.html',
  styleUrls: ['./vrgo-summary-register.component.scss']
})
export class VRGoSummaryRegisterComponent implements OnInit {

  @ViewChild('errorMsgbox', { static: false }) errorMsgbox;

  order: Order;
  newOrder: Order;
  eventDetail: any;
  shippingFee: number;
  netAmt: number;
  birthDate: any
  btnsubmit: any = 'ชำระค่าสมัคร';
  currentLesson: any = '1';
  errorMsg: any = '';
  ticketId: any;
  ticketType: any;
  msgPay: any = '';
  orderKey: any = 'KEY';
  language: any = {};
  langStatus: any;
  DI: any;
  shirtColorName: string = ''
  shirtColorValue: string = ''
  listEventImgItem: any = [];
  listEventImage: any = [];
  paymentGatewayFee: any;
  checked;
  constructor(
    private param: ParameterUrl,
    private httClient: HttpClientService,
    private rounter: Router,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private tokenStored: TokenStorageService,
    private translate: TranslateService
  ) {
    this.order = new Order();
    this.order.listOrderDetail = [];
    this.eventDetail = [];
  }

  ngOnInit() {
    this.eventDetail = [];
    this.langStatus = this.tokenStored.getLang();
    if (this.param.data != undefined && this.param.data.order != null) {
      this.order = this.param.data.order;
      // console.log("xxxxxx EMPTY ORDER KEY OR KEY : ", this.tokenStored.getOrderKey());
      // console.log('order >> ', this.order);
      if (this.tokenStored.getOrderKey() != null && this.tokenStored.getOrderKey() != "" && this.tokenStored.getOrderKey() == 'KEY') {
        this.order = new Order;
        this.rounter.navigate(['./']);
      } else {
        // console.log('ticketType  >> ', this.order.ticketType);
        this.ticketId = this.order.ticketId;
        this.eventDetail = this.param.data.eventDetail;
        this.listEventImage = this.eventDetail['listEventImage'];
        this.listEventImgItem = this.param.data.listEventImgItem;
        this.birthDate = this.datePipe.transform(this.order.listOrderDetail[0].birthDate, 'dd/MM/yyyy');
        this.getShirtColor();
        this.translate.addLangs(['th', 'en']);
        this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
          this.language = result;
          // console.log('ticketType  >> ', this.order.ticketType);
          this.getTicketIdFree(this.order.ticketType);
          // console.log('payment method >> ', this.order.paymentMethod);
        });
      }
    } else {
      console.log('error');
      this.rounter.navigate(['./']);
    }
  }

  selectdPaymentMethod(event, id) {
    var checked = event.target.checked;
    this.checked = id;
    if (checked && id == 'CC') {
      this.order.paymentMethod = 'CC';
      this.countNet(this.order.paymentMethod);
    } else if (checked && id == 'DI') {
      this.order.paymentMethod = 'DI';
      this.countNet(this.order.paymentMethod);
    }
  }
  getTicketIdFree(ticketType) {
    // console.log('ticketType >> ', ticketType);
    if (ticketType == 'VR_FREE') {
      this.msgPay = this.language.REGISTER_SUMMARY_PAGE.eventFree;
      this.btnsubmit = this.language.REGISTER_SUMMARY_PAGE.get;
      this.order.paymentMethod = 'FREE';
    } else {
      this.msgPay = this.language.REGISTER_SUMMARY_PAGE.howToPay;
      this.btnsubmit = this.language.REGISTER_SUMMARY_PAGE.confirm;
      this.order.paymentMethod = 'DI';
      this.countNet(this.order.paymentMethod);
    }
    // console.log('payment method  getTicketIdFree >> ', this.order.paymentMethod);
  }
  getShirtColor() {
    if (this.order.listOrderDetail[0].listOrderDetailObject.length != 0) {
      var listOrderDetailObject = this.order.listOrderDetail[0].listOrderDetailObject;
      for (let i = 0; i < listOrderDetailObject.length; i++) {
        if (listOrderDetailObject[i].fieldName == 'shirt_color') {
          this.shirtColorName = listOrderDetailObject[i].fieldLabel;
          this.shirtColorValue = listOrderDetailObject[i].objectDesc;
        }
      }
    }
  }
  countNet(vulue) {
    if (this.order.useCouponStatus == true) {
      if (this.order.shippingStatus == 'ACTIVE') {
        this.order.shippingAmount = this.order.shippingAmount;
      } else {
        this.order.shippingAmount = 0;
      }
      this.order.netAmt = Number(this.order.shippingAmount) + Number(this.order['price']);
      if (vulue == 'CC') {
        this.paymentGatewayFee = Number(this.order.netAmt * Number(this.order.paymentGatewayFee / 100));
        this.order.netAmt = Number(this.order.netAmt - Number(this.order.discountAmt)) + this.paymentGatewayFee;
      } else {
        this.paymentGatewayFee = Number(this.order.netAmt * Number(0.00 / 100));
        this.order.netAmt = Number(this.order.netAmt - Number(this.order.discountAmt)) + this.paymentGatewayFee;
      }
    } else {
      if (this.order.shippingStatus == 'ACTIVE') {
        this.order.shippingAmount = this.order.shippingAmount;
      } else {
        this.order.shippingAmount = 0;
      }
      this.order.netAmt = Number(this.order.shippingAmount) + Number(this.order['price']);
      if (vulue == 'CC') {
        this.paymentGatewayFee = Number(this.order.netAmt * Number(this.order.paymentGatewayFee / 100));
        this.order.netAmt = this.order.netAmt + this.paymentGatewayFee;
      } else {
        this.paymentGatewayFee = Number(this.order.netAmt * Number(0.00 / 100));
        this.order.netAmt = this.order.netAmt + this.paymentGatewayFee;
      }
    }
  }

  paymentOnCilck() {
    this.currentLesson = '2'
    this.btnsubmit = this.language.REGISTER_SUMMARY_PAGE.wait;
    if (this.order.userId === 0) {
      this.errorMsg = this.language.REGISTER_SUMMARY_PAGE.msgError;
      this.modalService.open(this.errorMsgbox, { backdrop: 'static' });
    }
    if (this.order.paymentMethod == 'CC' && this.order.userId != 0) {
      this.order.paymentMethod = 'CC'
      this.order.paymentMethodDesc = 'บัตรเครดิต';
      this.order.status = 'PENDING_PAYMENT';
      this.order.listOrderDetail[0].birthDate = this.birthDate;
      this.httClient.saveOrder(this.order).subscribe(data => {
        if (data.status === 'FAIL') {
          this.errorMsg = this.language.REGISTER_SUMMARY_PAGE.msgError;
          this.modalService.open(this.errorMsgbox, { backdrop: 'static' });
        }
        if (data != null && data.status !== 'FAIL') {
          this.newOrder = data;
          this.param.data = null;
          const dataObj = new Object();
          dataObj['orderNumber'] = this.newOrder.orderNumber;
          dataObj['amount'] = this.newOrder.netAmt;
          dataObj['desc'] = 'VRGO';
          dataObj['orderId'] = this.newOrder.orderId;
          this.param.data = dataObj;
          this.rounter.navigate(['./creditCard2C2P']);
        }
      });

    } else if (this.order.paymentMethod == 'DI' && this.order.userId != 0) {
      this.order.paymentMethod = 'DI'
      this.order.paymentMethodDesc = 'โอนตรงเข้าบัญชี';
      this.order.status = 'PENDING_PAYMENT';
      this.order.listOrderDetail[0].birthDate = this.birthDate;
      this.order['price'];
      this.httClient.saveOrder(this.order).subscribe(data => {
        if (data.status === 'FAIL') {
          this.errorMsg = this.language.REGISTER_SUMMARY_PAGE.msgError;
          this.modalService.open(this.errorMsgbox, { backdrop: 'static' });
        }
        if (data != null && data.status !== 'FAIL') {
          this.newOrder = data;
          this.param.data = {};
          this.param.data.order = this.newOrder;
          this.tokenStored.saveOrderKey(this.orderKey);
          this.httClient.sendMailOrder(data).subscribe(data => { });
          this.rounter.navigate(['./paymentUploadSlip']);
        }
      });

    } else if (this.order.paymentMethod == 'FREE' && this.order.userId != 0) {
      this.order.paymentMethod = 'FREE';
      this.order.paymentMethodDesc = 'กิจกรรมฟรี';
      this.order.status = 'PAID';
      this.order.listOrderDetail[0].birthDate = this.birthDate;
      // console.log('order after save >> ' , this.order);
      this.httClient.saveFreeOrder(this.order).subscribe(data => {
        if (data.status === 'FAIL') {
          this.errorMsg = this.language.REGISTER_SUMMARY_PAGE.msgError;
          this.modalService.open(this.errorMsgbox, { backdrop: 'static' });
        }
        if (data != null && data.status !== 'FAIL') {
          this.param.data = {};
          this.param.data.eventCategory = this.eventDetail.eventCategory;
          this.rounter.navigate(['./paymentSuccess']);
        }
      });
    }

  }

  goBack() {
    if (this.order.useCouponStatus == true) {
      let couponModel = new CouponModel;
      couponModel.couponCodeId = this.order.couponCodeId;
      this.httClient.redeemCouponCode(couponModel).subscribe(data => { });
    }
    this.rounter.navigate(['./registerForm']);
  }

  closeModel() {
    this.modalService.dismissAll();
  }

  retureHome() {
    this.modalService.dismissAll();
    this.rounter.navigate(['./']);
  }


}

