import { Component, OnInit,ViewChild } from '@angular/core';
import { Products, ColorFilter, TagFilter } from '../../model/product.model';
import { ProductsService } from '../../service/products.service';
import { EventModel } from '../../share-class/EventModel';
import { HttpClientService } from '../../service/httpclient.service';


@Component({
  selector: 'app-vrgo-event-spcial',
  templateUrl: './vrgo-event-spcial.component.html',
  styleUrls: ['./vrgo-event-spcial.component.scss']
})
export class VrgoEventSpcialComponent implements OnInit {



  public products     :   Products[] = [];
  public activeCategory      : string = 'all';
  constructor(private productsService: ProductsService,
    ) {
    this.getCategoryProduct(this.activeCategory);
   }

 

  ngOnInit() {
  // console.log('ngOnInit');

  }
  getCategoryProduct(category){
    this.productsService.getProductByCategory(category).subscribe(products => {           
      this.products = products.slice(0,8)
    })
    this.activeCategory = category
    }

  
  
 

}
