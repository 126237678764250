import { Component, OnInit ,HostListener} from '@angular/core';

@Component({
  selector: 'app-vrgo-custom-event',
  templateUrl: './vrgo-custom-event.component.html',
  styleUrls: ['./vrgo-custom-event.component.scss']
})
export class VrgoCustomEventComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 1200;

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // console.log('[scroll]', scrollPosition
    // );

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  constructor() { }

  ngOnInit() {
  }
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
}
