import { Component, OnInit } from '@angular/core';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vrfo-credit-success',
  templateUrl: './vrfo-credit-success.component.html',
  styleUrls: ['./vrfo-credit-success.component.scss']
})
export class VrfoCreditSuccessComponent implements OnInit {
  eventCategory: string;

  constructor( 
    private param: ParameterUrl,
    private rounter: Router,
    private activeRoute : ActivatedRoute) { }

  ngOnInit() {
    this.param 
    if(this.param != undefined && this.param.data != null){
        this.eventCategory = this.param.data.eventCategory
    } 
  }

  gotoMain(){
    this.rounter.navigate(['./']);
  }

  onClickto(){
      this.rounter.navigate(['./userVRTicket']);
  }
  
}
