
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStorageService } from '../auth/token-storage.service';
import { environment } from 'src/environments/environment';
import base64url from 'base64url';
import { AuthenticationService } from './authentication.service';
import { UserProfileModel } from '../model/userProfile.model';

const httpOptions = {
};

@Injectable({
  providedIn: 'root'
})
export class LineApiService {

  constructor(
    private httpClient: HttpClient,
    private authenService: AuthenticationService,
    private storageService : TokenStorageService
  ) {

  }

  clientId: string = environment.LnClientId;
  redirectUri: string = environment.LnRedirectUri;
  clientSecret: string = environment.LnClientSecret;

  // Normal Page //
  
  LineLogin(path) {
    // console.log("path", path);
    window.location.href = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=' + this.clientId + '&redirect_uri=' + this.redirectUri + path + '&state=LN-RU&scope=profile%20openid%20email';
  }

  getAccesToken(param, path) {
    // console.log("AccesToken", path);

    const body = new HttpParams()
      .set(`grant_type`, 'authorization_code')
      .set(`code`, param)
      .set(`redirect_uri`, this.redirectUri +'/'+ path)
      .set(`client_id`, this.clientId)
      .set(`client_secret`, this.clientSecret);
    const url = 'https://api.line.me/oauth2/v2.1/token';
    return this.httpClient.post<any>(url, body.toString(), { headers: { "Content-Type": "application/x-www-form-urlencoded" } }).pipe(catchError(error => {
      return throwError(error)
    }));
  }

  async afterLoginLine(code: string, url: any): Promise<boolean> {
    return await new Promise(resolve => {
      this.getAccesToken(code, url).toPromise().then(async res => {

        (window as any).global = window;
        global.Buffer = global.Buffer || require('buffer').Buffer;
        var openId = await res.id_token
        var splitted = openId.split(".", 3);
        var base64 = base64url.toBase64(splitted[1])
        var object = atob(base64);
        var line = JSON.parse(object);


        const userProfile: any = new Object;
        userProfile['provider'] = "LINE";
        userProfile['id'] = await line['sub'];
        userProfile['firstName'] = "";
        userProfile['lastName'] = "";
        userProfile['email'] = await line['email'];
        userProfile['photoUrl'] = await line['picture'];
        userProfile['providerId'] = await line['sub'];

        this.authenService.createSocialProfile(userProfile).subscribe(
          (response: any) => {

            // console.log("socialAuthentication LINE > ", response);

            this.storageService.saveToken(response.token);
            var userProfileS = new UserProfileModel;
            userProfileS = response.userProfile
            userProfileS.expireDate = response.expireDate;
            this.storageService.saveUserProfile(userProfileS);
            this.storageService.saveUsername(response.username);
            // return url;
            // return true;
            resolve(true);

          });
      });
    });
  }

}
