import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../service/authentication.service';
import { TokenStorageService } from '../../auth/token-storage.service';
import { HttpClientService } from '../../service/httpclient.service';
import { EventModel } from '../../share-class/EventModel';
import { AuthLoginInfo } from '../../auth/login-info';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterInfo } from '../../auth/register-info';
import { AuthService } from 'angular4-social-login';
import { SocialUser } from 'angular4-social-login';
import { GoogleLoginProvider } from 'angular4-social-login';
// import { AuthService, FacebookLoginProvider, SocialUser, GoogleLoginProvider } from 'angularx-social-login/angularx-social-login';
import { Router, ActivatedRoute } from '@angular/router';
import base64url from "base64url";
import { TranslateService } from '@ngx-translate/core';
import { FacebookApiService } from '../../service/facebook-api.service';
import { LineApiService } from '../../service/line-api.service';
import { UserProfileModel } from '../../model/userProfile.model';
import { WirtualApiService } from '../../service/wirtual-api.service';
// import { AuthService, SocialUser,GoogleLoginProvider  } from 'angularx-social-login';


@Component({
  selector: 'app-vrgo-modal-login',
  templateUrl: './vrgo-modal-login.component.html',
  styleUrls: ['./vrgo-modal-login.component.scss'],
})
export class VRGoModalLoginComponent implements OnInit {
  @ViewChild('loginModal', { static: false }) loginModal;
  @ViewChild('emailNull', { static: false }) emailNull;
  @ViewChild('modelRejectReserve', { static: false }) modelRejectReserve;
  @ViewChild('dialogSuccess', { static: false }) dialogSuccess;
  @ViewChild('dialogSuccesserror', { static: false }) dialogSuccesserror;
  @ViewChild('forgetPasswordModal', { static: false }) forgetPasswordModal;
  @Output() messageEvent = new EventEmitter<any>();
  @Output() messageEventCheck = new EventEmitter<any>();

  registerForm: FormGroup;
  loginForm: FormGroup;
  forgetPassForm: FormGroup;

  passwordValidate: boolean = false;
  form: any = {};
  username: string = '';
  password: string = '';
  emailForget: string = '';
  createProfile: any = { password: '', confirmPassword: '', email: '' };
  invalidLogin: boolean = (this.tokenStored.getUsername() == null ? false : true);
  loginInfo: AuthLoginInfo;
  registerInfo: RegisterInfo;
  public loading = false;
  SetAutoCompleteOff: string = 'new-password';
  //---login form---//
  emailLoginClass: string = 'form-control input-genstar';
  emailLoginErrorMsg: string = '';
  passwordLoginClass: string = 'form-control input-genstar';
  passwordLoginErrorMsg: string = '';
  forgetPassErrorMsg: string = '';

  //---register form---//
  emailRegisClass: string = 'form-control input-genstar';
  emailRegisErrorMsg: string = '';
  passwordRegisClass: string = 'form-control input-genstar';
  passwordRegisErrorMsg: string = '';
  confrimPasswordRegisClass: string = 'form-control input-genstar';
  confrimPasswordRegisErrorMsg: string = '';
  registerErrorMsg: string = '';

  //---forget password form---//
  passwordForgetClass: string = 'form-control input-genstar';
  emailForgetErrorMsg: string = '';

  newTrustFormVisible: boolean = false;
  dialogSuccessMessage: string = 'ระบบทำการบันทึกเสร็จเรียบร้อย';
  dialogSuccessMessagefogot: string = 'ทำการกู้คืนรหัสผ่านเรียบร้อยแล้วกรุณาตรวจสอบอีเมล์ของท่าน';
  loading1 = false;

  eventName: any = '';
  code: any = '';
  state: any = '';
  object: any
  accessToken: any
  name: any;
  btnsubmit: any = 'ส่ง';
  currentLesson: any = '3';
  rount: string;
  save(): void {
    this.loading1 = true;
  }
  constructor(
    private modalService: NgbModal,
    private modalRefService: NgbActiveModal,
    private modalRefServiceRegister: NgbActiveModal,
    private modalForgetPassword: NgbActiveModal,
    private modalLoginModal: NgbActiveModal,
    private modalRegisterModal: NgbActiveModal,
    private modalRejectByReason: NgbActiveModal,
    private loginService: AuthenticationService,
    private clientService: HttpClientService,
    private tokenStored: TokenStorageService,
    private config: NgbModalConfig,
    private authService: AuthService,
    private rounter: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private facebookApi: FacebookApiService,
    private lineApi: LineApiService,
    private wirtualApiService : WirtualApiService
  ) {
    this.translate = translate;
    this.translate.setDefaultLang('th');
    config.backdrop = 'static';
    config.keyboard = false;

    this.rount = this.rounter.url
    // console.log("rount > ",this.rounter.url);
  }

  ngOnInit() {
    this.buildRegisterForm();
    this.buildLoginForm();
    this.buildForgetPassForm();
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });

    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.code = params['code'];
    //   this.state = params['state'];
    //   if (this.code !== null && this.code !== undefined && this.code !== '') {
    //     if (this.state == 'LN-RU') {
    //       this.getAccesToken(this.code);
    //     } else if (this.state == 'FB-RU') {
    //       this.getAccesTokenFB(this.code);
    //     }
    //   }
    // });
  }

  private user: SocialUser;
  private loggedIn: boolean;

  buildRegisterForm() {
    this.registerForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
      termOfUseStatus: new FormControl(false , Validators.required),
    });
  }

  get getRegister() { return this.registerForm.controls }

  buildLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
      termOfUseStatus: new FormControl(false , Validators.required),
    });
  }

  get login() { return this.loginForm.controls }

  buildForgetPassForm() {
    this.forgetPassForm = new FormGroup({
      email: new FormControl('')
    });
  }

  gotoEvent() {
    // console.log("go to page", this.tokenStored.getURLPath());
    this.rounter.navigate([this.tokenStored.getURLPath()], { skipLocationChange: true });
  }



  popupConfirm(page) {

    // console.log("page", page);
    this.tokenStored.saveURLPath(page);

    this.emailLoginClass = 'form-control input-genstar';
    this.emailLoginErrorMsg = '';
    this.passwordLoginClass = 'form-control input-genstar';
    this.passwordLoginErrorMsg = '';
    this.username = '';
    this.password = '';
    this.modalLoginModal = this.modalService.open(this.loginModal);
  }

  checkLogin() {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.loginInfo = new AuthLoginInfo(
      this.form.username = this.loginForm.value.email,
      this.form.password = this.loginForm.value.password
    );
    let isNull = 0;
    if (this.form.username === '') {
      isNull = isNull + 1;
      this.emailLoginClass = 'form-control input-genstar-err';
      this.emailLoginErrorMsg = '* กรุณาระบุ Email';
    } else {
      this.emailLoginClass = 'form-control input-genstar';
      this.emailLoginErrorMsg = '';
    }
    if (!this.form.username.match(regex)) {
      isNull = isNull + 1;
      this.emailLoginClass = 'form-control input-genstar-err';
      this.emailLoginErrorMsg = '* กรุณาระบุ Email ให้ถูกต้อง';
      //console.log('NO');
    } else {
      this.emailLoginClass = 'form-control input-genstar';
      this.emailLoginErrorMsg = '';
      //console.log('OK');
    }
    if (this.form.password === '') {
      isNull = isNull + 1;
      this.passwordLoginClass = 'form-control input-genstar-err';
      this.passwordLoginErrorMsg = '* กรุณาระบุ รหัสผ่าน';
    } else {
      this.passwordLoginClass = 'form-control input-genstar';
      this.passwordLoginErrorMsg = '';
    }
    if (isNull == 0) {
      (this.loginService.authenticate(this.loginInfo).subscribe(
        (data: any) => {
          let status = { status: 'success' }
          // //console.log(data);
          if (data['invalidUser'] == true) {
            if (data['errorMessage'] == "INVALID_PASSWORD") {
              let status = { status: 'false' }
              this.passwordLoginClass = 'form-control input-genstar-err';
              this.emailLoginClass = 'form-control input-genstar';
              this.passwordLoginErrorMsg = '   > รหัสผ่านของคุณไม่ถูกต้อง';
              this.emailLoginErrorMsg = '';
              this.invalidLogin = false;
              this.messageEvent.emit(JSON.stringify(status));
            } else if (data['errorMessage'] == "INVALID_USER") {
              let status = { status: 'false' }
              this.emailLoginClass = 'form-control input-genstar-err';
              this.passwordLoginClass = 'form-control input-genstar';
              this.emailLoginErrorMsg = '   > ชื่อผู้ใช้งานของคุณไม่ถูกต้อง';
              this.passwordLoginErrorMsg = '';
              this.invalidLogin = false;
              this.messageEvent.emit(JSON.stringify(status));
            }
          } else {
            // console.log('img',data);
            this.tokenStored.saveToken(data.token);
            this.tokenStored.saveUsername(data.username);

            var userProfile = new UserProfileModel;
            userProfile = data.userProfile;
            userProfile.expireDate = data.expireDate;

            this.tokenStored.saveUserProfile(userProfile);
            this.invalidLogin = true;
            this.modalService.dismissAll();
            this.messageEvent.emit(JSON.stringify(status));
            if (this.tokenStored.getURLPath() != undefined) {
              window.location.href = this.tokenStored.getURLPath();
            } else {
              window.location.reload();
            }
          }
        }, error => {
          let status = { status: 'false' }
          this.emailLoginClass = 'form-control input-genstar-err';
          this.passwordLoginClass = 'form-control input-genstar-err';
          this.emailLoginErrorMsg = '* ชื่อผู้ใช้งาน หรือ รหัสผ่าน ผิดพลาด'
          this.invalidLogin = false;
          this.messageEvent.emit(JSON.stringify(status));
        }
      ))
    }
  }


  onKeydown(event) {
    if (event.key === "Enter") {
      this.checkLogin();
    }
  }

  closeCreateUser() {
    if (this.rount == '/') {
      this.createProfile = { password: '', confirmPassword: '', email: '' };
      this.emailRegisClass = 'form-control input-genstar';
      this.emailRegisErrorMsg = '';
      this.passwordRegisClass = 'form-control input-genstar';
      this.passwordRegisErrorMsg = '';
      this.confrimPasswordRegisClass = 'form-control input-genstar';
      this.confrimPasswordRegisErrorMsg = '';
      this.modalService.dismissAll();
    } else if (this.rount == '/upload-vr') {
      this.createProfile = { password: '', confirmPassword: '', email: '' };
      this.emailRegisClass = 'form-control input-genstar';
      this.emailRegisErrorMsg = '';
      this.passwordRegisClass = 'form-control input-genstar';
      this.passwordRegisErrorMsg = '';
      this.confrimPasswordRegisClass = 'form-control input-genstar';
      this.confrimPasswordRegisErrorMsg = '';
      this.modalService.dismissAll();
      this.rounter.navigate(['/']);
    } else {
      this.createProfile = { password: '', confirmPassword: '', email: '' };
      this.emailRegisClass = 'form-control input-genstar';
      this.emailRegisErrorMsg = '';
      this.passwordRegisClass = 'form-control input-genstar';
      this.passwordRegisErrorMsg = '';
      this.confrimPasswordRegisClass = 'form-control input-genstar';
      this.confrimPasswordRegisErrorMsg = '';
      this.modalService.dismissAll();
    }


  }

  openRegister(content) {
    this.modalService.dismissAll();
    this.modalRegisterModal = this.modalService.open(content);
  }

  openForgetModal(content) {
    this.forgetPassErrorMsg = '';
    this.modalService.dismissAll();
    this.modalRegisterModal = this.modalService.open(content);
  }
  checkForgetPassword() {
    this.forgetPassErrorMsg = '';
    this.currentLesson = '2'
    this.btnsubmit = 'กรุณารอสักครู่...';
    this.clientService.resetPassword(this.emailForget).subscribe(
      data => {
        if (data.code > 0) {
          this.clientService.sendResetPasswordMail(data).subscribe();
          this.forgetPassForm.controls['email'].setValue('');
          this.modalService.dismissAll();
          this.openModal();
        } else {
          this.forgetPassForm.controls['email'].setValue('');
          this.modalService.dismissAll();
          this.openModalerror()
        }
      }, error => this.openModalerror()
    );
  }

  openModalerror() {
    this.currentLesson = '3';
    this.btnsubmit = 'ส่ง';
    this.modalService.open(this.dialogSuccesserror, { backdrop: 'static' });
  }


  register() {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.registerInfo = new RegisterInfo(
      this.form.email = this.registerForm.value.email,
      this.form.password = this.registerForm.value.password,
      this.form.confirmPassword = this.registerForm.value.confirmPassword
    );
    let isNull = 0;
    if (this.form.email === '') {
      isNull = isNull + 1;
      this.emailRegisClass = 'form-control input-genstar-err';
      this.emailRegisErrorMsg = '* กรุณาระบุ Email';
    } else {
      this.emailRegisClass = 'form-control input-genstar';
      this.emailRegisErrorMsg = '';
    }
    if (!this.form.email.match(regex)) {
      isNull = isNull + 1;
      this.emailRegisClass = 'form-control input-genstar-err';
      this.emailRegisErrorMsg = '* กรุณาระบุ Email ให้ถูกต้อง';
    } else {
      this.emailRegisClass = 'form-control input-genstar';
      this.emailRegisErrorMsg = '';
    }
    if (this.form.password === '') {
      isNull = isNull + 1;
      this.passwordRegisClass = 'form-control input-genstar-err';
      this.passwordRegisErrorMsg = '* กรุณาระบุ รหัสผ่าน';
    } else {
      this.passwordRegisClass = 'form-control input-genstar';
      this.passwordRegisErrorMsg = '';
    }
    if (this.form.confirmPassword === '') {
      isNull = isNull + 1;
      this.confrimPasswordRegisClass = 'form-control input-genstar-err';
      this.confrimPasswordRegisErrorMsg = '* กรุณายืนยันรหัสผ่าน';
    } else {
      this.confrimPasswordRegisClass = 'form-control input-genstar';
      this.confrimPasswordRegisErrorMsg = '';
    }
    if (this.form.password != this.form.confirmPassword) {
      isNull = isNull + 1;
      this.registerErrorMsg = '* รหัสผ่านไม่ถูกต้อง';
    } else {
      this.registerErrorMsg = '';
    }

    if (isNull == 0) {

      this.clientService.checkUserAndEmail(this.registerInfo).subscribe(
        data => {
          this.registerErrorMsg = '';
          if (data.code != null && +data.code < 0) {
            this.registerErrorMsg = data.message;
          } else {
            this.clientService.register(this.registerInfo).subscribe(
              data => {
                if (data.code != null && +data.code < 0) {
                  this.registerErrorMsg = data.message;
                }
                if (data.password != null && data.email != null) {
                  this.loginInfo = new AuthLoginInfo(
                    this.form.username = data.email,
                    this.form.password = data.password
                  );
                  this.loginService.authenticate(this.loginInfo).subscribe(
                    (data: any) => {
                      this.tokenStored.saveToken(data.token);
                      this.tokenStored.saveUsername(data.username);
                      let userProfile = data.userProfile;
                      userProfile.expireDate = data.expireDate;
                      this.tokenStored.saveUserProfile(userProfile);
                      this.invalidLogin = true;
                      this.modalService.dismissAll();
                      this.gotoEvent();
                    }, error => {
                      this.invalidLogin = false;
                    }
                  )
                }
                this.modalService.dismissAll();
              }, error => {
              })
          }
        }
      )
    }
  }

  openModal() {
    this.currentLesson = '3';
    this.btnsubmit = 'ส่ง';
    this.modalService.open(this.dialogSuccess, { backdrop: 'static' });
  }

  forgetPassword() {

  }

  LoginByFacebook() {

    if (this.tokenStored.getURLPath() == "/upload-vr") {

      this.facebookApi.facebookLogin(this.tokenStored.getURLPath());

    } else if (this.tokenStored.getURLPath() == "/") {

      this.facebookApi.facebookLogin(this.tokenStored.getURLPath());

    } else if (this.tokenStored.getURLPath() == "/events") {

      this.facebookApi.facebookLogin(this.tokenStored.getURLPath());

    } else {

      this.facebookApi.facebookLoginToEventDetail();

    }

  }

  LoginByGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(Response => (this.afterRegis(Response)));
  }

  LoginByLINE() {
    console.log(this.tokenStored.getURLPath());
    this.lineApi.LineLogin(this.tokenStored.getURLPath());
  }

  afterRegis(Response) {

    this.user['providerId'] = this.user.id;

    this.loginService.createSocialProfile(this.user).subscribe(
      (data : any) => {
        let status = { status: 'success' }
        this.tokenStored.saveToken(data.token);
        this.tokenStored.saveUsername(data.username);
        let userProfileRes = data.userProfile;
        userProfileRes.expireDate = data.expireDate;
        this.tokenStored.saveUserProfile(userProfileRes);
        
        this.invalidLogin = true;
        this.modalService.dismissAll();
        this.messageEvent.emit(JSON.stringify(status));
        this.gotoEvent();
      }, error => {
        this.invalidLogin = false;
      }
    )
  }

  closeModal() {
    this.forgetPassErrorMsg = '';
    this.currentLesson = '3'
    this.btnsubmit = 'ส่ง';
    this.dialogSuccessMessage = '';
    this.modalService.dismissAll();

  }
  closeModalLine() {
    this.modalService.dismissAll();
    this.rounter.navigate(['./']);
  }

  mapProfile(Response) {
    const userFB: SocialUser = new SocialUser;
    userFB['provider'] = 'FACEBOOK';
    userFB['id'] = Response.id;
    userFB['firstName'] = Response.first_name;
    userFB['lastName'] = Response.last_name;
    userFB['email'] = Response.email;
    userFB['photoUrl'] = Response.picture.data.url;
    userFB['providerId'] = userFB['id'];

    this.loginService.createSocialProfile(userFB).subscribe(
      data => {
        let status = { status: 'success' }
        this.tokenStored.saveToken(data.token);
        this.tokenStored.saveUsername(data.username);
        let userProfile = JSON.stringify(data.userProfile);
        this.tokenStored.saveUserProfile(userProfile);
        this.invalidLogin = true;
        this.modalService.dismissAll();
        this.messageEvent.emit(JSON.stringify(status));
        this.mapLoginFacebook(status);
      }, error => {
        this.invalidLogin = false;
      }
    )

  }
  mapLoginFacebook(status) {
    if (status.status == 'success') {
      this.gotoEvent();
    }
  }

  LoginByWirtual() {
    this.wirtualApiService.wirtualLogin();
  }
}

