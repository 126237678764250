import { Component, Injectable, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { VRTicket } from '../../model/vrTicket.model';
import canvasToImage from 'canvas-to-image';
import { Router } from '@angular/router';

@Component({
  selector: 'vrgo-download-ecert',
  templateUrl: './vrgo-download-ecert.component.html',
  styleUrls: ['./vrgo-download-ecert.component.scss']
})
@Injectable()
export class VRGoDownloadECertComponent implements AfterViewInit {

  @ViewChild('canvasEl', { static: false }) canvas: ElementRef;

  imageUrl : string;

  vrTicket: VRTicket;

  eventId: number;
  ticketId: number;
  orderId: number;
  
  
  constructor(
    private httpClient : HttpClientService,
    private rounter: Router,
    private param: ParameterUrl
    ) {}

  ngAfterViewInit() {
    if (this.param.data != null && this.param.data.vrTicket != undefined) {
      this.vrTicket = this.param.data.vrTicket;
      // console.log('status >>',this.vrTicket);
      this.eventId = this.vrTicket.eventId;
      this.ticketId = this.vrTicket.ticketId;
      this.orderId = this.vrTicket.orderId;
    }

    this.getEcertData();
  }

  getEcertData(){
    const param = new Object();
    param['eventId'] = this.eventId;
    param['ticketId'] = this.ticketId;
    param['orderId'] = this.orderId;
    this.httpClient.generateEcert(param).subscribe(respons =>{
      // console.log(JSON.stringify(respons));
      if(respons.imageUrl != null){
        this.imageUrl = respons.imageUrl;
      }
    })
  }

  downloadECert(){
    const link = document.createElement('a');
    link.href = this.imageUrl;
    link.setAttribute('visibility','hidden');
    link.download = 'picture';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  uploadback(){
    this.rounter.navigate(['./userVRTicket']);
  }

}