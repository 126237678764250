import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClientService } from '../../service/httpclient.service';

@Component({
  selector: 'app-vrgo-touris-member',
  templateUrl: './vrgo-touris-member.component.html',
  styleUrls: ['./vrgo-touris-member.component.scss']
})
export class VrgoTourisMemberComponent implements OnInit {
  searchControl: FormControl = new FormControl();
  constructor(
    private httpClient : HttpClientService,
  ) { }

  ClassFirstName : string ='form-control ui-autocomplete-input';
  classLastName : string ='form-control ui-autocomplete-input';
  classEventId : string ='star-label';

  listRunningEvent : any;
  listUserConfirm: any;
  firstName: any = '';
  lastName:any = '';
  eventId : any = '';
  tel:any = '';
  selectedFood1 :any;

  ngOnInit() {
  this.getRunningEvent();
  }
  getRunningEvent(){
    this.httpClient.getRunningEvent().subscribe(data => {
      this.listRunningEvent = data;
    });
  }
  submit(){
    const param = new Object;
    let isNull = 0;
    if(this.firstName == ''){
      isNull = isNull + 1 ;
      this.ClassFirstName = 'form-control ui-autocomplete-input text-error';
    }else{
      this.ClassFirstName = 'form-control ui-autocomplete-input';
    }
    if(this.lastName == ''){
      isNull = isNull + 1 ;
      this.classLastName = 'form-control ui-autocomplete-input text-error';
    }else{
      this.classLastName = 'form-control ui-autocomplete-input';
    }
    if(this.eventId == ''){
      isNull = isNull + 1 ;
      this.classEventId = 'star-label-error';
    }else{
      this.classEventId = 'star-label';
    }
    if(isNull == 0){
      param['firstName'] = this.firstName;
      param['lastName'] = this.lastName;
      param['eventId'] = this.eventId;
      param['tel'] = this.tel;
      // console.log('param >>',param);
      this.httpClient.getUserConfirm(param).subscribe(data => {
        this.listUserConfirm = data;
        // console.log('listUserConfirm >>',this.listUserConfirm);
      });
    }
  }
  changeEvent(){
    // console.log('eventId >>', this.eventId);
  }
  onChange(event, type) {
    const key = event.key;
    if(type === 'NUMBER'){
      if(!key.match('^-?(0|[1-9]\d*)?$')) {
        event.preventDefault();
      }
    }else if(type === 'TEXT'){
      if(!key.match('^[a-zA-Z]')){
        event.preventDefault();
      }
    }else if(type === 'TEL'){
      if(!key.match('^-?(0|[1-9+]\d*)?$')){
        event.preventDefault();
      }
    }else if(type === 'ALL'){
      if(!key.match('^[A-Za-z0-9\.\-\s\\w+,]+$')){
        event.preventDefault();
      }
    }
  }
}
