import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { VRTicket } from '../../model/vrTicket.model';
import { HttpClientService } from '../../service/httpclient.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonPipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { FunctionUtils } from '../../utils/functionUtils';
import { DatePipe } from '@angular/common';
import { TokenStorageService } from '../../auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SocialUser } from 'angular4-social-login';
import { AuthenticationService } from '../../service/authentication.service';

declare var liff: any;
@Component({
  selector: 'app-vrgo-log-upload',
  templateUrl: './vrgo-log-upload.component.html',
  styleUrls: ['./vrgo-log-upload.component.scss']
})
export class VrgoLogUploadComponent implements OnInit {

  @ViewChild('ImgModal', { static: true }) ImgModal;
  @ViewChild('upLoadVr', { static: true }) upLoadVr;
  @ViewChild('upLoadError', { static: true }) upLoadError;
  @ViewChild('ticketSingleAll', { static: true }) ticketSingleAll
  @ViewChild('ticketSingleDay', { static: true }) ticketSingleDay

  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('emailNull', { static: false }) emailNull;


  fileToUpload: File = null;
  filename: string = '';
  uploaded: boolean = false;
  fileData: File = null;
  previewUrl: any = null;
  orderNumber: string = '';
  orderId: any = '';
  vrId: number;
  ticketId: number;
  userId: any;
  distance: string = '';
  location: string = '';
  submited: boolean = false;
  eventId: any;
  listVRLogModel:any [];
  data: any;
  url = '';
  validateUploadLogs: VRTicket ;
  statusTicketVr: any;
  submitStatus: any;
  uploadeds: boolean;

  vrTicket: VRTicket [] = [];
  vrComplete : any;
  CompeletClass : string = 'form-control';
  btnsubmit : any = 'ส่งผลกิจกรรม';
  fieldDataType : any ='NUMBER';
  currentLesson:any = '1';
  uploadVRStatus : any = '';
  second : any = [];
  minute : any = [];
  hour : any = [];
  secondValue : string ;
  minuteValue : string ;
  hourValue : string ;
  period: string = '';
  language : any ;
  imgVrLog : any ;
  userProfile: any;
  idToken: any;
  email: any;
  imageProfile: any;
  invalidLogin: boolean;
  constructor(
    private param: ParameterUrl,
    private service: HttpClientService,
    private rounter: Router,
    private modalService: NgbModal,
    private fn: FunctionUtils,
    private datePipe: DatePipe,
    private tokenStored: TokenStorageService,
    private translate:TranslateService,
    private loginService: AuthenticationService,
  ) {
    var time = new Array(
      '00','01','02','03','04','05','06','07','08','09','10',
      '11','12','13','14','15','16','17','18','19','20',
      '21','22','23','24','25','26','27','28','29','30',
      '31','32','33','34','35','36','37','38','39','40',
      '41','42','43','44','45','46','47','48','49','50',
      '51','52','53','54','55','56','57','58','59','60',);
    this.second = time ;
    this.minute = time ;

    // if (liff.isInClient()) {
    //   this.initLineLiff();
    // } else {
      this.default();
    // }
    
   }

  ngOnInit() {
    this.getListBox();
  }

  default(){
    this.data  = new  Object;
    if (this.param.data != null && this.param.data.vrTicket != undefined) {
      this.vrTicket = this.param.data.vrTicket;
      this.vrId = this.vrTicket['vrId'];
      // console.log(this.vrId);
      this.ticketId = this.vrTicket['ticketId'];
      this.orderId = this.vrTicket['orderId'];
      this.userId = this.vrTicket['userId'];
      this.eventId = this.vrTicket['eventId'];
      this.uploadVRStatus = this.vrTicket['uploadVRStatus'] ;
      this.vrComplete = this.vrTicket['completeStatus'];
      this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
        this.language = result;
        this.btnsubmit = this.language.UPLOAD_VR_PAGE.btnSubmit;
          });
      if(this.vrTicket['completeStatus'] == 'COMPLETE'){
        this.CompeletClass = 'form-control border-complete';
      }else if(this.vrTicket['completeStatus'] == 'INCOMPLETE'){
        this.CompeletClass = 'form-control';
      }
      this.validateUploadLog();
      const param = new Object();
      param['orderId'] = this.orderId;
      param['eventId'] = this.eventId;
      param['ticketId'] = this.ticketId;
      // console.log("param",param);
      this.getviewVrHistroyLog(param);
    }else{
      this.rounter.navigate(['./my-dashboard'])
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.filename = this.fileToUpload.name;
  }
  fileremove() {
    this.filename = null;
  }

  uploadVR() {
    // console.log('minuteValue',this.minuteValue)
    this.submited = true;
    if(this.hourValue == undefined || this.hourValue == null || this.hourValue ==''){
      this.hourValue = '0';
    }
    if(this.secondValue == undefined || this.secondValue == null || this.secondValue ==''){
      this.secondValue = '0';
    }
    if(this.minuteValue == undefined || this.minuteValue == null || this.minuteValue =='' || this.minuteValue == '0'){
      this.minuteValue = '0'
      this.period = 'B';
    }else{
      this.period = 'A';
    }
    // console.log('minuteValue',this.minuteValue)
    if (this.distance != '' && this.filename != '' && this.location != '' && this.period =='A') {
      this.currentLesson = '2';
      this.btnsubmit = this.language.REGISTER_SUMMARY_PAGE.wait;
      // console.log(
      //   this.hourValue,
      //   this.minuteValue,
      //   this.secondValue
      // );
      // this.service.uploadVRLog(this.fileToUpload, this.vrId, this.userId, this.ticketId, this.distance, this.location,this.period).subscribe(data => {
      this.service.uploadVRLog(this.fileToUpload, this.vrId, this.userId, this.ticketId, this.distance, this.location,this.hourValue,this.minuteValue,this.secondValue).subscribe(data => {
        // console.log(data);
        if (data['fileDownloadUri'] != '' && this.submitStatus == 'SINGLE_ALL' && this.uploadeds == false) {
          this.uploaded = true;
          this.modalUploadVr();
        } else if (data['fileDownloadUri'] != '' && this.submitStatus == 'SINGLE_ALL' && this.uploadeds == true) {
          this.modalTicketSingleAll();
        } else if (data['fileDownloadUri'] != '' && this.submitStatus == 'SINGLE_DAY' && this.uploadeds == false) {
          this.uploaded = true;
          this.modalUploadVr();
        } else if (data['fileDownloadUri'] != '' && this.submitStatus == 'SINGLE_DAY' && this.uploadeds == true) {
          this.modalTicketSingleDay();
        } else if (data['fileDownloadUri'] != '' && this.submitStatus == 'MULTI' && this.uploadeds == false) {
          this.uploaded = true;
          this.modalUploadVr();
        }

      }, error => {
        console.log(error);
      });
    }
  }

  modalTicketSingleAll() {
    this.modalService.dismissAll();
    this.modalService.open(this.ticketSingleAll, { backdrop: 'static',windowClass:'myCustomModalClass'});
  }
  modalTicketSingleDay() {
    this.modalService.dismissAll();
    this.modalService.open(this.ticketSingleDay, { backdrop: 'static' ,windowClass:'myCustomModalClass'});
  }

  modalUploadVr() {
    this.modalService.dismissAll();
    this.modalService.open(this.upLoadVr, { backdrop: 'static' ,windowClass:'myCustomModalClass'});
  }

  validateUploadLog() {
    this.service.validateUploadLog(this.vrId, this.userId).subscribe(data => {
      this.statusTicketVr = data;
      this.submitStatus = this.statusTicketVr.submitStatus;
      this.uploadeds = this.statusTicketVr.uploaded;
    })
  }

  modalUploadError() {

  }
  uploadback(){
    // this.rounter.navigate(['./my-dashboard']);
    this.rounter.navigate(['./my-dashboard']);

  }

  closeModel() {
    this.modalService.dismissAll();
    // this.rounter.navigate(['./my-dashboard']);
    this.rounter.navigate(['./my-dashboard']);

  }
  receiveModalPopupError(event) {

  }

  getviewVrHistroyLog(param){
    this.service.getviewVrHistroyLog(param).subscribe(Response => 
      { this.mapdata(Response)});
  }

  mapdata(data){
    // console.log('data', data)
    this.data = data
    this.listVRLogModel = data.listVRLogModel
    // console.log('listVRLogModel',this.listVRLogModel);
  }

  openImg(img){
    // console.log('>>>',img);
    this.imgVrLog = img.imageName;
    // console.log('>>>',this.imgVrLog);
    this.modalService.open(this.ImgModal, { backdrop: 'static' ,windowClass:'myCustomModalClass'});
  }
  
  closeModal(){
    this.modalService.dismissAll();
  }

  onChange(event, type) {
    const key = event.key;
    if(type === 'NUMBER'){
      if(!key.match('^-?(0|[1-9.]\d*)?$')) {
        
        event.preventDefault();
      }
    }
  }
  changeSecond(second){
    // console.log('second >> ', second.value);
    this.secondValue =  second.value;

  }
  changeMinute(minute){
    // console.log('minute >> ', minute.value);
    this.minuteValue = minute.value;
  }
  changeHour(hour){
    // console.log('hour >> ', hour.value);
    this.hourValue = hour.value;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }
  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
  getListBox(){
    this.service.getListBox('HOURTYPE').subscribe(data => {
      this.hour = data
    });
  }
}
