import { Component, OnInit, Injectable } from '@angular/core';
import { ScrollToService } from 'ng2-scroll-to-el';
import { HttpClientService } from '../../service/httpclient.service';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from '../../auth/token-storage.service';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
// import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
// import { ParameterUrl } from '../share-class/ParameterUrl';
// import { Router } from '@angular/router';

@Component({
  selector: 'vrgo-banner-slider',
  templateUrl: './vrgo-banner-slider.component.html',
  styleUrls: ['./vrgo-banner-slider.component.scss']
})
@Injectable()
export class VRGoBannerSliderComponent implements OnInit {
  route: string;

  constructor(
    private scrollService: ScrollToService,
    private httpClienService: HttpClientService,
    private translate: TranslateService,
    public location: Location,
    private rounter: Router,
    public tokenStored: TokenStorageService,
  ) {

    rounter.events.subscribe(val => {
      this.route = location.path();
    });
    
    this.listEvent;
    translate.addLangs(['th', 'en']);
    this.translate = translate;
    translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
      this.language = result;
      this.offers = [
        {
          link: 'https://checkrace.com/events',
          img: 'https://checkrace.com/FileController/downloadFile/event/Register-icon.png',
          topic_title: this.language.HOME_PAGE.HOME_PAGE_TOPPIC_Register,
          detail_title: this.language.HOME_PAGE.HOME_PAGE_DETAIL_Register,
        },
        {
          link: 'https://checkrace.com/my-dashboard',
          img: 'https://checkrace.com/FileController/downloadFile/event/Run-icon.png',
          topic_title: this.language.HOME_PAGE.HOME_PAGE_TOPPIC_Submit,
          detail_title: this.language.HOME_PAGE.HOME_PAGE_DETAIL_Submit,
        },
        {
          link: 'https://checkrace.com/my-dashboard',
          img: 'https://checkrace.com/FileController/downloadFile/event/Reward-icon.png',
          topic_title: this.language.HOME_PAGE.HOME_PAGE_TOPPIC_Reward,
          detail_title: this.language.HOME_PAGE.HOME_PAGE_DETAIL_Reward,
        }
      ]
    });
  }
  scrollToTop(element) {
    this.scrollService.scrollTo(element);
  }
  listEvent;
  imgPart;
  LANGUAGE;
  language: any = {};

  ngOnInit() {
    // console.log( this.route);
    // this.translate.get('OrderTransaction_Page_Event_Name').toPromise().then((res: string) => {
    //   this.LANGUAGE = res
    //   console.log('LANGUAGE',this.LANGUAGE)
    //   });
    //ดึง get lang มาใช้งาน
    this.listEvent = [];
    this.getEventAll();
    this.img();

  }

  getEventAll() {
    this.httpClienService.getHomeBannerEvent().subscribe(response => this.mapEventAll(response))
  }
  mapEventAll(Response) {
    this.listEvent = Response;
    // console.log('language C',this.language);
  }


  img() {

  }

  ecombannerOptions = {
    items: 1,
    nav: true,
    navClass: ['owl-prev', 'owl-next'],
    navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
    dots: false,
    autoplay: false,
    slideSpeed: 300,
    smartSpeed: 1100,
    loop: false
  }

  offers = [

  ]


}