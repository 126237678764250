import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NavService, Menu } from '../../service/nav.service';
import { TokenStorageService } from '../../auth/token-storage.service';
import { VRGoModalLoginComponent } from '../vrgo-modal-login/vrgo-modal-login.component';
import { HttpClientService } from '../../service/httpclient.service';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';
import { UsersModel } from '../../share-class/UsersModel';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'vrgo-menu',
  templateUrl: './vrgo-menu.component.html',
  styleUrls: ['./vrgo-menu.component.scss']
})


export class VRGoMenuComponent implements OnInit {
  @ViewChild(VRGoModalLoginComponent, { static: false }) vrGoModal: VRGoModalLoginComponent;
  public menuItems: Menu[];
  public openSide: boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem: string = ''
  public overlay: boolean = false;

  invalidLogin: boolean = (this.tokenStored.getUsername() == null ? false : true);

  userprofile;
  languageConfig: any;
  route: string;
  language: any;
  ss: any;
  profileModel: UsersModel;
  imgProfile: any = '';
  img: any;
  imgSplit: any;

  languageForm: FormGroup;

  constructor(
    public navServices: NavService,
    private rounter: Router,
    private clientService: HttpClientService,
    public tokenStored: TokenStorageService,
    public translate: TranslateService,
    public location: Location,
    private formBuilder: FormBuilder,) {

    this.buildChangeLanguage();
    if (this.tokenStored.getLang() == 'en') {
      this.languageForm.controls.checkland.setValue(false);
    } else {
      this.languageForm.controls.checkland.setValue(true);
    }

    // this.tokenStored.getImgProfile()
    // if (this.tokenStored.getImgProfile() == null) {
    //   this.tokenStored.saveImgProfile('../../../../assets/images/icon/icons-8-user.png');
    // }

    this.translate = translate;
    this.translate.setDefaultLang('th');
    translate.addLangs(['th', 'en']);
    translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
      this.language = result;

    });
    rounter.events.subscribe(val => {
      this.route = location.path();
    });
  }

  buildChangeLanguage() {
    this.languageForm = this.formBuilder.group({
      checkland: new FormControl(true),

    });
  }
  changeLanguage() {
    var lang ;
    if(this.languageForm.controls.checkland.value == false){
      lang = 'en';
    }else{
      lang = 'th';
    }
    this.tokenStored.getUserProfile();
    // console.log('Profile >>',this.tokenStored.getUserProfile());
    this.tokenStored.saveLang(lang);
    window.location.reload();
    // this.ngOnInit();

  }
  ngOnInit() {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.translate.setDefaultLang('th');
    });
    if (!this.tokenStored.getLang()) {
      this.tokenStored.saveLang("th");
    }
    if (this.tokenStored.getLang() == 'en') {
      this.translate.currentLang = 'en';
      if (this.language = this.translate.store.translations.en != undefined) {
        this.language = this.translate.store.translations.en.MENU;
      }
      // this.translate.setDefaultLang('en');
    } else {
      this.translate.currentLang = 'th';
      if (this.language = this.translate.store.translations.th != undefined) {
        this.language = this.translate.store.translations.th.MENU;
      }
      //this.translate.setDefaultLang('th');
    }
    this.translate.use(this.tokenStored.getLang());
    this.translate.reloadLang(this.tokenStored.getLang()).toPromise().then(result => {
      this.language = result;
    });
    // //console.log('getLang',this.tokenStored.getLang().length)
  }

  toggleSidebar() {
    this.openSide = !this.openSide
  }

  closeOverlay() {
    this.openSide = false
  }

  //For Active Main menu in Mobile View
  setActive(menuItem) {
    if (this.activeItem === menuItem) {
      this.activeItem = ''
    } else {
      this.activeItem = menuItem
    }
  }

  isActive(item) {
    return this.activeItem === item
  }

  // For Active Child Menu in Mobile View
  setChildActive(subMenu) {
    if (this.activeChildItem === subMenu) {
      this.activeChildItem = ''
    } else {
      this.activeChildItem = subMenu
    }
  }

  ischildActive(subMenu) {
    return this.activeChildItem === subMenu
  }

  public openLogin() {
    this.vrGoModal.popupConfirm(this.rounter.url);
  }

  public openLoginGotoVr(page) {
    // console.log("page",page);
    this.tokenStored.saveURLPath(page);
    this.vrGoModal.popupConfirm(page);

  }

  public logout() {
    this.tokenStored.signOut();
    this.rounter.navigate(['./']);
  }

  public receiveModalPopupError(event) {
  }

}
