import { OrderDetail } from './orderDetail.model';

export class Order {
    orderId: number;
	userId: number;
	ticketId: number;
	eventId: number;
	orderNumber: string;
	invoiceNo: string;
	netAmt: number;
	vat: number;
	status: string;
	shippingStatus: string;
	shippingAmount: number;
	address: string;
	province: string;
	amphure: string;
	district: string;
	subDistrict: string;
	zipcode: string;
	country: string;
	paymentDate: string;
	paymentMethod: string;
	createDate: string;
	createBy: string;
	updateDate: string;
	updateBy: string;
	remark: string;
	eventLocation: string;
	eventName: string;
	ticketName: string;
	distance: number;
	unit:string;
	orderStatusDesc: string;
	paymentMethodDesc: string;
	netAmtDesc: string;
	price : number;
	priceDesc :string;
	startTime : string;
	eventRegisDate : string;
	eventRegisEnd : string;
	eventImage : string ;
	paymentGatewayFee: number;
	platformFee: number;
	couponCode : string;
	couponMode : string;
	discountAmt: string;
	useCouponStatus : boolean;
	couponCodeId: string;
	ticketType: string;
	couponId : number;
    listOrderDetail: OrderDetail [] = [];
}