import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../auth/token-storage.service';
@Component({
  selector: 'app-vrgo-section-content',
  templateUrl: './vrgo-section-content.component.html',
  styleUrls: ['./vrgo-section-content.component.scss']
})
export class VrgoSectionContentComponent implements OnInit {

  constructor(
    private tokenStored: TokenStorageService,
    ) { 
      this.tokenStored.getUserProfile();
      // console.log('authen..',this.tokenStored.getUserProfile())
    }

  topicContent1: string = '';
  topicContent2: string = '';
  detailContent1: string = '';
  detailContent2: string = '';
  btn1: string = '';
  btn2: string = '';
  contect = [
    {
      topicContent: "VIRTUAL CHALLENGE",
      detailContent: "กิจกรรมท้าทายความสามารถของคุณเรามีกิจกรรมที่หลากหลาย ท้าทายให้คุณฟิตและสนุกสนานไปพร้อมกัน",
      btn: "View All Events",
      img: "../../../../assets/images/vrgo/banner-1.png",
      link:"/events",
      authen:"NO",
      flax : "left"
    }, {
      topicContent: 'Reward',
      detailContent: 'เสื้อและเหรียญรางวัล สร้างสรรค์ออกแบบมาเพื่อคุณเพราะเราเข้าใจว่ารางวัลไม่ใช่แค่ "วัสดุ"แต่คือ "ความภาคภูมิใจ"',
      btn: "",
      img: "../../../../assets/images/vrgo/banner-2.png",
      link:"",
      authen:"NO",
      flax : "right"
    },
    {
      topicContent: 'FEATURE',
      detailContent: 'สมัครง่าย งานวิ่ง VR มากมาย e-BIB / e-Certificate / e-Medal ',
      btn: "",
      img: "../../../../assets/images/vrgo/banner-3.png",
      link:"",
      authen:"NO",
      flax : "left"
    }, {
      topicContent: 'Ranking',
      detailContent: 'Dashboard หน้าสรุปกิจกรรม',
      btn: "My Dashboard",
      img: "../../../../assets/images/vrgo/banner-4.png",
      link:"/my-dashboard",
      authen:"YES",
      flax : "right"
    }



  ];
  ngOnInit() {
  }

}
