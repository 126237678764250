import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vrgo-ticket-faq',
  templateUrl: './vrgo-ticket-faq.component.html',
  styleUrls: ['./vrgo-ticket-faq.component.scss']
})
export class VrgoTicketFaqComponent implements OnInit {


  list: any[] = [
    {
      id: 'static-1',
      question: 'สมัครที่ไหนสมัครได้อย่างไร ?',
      answer: 'คุณสามารถดูกิจกรรมที่คุณสนใจได้ที่เมนู "กิจกรรมทั้งหมด" เลือกกิจกรรมที่คุณสนใจและคลิกที่ปุ่มสมัคร ระบุข้อมูลให้ครบถ้วน (ควรระบุข้อมูลที่ถูกต้อง เพื่อให้ได้รับผลประโยชน์อย่างเต็มที่!!) จากนั้น ชำระเงินค่าสมัครและเริ่มต้นการออกกำลังกาย โดยคุณสามารถส่งผลการออกกำลังกายภายในระยะเวลาที่กำหนดได้เลย'
    },
    {
      id: 'static-2',
      question: 'จะส่งผลได้อย่างไร ?',
      answer: 'คุณสามารถใช้แอพพลิเคชั่น หรือ อุปกรณ์ ที่สามารถจับระยะทางด้วยระบบ gps และส่งผลการออกกำลังกายของคุณให้กับเราที่เมนู "ส่งผลการวิ่ง" โดยทางเราจะมีเจ้าหน้าที่ตรวจสอบผลเบื้องต้นจะใช้เวลาตรวจสอบ 1-2 วันทำการ'
    },
    {
      id: 'static-3',
      question: 'นอกจากการวิ่งแล้ว สามารถเลือกบันทึกกิจกรรมรูปแบบอื่นได้ด้วยไหม ?',
      answer: 'คุณสามารถส่งผลการออกกำลังกายตามประเภทของกิจกรรมที่กำหนดไว้ได้ เบื้องต้นกิจกรรมประเภทวิ่ง สามารถส่งผลได้ด้วย การบันทึกผลการออกกำลังกายแบบ เดิน (Walking) / วิ่ง (Running) / วิ่งบนลู่วิ่ง (Treadmill) / การปีนเขา (Hiking)',
    },
    {
      id: 'static-4',
      question: 'สามารถวิ่งก่อนที่จะถึงระยะเวลาการส่งผลได้หรือไม่ ?',
      answer: 'ไม่ได้นะ!! เราของสงวนสิทธิ์ งดการส่งผลก่อนระยะเวลากิจกรรม เพื่อให้การแข่งขันที่มีขอบเขตอย่างชัดเจน ท้าทาย และ สนุกสนาน',
    },
    {
      id: 'static-5',
      question: 'หากต้องการเปลี่ยนแปลงข้อมูลที่สมัครไว้แล้วจะต้องทำอย่างไร ?',
      answer: 'การเปลี่ยนแปลงข้อมูลนั้นสามารถทำได้ โดยติดต่อเราทาง Facebook Fanpage ของเรา',
    },
    {
      id: 'static-6',
      question: 'จะได้รับของรางวัล เมื่อส่งผลครบตามกำหนด เมื่อไหร่ อย่างไร ?',
      answer: 'ของรางวัลจากการแข่งขันจะเริ่มจัดส่งให้เมื่อกิจกรรมประกาศปิดการส่งผลเรียบร้อยแล้ว โดยเบื้องต้นการจัดส่งจะใช้เวลาประมาณ 1 - 3 วันทำการ (ภายในประเทศไทย) ในส่วนของต่างประเทศอาจจะใช้เวลาประมาณ 14 วันทำการ (ทั้งนี้การจัดส่งของรางวัลอาจมีการเปลี่ยนแปลงขึ้นอยู่กับนโยบายของผู้จัดกิจกรรม)',
    }

  ];

  constructor() { }

  ngOnInit() {
  }

}
