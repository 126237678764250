import { Component, OnInit, Compiler, ViewChild } from '@angular/core';
import { ProductsService } from './vrgo/service/products.service';
import { Products } from './vrgo/model/product.model';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ColorScssService } from './vrgo/service/color-scss.service';
import { AuthenticationService } from './vrgo/service/authentication.service';
import { TokenStorageService } from './vrgo/auth/token-storage.service';
import { VRGoModalLoginComponent } from './vrgo/component/vrgo-modal-login/vrgo-modal-login.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild("validToken", { static: false }) validToken;
  @ViewChild(VRGoModalLoginComponent, { static: false }) vrGoModal: VRGoModalLoginComponent;

  constructor(
    public colorPicker: ColorScssService,
    private route: ActivatedRoute,
    private title: Title,
    public compiler: Compiler,
    public authen: AuthenticationService,
    public storageservice: TokenStorageService,
    private modalService: NgbModal,
    private validTokenModal: NgbActiveModal,
    private ngxService: NgxUiLoaderService,
  ) {
  }

  ngOnInit() {
    this.colorPicker.setColorScheme('color-7');
    this.title.setTitle(this.route.snapshot.data['VR GO']);

    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 500);

    this.compiler.clearCache();
    // this.colorPicker.setColorScheme('color-7');
    this.title.setTitle(this.route.snapshot.data['VR GO']);
    this.checkSession();
  }

  checkSession() {
    const currentUser = this.storageservice.getUserProfile();
    
    if (currentUser && currentUser.expireDate) {

      // console.log("user token date =", new Date(currentUser.expireDate));
      // console.log("new Date =", new Date);

      let expireDate = new Date(currentUser.expireDate);
      if (expireDate < new Date) {

        Swal.fire({

          title: '<strong class="titie-alert"> ' + "Token หมดอายุ" + '</strong>',
          text: "กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'ล็อคอินอีกครั้ง',
          cancelButtonColor: '#e60c21',
          iconColor: "#e60c21",

        }).then((result) => {
          this.storageservice.signOut();
          this.openLogin();
        });

      }

    }
  }

  openLogin() {
    this.validTokenModal.close();
    this.vrGoModal.popupConfirm("/");
  }

  receiveModalPopupError(event) {

  }

  public getBrowserName() {
    // const open = require('open');
    const agent = window.navigator.userAgent.toLowerCase()
    // console.log("agent >> ",agent);
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:

        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:

        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  // gotoUrl(){
  //   const open = require('open');

  //   (async () => {

  //       // Opens the URL in the default browser.
  //       await open('https://sindresorhus.com');

  //       // Opens the URL in a specified browser.
  //       await open('https://sindresorhus.com', {app: 'firefox'});

  //       // Specify app arguments.
  //       await open('https://sindresorhus.com', {app: ['google chrome', '--incognito']});
  //   })();
  // }

}
