import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { Order } from '../../model/order.model';
import { TokenStorageService } from '../../auth/token-storage.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FunctionUtils } from '../../utils/functionUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vrgo-order-transaction',
  templateUrl: './vrgo-order-transaction.component.html',
  styleUrls: ['./vrgo-order-transaction.component.scss']
})
export class VRGoOrderTransactionComponent implements OnInit {

  returnedArray: Order[];

  userId: number;
  order: Order[];

  constructor(
    private httClient: HttpClientService,
    private tokenStored: TokenStorageService,
    private fn: FunctionUtils,
    private param: ParameterUrl,
    private rounter: Router
  ) { }

  ngOnInit() {
    this.order = [];
    const userDetail = this.tokenStored.getUserProfile();
    if(userDetail != null && userDetail['userId'] != undefined){
      this.userId = Number(userDetail['userId']);
      if(this.userId != null){
        let userObject = new Object();
        userObject['userId'] = this.userId; 
        userObject['languageStatus'] = this.tokenStored.getLang();
        this.httClient.listTicketTransaction(userObject).subscribe( data =>{
          this.mapTicketTransaction(data);
        })
      }
    }

  }

  mapTicketTransaction(data: any){
    this.order = data;
    // console.log('list ticket : '+JSON.stringify(this.order));
    this.returnedArray = this.fn.slice(this.order, 0, 10);
    // console.log('returnedArray',this.returnedArray) /* ให้เพิ่มระยะทาง */
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.fn.slice(this.order, startItem, endItem);
  }

  viewTicketDetail(order :Order){
    this.param.data = {};
    this.param.data.order = order;
    this.rounter.navigate(['./orderDetail']);
  }

}

