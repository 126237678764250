// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// https://checkrace.com
export const environment = {
  production: false,
  // serviceUrl: 'http://localhost:8903/',
  serviceUrl: 'https://checkrace.com/',
  secretKey: 'ldsf$#$43324438yasf%#235',

  // FACEBOOK CONFIG
  fbClientId: "2625583094248090",
  // fbRedirectUri: "https://checkrace.com",
  fbRedirectUri: "https://checkrace.com",
  fbClientSecret: "854a8542a98188bf82b82bb39dcef36c",

  // LINE CONFIG
  LnClientId: "1655806469",
  // LnClientId: "1654053317", //เก่า
  
  // LnRedirectUri: "https://checkrace.com",
  LnRedirectUri: "https://checkrace.com",
  LnClientSecret: "69b34e3d7d153ba3361227db2d3542e5",
  // LnClientSecret: "5ee70a7ba4d4ccc233cc85d0c5886671",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
