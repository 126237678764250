import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { TokenStorageService } from '../auth/token-storage.service';
import { environment } from 'src/environments/environment';
const httpOptions = {
};

@Injectable({
  providedIn: 'root'
})

export class FacebookApiService {
  code: string = "";
  eventUrl: string = "";
  accessToken: any;
  @Output() messageEvent = new EventEmitter<any>();
  eventName: string;

  constructor(
    private httpClient: HttpClient,
    private tokenStored: TokenStorageService,
  ) {

  }

  clientId: string = environment.fbClientId;
  redirectUri: string = environment.fbRedirectUri;
  clientSecret: string = environment.fbClientSecret;

  // redirect_uri main //
  facebookLogin(path) {
    window.location.href = 'https://www.facebook.com/v10.0/dialog/oauth?client_id=' + this.clientId + '&scope=email%20public_profile&redirect_uri=' + this.redirectUri + path;
  }

  facebookAccesTokenToMain(param, path) {
    return this.httpClient.get<any>('https://graph.facebook.com/v10.0/oauth/access_token?client_id=' + this.clientId + '&redirect_uri=' + this.redirectUri + '/' + path + '&client_secret=' + this.clientSecret + '&code=' + param, httpOptions);
  }

  // Event Detail //
  facebookLoginToEventDetail() {
    this.eventUrl = this.tokenStored.getURLEvent();
    window.location.href = 'https://www.facebook.com/v10.0/dialog/oauth?client_id=' + this.clientId + '&scope=email%20public_profile&auth_type=rerequest&redirect_uri=' + this.redirectUri + '/' + this.eventUrl;
  }

  // ========================= Basic ============================= //

  getUserId(param) {
    return this.httpClient.get<any>('https://graph.facebook.com/debug_token?input_token=' + param + '&access_token=' + this.clientId + '|' + this.clientSecret + '', httpOptions);
  }

  getUserProfile(param, param2) {
    return this.httpClient.get<any>('https://graph.facebook.com/v10.0/' + param + '/?fields=email,name,first_name,last_name,picture&access_token=' + param2, httpOptions);
  }


}

