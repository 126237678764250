import { Component, OnInit,HostListener , ViewChild } from '@angular/core';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClientService } from '../../service/httpclient.service';
import * as _ from 'lodash';
import { EventModel } from '../../share-class/EventModel';
import { TokenStorageService } from '../../auth/token-storage.service';
import { AuthenticationService } from '../../service/authentication.service';

import { VRGoModalLoginComponent } from '../vrgo-modal-login/vrgo-modal-login.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vrgo-event-peview',
  templateUrl: './vrgo-event-peview.component.html',
  styleUrls: ['./vrgo-event-peview.component.scss']
})
export class VrgoEventPeviewComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 1200;

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // console.log('[scroll]', scrollPosition
    // );
    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  @ViewChild('ticketRegistered', { static: true }) ticketRegistered;
  @ViewChild(VRGoModalLoginComponent, { static: false }) vrGoModal: VRGoModalLoginComponent;
  constructor(
    private param: ParameterUrl,
    private rounter: Router,
    private modalService: NgbModal,
    private httpClienService: HttpClientService,
    private loginService: AuthenticationService,
    private tokenstorage: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    private datepipe : DatePipe) {
    this.eventDetail = new EventModel();
  }
  text: any =
    {
      "Days": "Day",
      "Hours": "Hour",
      "Minutes": "Min",
      "Seconds": "Sec",
    };

  endTime: any;



  requestParam: any = {};
  eventDetail: EventModel;
  eventDetailById: any = {};



  ticketDetail: any;

  userProfile: any;
  eventId: string = '';
  userId : EventModel;
  lisTicket = [];
  listEventImages = [];

  ticketId: any;
  // userId: any;
  validateRegistered: EventModel;
  validateRegistereds: any;
  
  dateToDay = new Date().toISOString();
  name:string = '';


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.name = params['name'];
      if(this.name != null && this.name != '' && this.name !=undefined && this.name != 'null'){
          this.eventDetail.urlPath = this.name;
          this.tokenstorage.removeSessionOrder();
          if(this.eventDetail.urlPath != null && this.eventDetail.urlPath != undefined && this.eventDetail.urlPath != '' ){
            // console.log('events',this.eventDetail);
            this.getEventPreview();
          }else{
            this.rounter.navigate(['./']);    
          }  
      }else{
        this.rounter.navigate(['./']);    
      }
    });


    // if (this.param.data != null && this.param.data.eventId != undefined) {
    //   this.eventId = this.param.data.eventId;
    // } else {
    //   this.rounter.navigate(['./']);
    // }
    // this.tokenstorage.removeSessionOrder();
    // if (this.eventId != '') {
    //   this.eventDetail = this.param.data.eventDetail;
    //   this.getEventPreview();
    // }else{
    //   this.rounter.navigate(['./']);
    // };
    // this.dateToDay;
    // console.log('date',this.dateToDay);
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  ticketRegister(ticketId) {
    const userDetail = this.tokenstorage.getUserProfile()
    if (userDetail == null) {
      this.vrGoModal.popupConfirm(this.rounter.url);
    } else {
      this.validateRegisteredTicket(ticketId);

    }

  }

  getEventPreview() {
    // console.log('old-response',this.eventDetail);
    this.httpClienService.getEventPreview(this.eventDetail).subscribe(
      response => {
        // console.log('response registerStatusDesc',response);
        this.eventDetail = response;
        this.endTime = new Date(this.eventDetail.eventDate).toISOString()
        const userDetail = this.tokenstorage.getUserProfile()
        if (userDetail != null) {
          this.eventDetail.userId = userDetail.userId
          // console.log('event',this.eventDetail);
          this.httpClienService.validateRegisteredTicket(this.eventDetail).subscribe(
            response => {
              this.validateRegistered = response;
              // console.log('validateRegistered',response)
              if (this.validateRegistered.registerStatus == 'SINGLE' && this.validateRegistered.registerStatusDesc == 'REGISTERED') {
                this.eventDetail.listTicket = this.validateRegistered.listTicket;
              }
            });
        }

      });
  }
  receiveModalPopupError(event) {
    // console.log(event);
    event = JSON.parse(event);
    if (event.status == 'success') {
      const userDetail = this.tokenstorage.getUserProfile()
      this.eventDetail.userId = userDetail.userId
      // console.log('eventDetail167',this.eventDetail)
      if (userDetail != null) {
        this.httpClienService.validateRegisteredTicket(this.eventDetail).subscribe(
          response => {
            this.validateRegistered = response;
            // console.log('this.validateRegistered',this.validateRegistered)
            // เช็คตอนที่เรา ล็อคอินจากหน้า eventDetail แล้วเข้าเงื่อนไขดังนี้
            if (this.validateRegistered.registerStatus == 'SINGLE' && this.validateRegistered.registerStatusDesc == 'REGISTERED') {
              this.eventDetail.listTicket = this.validateRegistered.listTicket;
              this.openModelValidate();
            }
          });
      }
    }

  }



  validateRegisteredTicket(ticketId) {
    const userDetail = this.tokenstorage.getUserProfile()
    this.eventDetail.userId = userDetail.userId
    // console.log('ticketId',this.eventDetail)
    this.httpClienService.validateRegisteredTicket(this.eventDetail).subscribe(
      response => {
        this.validateRegistered = response;
        const userDetail = this.tokenstorage.getUserProfile()
        // เช็คตอนที่เราล็อคอินมาก่อนแล้ว แล้วมากิน ticket ที่มี เงื่อนไขดังนี้
        if (userDetail != null && this.validateRegistered.registerStatus == 'SINGLE' && this.validateRegistered.registerStatusDesc == 'REGISTERED') {
          this.openModelValidate();
        } else {
          this.param.data = {};
          this.param.data.eventDetail = this.eventDetail;
          this.param.data.ticketId = ticketId;
          this.rounter.navigate(['./registerForm']);
        }
      });
  }

  openModelValidate() {
    this.modalService.dismissAll();
    this.modalService.open(this.ticketRegistered, { windowClass: 'popup', backdrop: 'static' })

  }
  closeModel() {
    this.modalService.dismissAll();
  }

  list: any[] = [
    {
      id:'static-1',
      question: 'สมัครที่ไหนสมัครได้อย่างไร ?',
      answer: 'คุณสามารถดูกิจกรรมที่คุณสนใจได้ที่เมนู "กิจกรรมทั้งหมด" เลือกกิจกรรมที่คุณสนใจและคลิกที่ปุ่มสมัคร ระบุข้อมูลให้ครบถ้วน (ควรระบุข้อมูลที่ถูกต้อง เพื่อให้ได้รับผลประโยชน์อย่างเต็มที่!!) จากนั้น ชำระเงินค่าสมัครและเริ่มต้นการออกกำลังกาย โดยคุณสามารถส่งผลการออกกำลังกายภายในระยะเวลาที่กำหนดได้เลย'
    },
    {
      id:'static-2',
      question: 'จะส่งผลได้อย่างไร ?',
      answer: 'คุณสามารถใช้แอพพลิเคชั่น หรือ อุปกรณ์ ที่สามารถจับระยะทางด้วยระบบ gps และส่งผลการออกกำลังกายของคุณให้กับเราที่เมนู "ส่งผลการวิ่ง" โดยทางเราจะมีเจ้าหน้าที่ตรวจสอบผลเบื้องต้นจะใช้เวลาตรวจสอบ 1-2 วันทำการ'
    },
    {
      id:'static-3',
      question: 'นอกจากการวิ่งแล้ว สามารถเลือกบันทึกกิจกรรมรูปแบบอื่นได้ด้วยไหม ?',
      answer: 'คุณสามารถส่งผลการออกกำลังกายตามประเภทของกิจกรรมที่กำหนดไว้ได้ เบื้องต้นกิจกรรมประเภทวิ่ง สามารถส่งผลได้ด้วย การบันทึกผลการออกกำลังกายแบบ เดิน (Walking) / วิ่ง (Running) / วิ่งบนลู่วิ่ง (Treadmill) / การปีนเขา (Hiking)',
    },
    {
      id:'static-4',
      question: 'สามารถวิ่งก่อนที่จะถึงระยะเวลาการส่งผลได้หรือไม่ ?',
      answer: 'ไม่ได้นะ!! เราของสงวนสิทธิ์ งดการส่งผลก่อนระยะเวลากิจกรรม เพื่อให้การแข่งขันที่มีขอบเขตอย่างชัดเจน ท้าทาย และ สนุกสนาน',
    },
    {
      id:'static-5',
      question: 'หากต้องการเปลี่ยนแปลงข้อมูลที่สมัครไว้แล้วจะต้องทำอย่างไร ?',
      answer: 'การเปลี่ยนแปลงข้อมูลนั้นสามารถทำได้ โดยติดต่อเราทาง Facebook Fanpage ของเรา',
    },
    {
      id:'static-6',
      question: 'จะได้รับของรางวัล เมื่อส่งผลครบตามกำหนด เมื่อไหร่ อย่างไร ?',
      answer: 'ของรางวัลจากการแข่งขันจะเริ่มจัดส่งให้เมื่อกิจกรรมประกาศปิดการส่งผลเรียบร้อยแล้ว โดยเบื้องต้นการจัดส่งจะใช้เวลาประมาณ 1 - 3 วันทำการ (ภายในประเทศไทย) ในส่วนของต่างประเทศอาจจะใช้เวลาประมาณ 14 วันทำการ (ทั้งนี้การจัดส่งของรางวัลอาจมีการเปลี่ยนแปลงขึ้นอยู่กับนโยบายของผู้จัดกิจกรรม)',
    }

  ];

  imgeventitemsCarouselOptions = {
    items: 2,
    nav: false,
    navClass: [],
    navText: [],
    margin: 20,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: false,
    dots: true,
    stagePadding: 40,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 3,
      },
      1250: {
        items: 3,
        margin: 30
      }
    }
  }
  retureBack(){
    this.rounter.navigate(['./']);
  }


}