
export class FlagNationDashboardModel {
	
	summaryRunnerOverAll : number;
	summaryAllDistance  : number;
	summaryCurrencyOverAll : string;
	totalTeam : number;
	listTeam : any [];
	listUserRanking : any [];
	ticketId : number;
	eventId : number;
	userId : number;
	orderId : number;
    
}