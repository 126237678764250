import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { TicketField } from './ticketField.model';


    @Injectable({
    providedIn: 'root'
  })
  export class FieldFormDefault {
  
    constructor() {  }
  
    fieldDefault: TicketField[] = [
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'firstName',
            fieldLabel: 'ชื่อ (ภาษาอังกฤษ)',
            fieldDataType: 'TEXT',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'ชื่อ (ภาษาอังกฤษ)',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'lastName',
            fieldLabel: 'นามสกุล (ภาษาอังกฤษ)',
            fieldDataType: 'TEXT',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'นามสกุล (ภาษาอังกฤษ)',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'birthDate',
            fieldLabel: 'วันเกิด',
            fieldDataType: 'TEXT',
            fieldInputType: 'DATE',
            fieldPlacehold: 'วันเกิด',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'gender',
            fieldLabel: 'เพศ',
            fieldDataType: 'TEXT',
            fieldInputType: 'COMBOBOX',
            fieldPlacehold: 'เพศ',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'N',
            status: 'ACTIVE',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: [
                {
                    fieldObjectId: 0,
                    ticketFieldId: 0,
                    description: 'เพศหญิง',
                    objectDesc: 'หญิง',
                    objectValue: "F",
                    status: 'Active'
                },
                {
                    fieldObjectId: 0,
                    ticketFieldId: 0,
                    description: 'เพศชาย',
                    objectDesc: 'ชาย',
                    objectValue: "M",
                    status: 'Active'
                }
            ]
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'tel',
            fieldLabel: 'เบอร์โทรศัพท์',
            fieldDataType: 'TEL',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'Please specify Phone number',
            fieldDesc: '',
            fieldLimit: 30,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'email',
            fieldLabel: 'Email',
            fieldDataType: 'EMAIL',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'Email',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'Y',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'idCard',
            fieldLabel: 'Passport ID',
            fieldDataType: 'NUMBER-TH',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'Please specify Passport ID',
            fieldDesc: '',
            fieldLimit: 50,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        }
    ]
    fieldDefaultTH: TicketField[] = [
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'firstName',
            fieldLabel: 'ชื่อ (ภาษาอังกฤษ)',
            fieldDataType: 'TEXT',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'ชื่อ (ภาษาอังกฤษ)',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'lastName',
            fieldLabel: 'นามสกุล (ภาษาอังกฤษ)',
            fieldDataType: 'TEXT',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'นามสกุล (ภาษาอังกฤษ)',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'birthDate',
            fieldLabel: 'วันเกิด',
            fieldDataType: 'TEXT',
            fieldInputType: 'DATE',
            fieldPlacehold: 'วันเกิด',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'gender',
            fieldLabel: 'เพศ',
            fieldDataType: 'TEXT',
            fieldInputType: 'COMBOBOX',
            fieldPlacehold: 'เพศ',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'N',
            status: 'ACTIVE',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: [
                {
                    fieldObjectId: 0,
                    ticketFieldId: 0,
                    description: 'เพศหญิง',
                    objectDesc: 'หญิง',
                    objectValue: "F",
                    status: 'Active'
                },
                {
                    fieldObjectId: 0,
                    ticketFieldId: 0,
                    description: 'เพศชาย',
                    objectDesc: 'ชาย',
                    objectValue: "M",
                    status: 'Active'
                }
            ]
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'tel',
            fieldLabel: 'เบอร์โทรศัพท์',
            fieldDataType: 'NUMBER',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'กรุณาระบุ เบอร์โทรศัพท์ 10 หลัก',
            fieldDesc: '',
            fieldLimit: 10,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'email',
            fieldLabel: 'Email',
            fieldDataType: 'EMAIL',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'Email',
            fieldDesc: '',
            fieldLimit: 200,
            filedDisabled:'Y',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'Y',
            fieldPattern: '',
            listFieldObject: []
        },
        { 
            ticketId: 0,
            ticketFieldId: 0,
            fieldName: 'idCard',
            fieldLabel: 'หมายเลขประจำตัวประชาชน',
            fieldDataType: 'NUMBER',
            fieldInputType: 'INPUT',
            fieldPlacehold: 'กรุณาระบุ หมายเลขประจำตัวประชาชน 13 หลัก',
            fieldDesc: '',
            fieldLimit: 13,
            filedDisabled:'N',
            status: 'Active',
            createDate: '',
            createBy: '',
            updateDate: '',
            updateBy: '',
            fieldRequired: 'N',
            fieldPattern: '',
            listFieldObject: []
        }
    ]

    items = new BehaviorSubject<TicketField[]>(this.fieldDefault);
}
