import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { Order } from '../../model/order.model';
import { TokenStorageService } from '../../auth/token-storage.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FunctionUtils } from '../../utils/functionUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
import { VRTicket } from '../../model/vrTicket.model';

@Component({
  selector: 'app-vrgo-e-badge',
  templateUrl: './vrgo-e-badge.component.html',
  styleUrls: ['./vrgo-e-badge.component.scss']
})
export class VRGoEbadgeComponent implements OnInit {

  userId: number;

  fullName : string;
  imgProfile : string ='https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
  email : string;
  listEbadgeDetail : any[];
  amount : number;
  sumAmount : number;
  sum :number;
  constructor(
    private httClient: HttpClientService,
    private tokenStored: TokenStorageService
  ) { }

  ngOnInit() {

    const userDetail = this.tokenStored.getUserProfile();

    this.email = userDetail.email ;
    this.fullName = userDetail.firstName+' '+userDetail.lastName;
    if(userDetail.imageProfile != undefined && userDetail.imageProfile != null && userDetail.imageProfile != ''){
      this.imgProfile = this.tokenStored.getImgProfile();
    }else{
      this.imgProfile = 'https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
    }

    let param = new FormData();
    param.append('userId', userDetail.userId);
    this.httClient.getEBadgeByUser(param).subscribe(response => {
      // console.log('response',response)
      this.listEbadgeDetail = response['listEbadge'];
      this.sum = this.listEbadgeDetail.length ;
      // console.log('a>>',this.sum);
      this.amount = response['amount'];
      // console.log('a>>',this.amount);
      this.sumAmount = (isNaN((this.amount / this.sum) * 100) ? 0 : (this.amount / this.sum) * 100);
      // console.log('sumAmount >>',this.sumAmount);
    });
  }

}

