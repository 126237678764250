import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { routes } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { MatTabsModule } from '@angular/material/tabs';
import { CountdownModule } from "@aigre87/ng2-date-countdown";
import { AngularCountdownDateTimeModule } from 'angular-countdown-date-time';
import { MatIconModule } from '@angular/material/icon';

import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { httpInterceptorProviders } from './vrgo/auth/auth-interceptor';
import { CommonModule, DatePipe } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper/dist/ngx-swiper-wrapper';
import { SharedModule } from './shared/shared.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { CountToModule } from 'angular-count-to';
import { AngularTiltModule } from 'angular-tilt';
import { MasonryGalleryModule } from 'ngx-masonry-gallery';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPayPalModule } from 'ngx-paypal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxMasonryModule } from 'ngx-masonry';
import { VRGoBannerSliderComponent } from './vrgo/component/vrgo-banner-slider/vrgo-banner-slider.component';
import { VRGoHeaderComponent } from './vrgo/component/vrgo-header/vrgo-header.component';
import { VRGoProductSliderComponent } from './vrgo/component/vrgo-product-slider/vrgo-product-slider.component';
import { VRGoParallaxBannerComponent } from './vrgo/component/vrgo-parallax-banner/vrgo-parallax-banner.component';
import { VRGoProductTabComponent } from './vrgo/component/vrgo-product-tab/vrgo-product-tab.component';
import { VRGoDownBannerComponent } from './vrgo/component/vrgo-down-banner/vrgo-down-banner.component';
import { VRGoRecentStoryComponent } from './vrgo/component/vrgo-recent-story/vrgo-recent-story.component';
import { VRGoFooterComponent } from './vrgo/component/vrgo-footer/vrgo-footer.component';
import { VRGoMenuComponent } from './vrgo/component/vrgo-menu/vrgo-menu.component';
import { SidebarComponent } from './vrgo/product/product-detail/sidebar/sidebar.component';
import { ProductDetailsComponent } from './vrgo/product/product-detail/product-details/product-details.component';
import { ProductDetailComponent } from './vrgo/product/product-detail/product-detail.component';
import { CompareComponent } from './vrgo/product/compare/compare.component';
import { NewProductComponent } from './vrgo/product/new-product/new-product.component';
import { ProductBoxComponent } from './vrgo/product/product-box/product-box.component';
import { VRGoEventsComponent } from './vrgo/component/vrgo-events/vrgo-events.component';
import { VRGoModalLoginComponent } from './vrgo/component/vrgo-modal-login/vrgo-modal-login.component';
import { MatMenuModule } from '@angular/material/menu';
import { VRGoMainComponent } from './vrgo/component/vrgo-main/vrgo-main.component';
import { VrgoEventSpcialComponent } from './vrgo/component/vrgo-event-spcial/vrgo-event-spcial.component';
import { VrgoEventAllComponent } from './vrgo/component/vrgo-event-all/vrgo-event-all.component';
import { VRGoEventDetailComponent } from './vrgo/component/vrgo-event-detail/vrgo-event-detail.component';
import { VRGoRegisterFormComponent } from './vrgo/component/vrgo-register-form/vrgo-register-form.component';
import { NumberDirective } from './vrgo/directive/numbers-only.directive';

import { AuthServiceConfig } from "angular4-social-login";
import { SocialLoginModule } from "angular4-social-login";
// import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login/angularx-social-login";
import { GoogleLoginProvider } from "angular4-social-login";
import { FacebookLoginProvider } from "angular4-social-login";
// import { GoogleLoginProvider } from "angularx-social-login";
import { FunctionUtils } from './vrgo/utils/functionUtils';
import { VrgoPaymentsResultComponent } from './vrgo/component/vrgo-payments-result/vrgo-payments-result.component';
import { VRGoSummaryRegisterComponent } from './vrgo/component/vrgo-summary-register/vrgo-summary-register.component';
import { VRGoUploadSlipComponent } from './vrgo/component/vrgo-upload-slip/vrgo-upload-slip.component';
import { VRGoOrderTransactionComponent } from './vrgo/component/vrgo-order-transaction/vrgo-order-transaction.component';
import { VRGoCreditCard2C2PComponent } from './vrgo/component/vrgo-credit-card/vrgo-credit-card.component';
import { VRGoOrderDetailComponent } from './vrgo/component/vrgo-order-detail/vrgo-order-detail.component';
import { VRGoUSERVRTicketComponent } from './vrgo/component/vrgo-user-vr-ticket/vrgo-user-vr-ticket.component';
import { VrgoLogUploadComponent } from './vrgo/component/vrgo-log-upload/vrgo-log-upload.component';
import { VrgoProfileComponent } from './vrgo/component/vrgo-profile/vrgo-profile.component';
import { VRGoDownloadEbibComponent } from './vrgo/component/vrgo-download-ebib/vrgo-download-ebib.component';
import { VRGoDownloadECertComponent } from './vrgo/component/vrgo-download-ecert/vrgo-download-ecert.component';
import { VrfoCreditSuccessComponent } from './vrgo/component/vrfo-credit-success/vrfo-credit-success.component';
import { VrfoCreditFailComponent } from './vrgo/component/vrfo-credit-fail/vrfo-credit-fail.component';
import { VRGoEbadgeComponent } from './vrgo/component/vrgo-e-badge/vrgo-e-badge.component';
import { VrgoEventPeviewComponent } from './vrgo/component/vrgo-event-peview/vrgo-event-peview.component';
import { VrgoFaqComponent } from './vrgo/component/vrgo-faq/vrgo-faq.component';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// import { TranslateModule,TranslateLoader } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VrgoMyDashboardComponent } from './vrgo/component/vrgo-my-dashboard/vrgo-my-dashboard.component';
import { VrgoMySubDashboardComponent } from './vrgo/component/vrgo-my-sub-dashboard/vrgo-my-sub-dashboard.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { VrgoTourisMemberComponent } from './vrgo/component/vrgo-touris-member/vrgo-touris-member.component';
import { VrgoCustomEventComponent } from './vrgo/component/vrgo-custom-event/vrgo-custom-event.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { VrgoSectionContentComponent } from './vrgo/component/vrgo-section-content/vrgo-section-content.component';
import { VrgoSectionFooterComponent } from './vrgo/component/vrgo-section-footer/vrgo-section-footer.component';
import { VrgoUploadAllEventComponent } from './vrgo/component/vrgo-upload-all-event/vrgo-upload-all-event.component';
import { VrgoTicketInfoComponent } from './vrgo/component/vrgo-ticket-info/vrgo-ticket-info.component';
import { VrgoTicketInfoContentComponent } from './vrgo/component/vrgo-ticket-info-content/vrgo-ticket-info-content.component';
import { VrgoTicketOnlyComponent } from './vrgo/component/vrgo-ticket-only/vrgo-ticket-only.component';
import { VrgoTicketInfoRankComponent } from './vrgo/component/vrgo-ticket-info-rank/vrgo-ticket-info-rank.component';
import { VrgoTicketFaqComponent } from './vrgo/component/vrgo-ticket-faq/vrgo-ticket-faq.component';
import { VrgoTicketInfoPartnerComponent } from './vrgo/component/vrgo-ticket-info-partner/vrgo-ticket-info-partner.component';
import { VrgoPrivacypolicyComponent } from './vrgo/component/vrgo-privacypolicy/vrgo-privacypolicy.component';
import { EncrDecrService } from './vrgo/auth/encr-decr.service';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from "ngx-ui-loader";
import { VrgoPrivacypolicyEnComponent } from './vrgo/component/vrgo-privacypolicy-en/vrgo-privacypolicyEn.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VrCallbackComponent } from './vrgo/component/vr-callback/vr-callback.component';
import { environment } from '../environments/environment';
import { NgxImageCompressService } from 'ngx-image-compress';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#ec3333",
  fgsColor: "#ec3333",
  pbColor: "#ec3333",
};


const googleLoginOptions = {
  scope: 'profile email'
};

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("979637717538-mqt62hv26bd49s5jfk5vt892vhoqv0co.apps.googleusercontent.com")//DEV
    // provider: new GoogleLoginProvider("979637717538-mqt62hv26bd49s5jfk5vt892vhoqv0co.apps.googleusercontent.com", googleLoginOptions)//UAT
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.fbClientId)
    // provider: new FacebookLoginProvider("605802170057584")
  }
]);

export function provideConfig() {
  return config;
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    VRGoBannerSliderComponent,
    VRGoHeaderComponent,
    VRGoProductSliderComponent,
    VRGoParallaxBannerComponent,
    VRGoProductTabComponent,
    VRGoDownBannerComponent,
    VRGoRecentStoryComponent,
    VRGoFooterComponent,
    VRGoMenuComponent,
    SidebarComponent,
    ProductDetailsComponent,
    ProductDetailComponent,
    CompareComponent,
    NewProductComponent,
    ProductBoxComponent,
    VRGoEventsComponent,
    VRGoModalLoginComponent,
    VRGoMainComponent,
    VrgoEventSpcialComponent,
    VrgoEventAllComponent,
    VRGoEventDetailComponent,
    VRGoRegisterFormComponent,
    NumberDirective,
    VrgoPaymentsResultComponent,
    VRGoSummaryRegisterComponent,
    VRGoUploadSlipComponent,
    VRGoOrderTransactionComponent,
    VRGoCreditCard2C2PComponent,
    VRGoOrderDetailComponent,
    VRGoUSERVRTicketComponent,
    VrgoLogUploadComponent,
    VrgoProfileComponent,
    VRGoDownloadEbibComponent,
    VRGoDownloadECertComponent,
    VrfoCreditSuccessComponent,
    VrfoCreditFailComponent,
    VRGoEbadgeComponent,
    VrgoEventPeviewComponent,
    VrgoFaqComponent,
    VrgoMyDashboardComponent,
    VrgoMySubDashboardComponent,
    VrgoTourisMemberComponent,
    VrgoCustomEventComponent,
    VrgoSectionContentComponent,
    VrgoSectionFooterComponent,
    VrgoUploadAllEventComponent,
    VrgoTicketInfoComponent,
    VrgoTicketInfoContentComponent,
    VrgoTicketOnlyComponent,
    VrgoTicketInfoRankComponent,
    VrgoTicketFaqComponent,
    VrgoTicketInfoPartnerComponent,
    VrgoPrivacypolicyComponent,
    VrgoPrivacypolicyEnComponent,
    VrCallbackComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    HttpClientModule,
    CarouselModule,
    RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    CommonModule,
    SwiperModule,
    SharedModule,
    GalleryModule.forRoot(),
    CountToModule,
    AngularTiltModule,
    ScrollToModule.forRoot(),
    MasonryGalleryModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    Ng5SliderModule,
    NgxMasonryModule,
    MatMenuModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    SocialLoginModule,
    CountdownModule,
    AngularCountdownDateTimeModule,
    PaginationModule.forRoot(),
    MatTabsModule,
    MatIconModule,
    MatCheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]

      }
    }),
    MatButtonToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    AutocompleteLibModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  exports: [
    SidebarComponent,
    ProductDetailsComponent,
    CompareComponent,
    NewProductComponent,
    ProductBoxComponent,
    ProductDetailComponent,
    VRGoHeaderComponent,
    VRGoFooterComponent,
    CountdownModule

  ],
  providers: [NgbActiveModal, EncrDecrService, httpInterceptorProviders, DatePipe, FunctionUtils,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    NgxImageCompressService],
  bootstrap: [AppComponent]
})
export class AppModule { }
