import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../../auth/token-storage.service';
import { AuthenticationService } from '../../service/authentication.service';

export interface UserProfileModel {
  id: any;
  name: any;
  email: any;
  photoUrl: any;
  firstName: any;
  lastName: any;
  authToken: any;
  idToken: any;
  provider: any;
  providerId: any;
  userId: any;
  displayName: any;
  pictureUrl: any;
}

@Component({
  selector: 'app-vr-callback',
  templateUrl: './vr-callback.component.html',
  styleUrls: ['./vr-callback.component.scss']
})
export class VrCallbackComponent implements OnInit {

  loginForm: FormGroup;
  submitted: boolean;
  progressStatus: boolean;
  identifier: string;
  providerName: string;
  msgLoginUsername: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpClient: AuthenticationService,
    private tokenStored: TokenStorageService,
  ) {
    this.buildLoginForm();
    this.progressStatus = true;
    this.activatedRoute.queryParams.subscribe(params => {
      var code = params['code'];
      if (code) {
        this.getAccesToken(code);
      } else {
        // this.router.navigate(['/events']);
      }
    })
  }

  ngOnInit() {
  }

  buildLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required)
    });
  }

  get login() { return this.loginForm.controls }

  getAccesToken(code) {
    this.httpClient.validateProviderEmail(code).subscribe(async (resValidEmail: any) => {
      if (resValidEmail.providerName && resValidEmail.providerId) {
        this.identifier = resValidEmail.providerId;
        this.providerName = resValidEmail.providerName;

        if (resValidEmail.status && (resValidEmail.email || resValidEmail.tel)) {
          let userProfile: UserProfileModel = {
            id: '',
            name: '',
            email: '',
            photoUrl: '',
            firstName: '',
            lastName: '',
            authToken: '',
            idToken: '',
            provider: '',
            providerId: '',
            userId: '',
            displayName: '',
            pictureUrl: '',
          };

          userProfile.provider = resValidEmail.providerName;
          userProfile.name = '';
          userProfile.firstName = '';
          userProfile.lastName = '';
          userProfile.email = resValidEmail.email;
          userProfile.photoUrl = '';
          userProfile.id = resValidEmail.providerId;
          userProfile.providerId = resValidEmail.providerId;

          this.httpClient.createSocialProfile(userProfile)
            .subscribe((data : any) => {
              this.tokenStored.saveToken(data.token);
              this.tokenStored.saveUsername(data.username);
              let userProfileRes = data.userProfile;
              userProfileRes.expireDate = data.expireDate;
              this.tokenStored.saveUserProfile(userProfileRes);

              // this.tokenStored.saveToken(data.token);
              // this.tokenStored.saveUsername(data.username);
              // let userProfile = JSON.stringify(data.userProfile);
              // this.tokenStored.saveUserProfile(userProfile);
       
              if (userProfile != null) {
                var imgSplit;
                if (userProfileRes.imageProfile != null) {
                  imgSplit= userProfileRes.imageProfile.split("_")[0];
                  if (imgSplit == 'IM') {
                    this.tokenStored.saveImgProfile(userProfileRes.imageProfileUrl);
                  } else {
                    this.tokenStored.saveImgProfile(userProfileRes.imageProfile);
                  }
                } else {
                  this.tokenStored.saveImgProfile('https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png');
                }
              }
              this.router.navigate(['/events']).then(() => {
                window.location.reload();
              });
            })
        } else {
          this.progressStatus = false;
        }
      } else {
        this.router.navigate(['/events']).then(() => {
          window.location.reload();
        });
      }

    });
  }

  checkLogin() {
    let userProfile: UserProfileModel = {
      id: '',
      name: '',
      email: '',
      photoUrl: '',
      firstName: '',
      lastName: '',
      authToken: '',
      idToken: '',
      provider: '',
      providerId: '',
      userId: '',
      displayName: '',
      pictureUrl: '',
    };

    this.submitted = true;
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.loginForm.controls.email.value.match(regex)) {
      this.login.email.setErrors({ 'incorrect': true });
    }

    if (this.loginForm.controls.email.value == "") {
      this.login.email.setErrors({ 'required': true });
    }

    if (this.loginForm.valid) {

      userProfile.provider = this.providerName;
      userProfile.name = '';
      userProfile.firstName = '';
      userProfile.lastName = '';
      userProfile.email = this.login.email.value;
      userProfile.photoUrl = '';
      userProfile.id = this.identifier;
      userProfile.providerId = this.identifier;

      this.httpClient.createSocialProfile(userProfile)
        .subscribe(data => {
          this.tokenStored.saveToken(data.token);
          this.tokenStored.saveUsername(data.username);
          let userProfile = JSON.stringify(data.userProfile);
          this.tokenStored.saveUserProfile(userProfile);
          var imgSplit;
          if (userProfile != null) {
            if (JSON.parse(userProfile).imageProfile != null) {
              imgSplit = JSON.parse(userProfile).imageProfile.split("_")[0]
              if (imgSplit == 'IM') {
                this.tokenStored.saveImgProfile(JSON.parse(userProfile).imageProfileUrl);
              } else {
                this.tokenStored.saveImgProfile(JSON.parse(userProfile).imageProfile);
              }
            } else {
              this.tokenStored.saveImgProfile('https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png');
            }
          }
          this.router.navigate(['/events']).then(() => {
            window.location.reload();
          });
        })
    } else {
      this.progressStatus = false;
    }

  }

}
