import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { EncrDecrService } from './encr-decr.service';
import { environment } from 'src/environments/environment';
import { UserProfileModel } from '../model/userProfile.model';

const TOKEN_KEY = 'Ah_';
const USERNAME_KEY = 'AU_';
const AUTHORITIES_KEY = 'E_';
const USER_PROFILE = 'U_';
const ORDER_KEY = 'orderKey';
const ORDER_SESSION = 'OrderSession';
const VRLOG_SESSION = 'VrlogSession';
const LANGUAGE = 'lang';
const SAVE_FORM = 'saveForm';
const IMG_PROFILE = 'imgprofile';
const EVENT_NAME = 'eventName';
const URL_PATH = 'urlPath';
const LPV = 'province'

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  secretKey = environment.secretKey;

  private roles: Array<string> = [];
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private EncrDecr: EncrDecrService
  ) { }

  signOut() {
    if (isPlatformBrowser(this.platformId))
      window.localStorage.clear();
  }

  public saveToken(token: string) {
    const encrypted = this.EncrDecr.set(this.secretKey, token);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, encrypted);
  }

  public getToken(): string {
    const token = localStorage.getItem(TOKEN_KEY);
    let decrypted = token;

    if (token != null) {
      decrypted = this.EncrDecr.get(this.secretKey, token);
    }
    return decrypted;
  }

  public saveUserProfile(userProfile: any) {
    const encrypted = this.EncrDecr.set(this.secretKey, JSON.stringify(userProfile));

    window.localStorage.removeItem(USER_PROFILE);
    window.localStorage.setItem(USER_PROFILE, encrypted);
  }

  public getUserProfile(): any {
    let userProfile;
    const fullName = localStorage.getItem(USER_PROFILE);
    let decrypted = fullName;

    if (fullName != null) {
      decrypted = this.EncrDecr.get(this.secretKey, fullName);
      let userProfileStr = JSON.stringify(decrypted);
      let userProfileObj = JSON.parse(userProfileStr);
      userProfile = JSON.parse(userProfileObj);
    }
    return userProfile;
  }

  public getUserProfileModel(): UserProfileModel {
    let userProfile;
    const fullName = localStorage.getItem(USER_PROFILE);
    let decrypted = fullName;

    if (fullName != null) {
      decrypted = this.EncrDecr.get(this.secretKey, fullName);
      let userProfileStr = JSON.stringify(decrypted);
      let userProfileObj = JSON.parse(userProfileStr);
      userProfile = JSON.parse(userProfileObj);
    }
    return userProfile;
  }


  public removeUserProfile(): any {
    window.localStorage.removeItem(USER_PROFILE);
  }


  public saveURLEvent(eventName: string) {
    window.localStorage.removeItem(EVENT_NAME);
    window.localStorage.setItem(EVENT_NAME, eventName);
  }

  public getURLEvent(): string {
    return localStorage.getItem(EVENT_NAME);
  }

  public saveURLPath(urlPath: string) {
    window.localStorage.removeItem(URL_PATH);
    window.localStorage.setItem(URL_PATH, urlPath);
  }

  public getURLPath(): string {
    return localStorage.getItem(URL_PATH);
  }

  public saveSessionOrder(value) {
    window.localStorage.removeItem(ORDER_SESSION);
    window.localStorage.setItem(ORDER_SESSION, value);
  }

  public getSessionOrder(): any {
    return localStorage.getItem(ORDER_SESSION);
  }

  public removeSessionOrder(): any {
    window.localStorage.removeItem(ORDER_SESSION);
  }

  public saveSessionVrLog(value) {
    window.localStorage.removeItem(VRLOG_SESSION);
    window.localStorage.setItem(VRLOG_SESSION, value);
  }

  public getSessionVrLog(): any {
    return localStorage.getItem(VRLOG_SESSION);
  }

  public removeSessionVrLog(): any {
    window.localStorage.removeItem(VRLOG_SESSION);
  }

  public saveUsername(username: string) {
    window.localStorage.removeItem(USERNAME_KEY);
    window.localStorage.setItem(USERNAME_KEY, username);
  }

  public saveLang(lang: string) {
    window.localStorage.removeItem(LANGUAGE);
    window.localStorage.setItem(LANGUAGE, lang);
  }

  public getLang(): string {
    return localStorage.getItem(LANGUAGE);
  }

  public saveForm(language: string) {
    window.localStorage.removeItem(SAVE_FORM);
    window.localStorage.setItem(SAVE_FORM, language);
  }

  public getForm(): string {
    return localStorage.getItem(SAVE_FORM);
  }

  public getUsername(): string {
    return localStorage.getItem(USERNAME_KEY);
  }

  public saveAuthorities(authorities: string[]) {
    window.localStorage.removeItem(AUTHORITIES_KEY);
    window.localStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.roles = [];

    if (localStorage.getItem(TOKEN_KEY)) {
      JSON.parse(localStorage.getItem(AUTHORITIES_KEY)).forEach(authority => {
        this.roles.push(authority.authority);
      });
    }

    return this.roles;
  }
  public saveImgProfile(img: string) {
    window.localStorage.removeItem(IMG_PROFILE);
    window.localStorage.setItem(IMG_PROFILE, img);
  }
  public getImgProfile(): string {
    return localStorage.getItem(IMG_PROFILE);
  }

  public saveAllProvince(value) {
    window.localStorage.removeItem(LPV);
    window.localStorage.setItem(LPV, value);
  }
  public getAllProvince(): any {
    return localStorage.getItem(LPV);
  }
  public removeAllProvince(): any {
    window.localStorage.removeItem(LPV);
  }


  public saveOrderKey(orderKey: string) {
    window.localStorage.removeItem(ORDER_KEY);
    window.localStorage.setItem(ORDER_KEY, orderKey);
  }
  public getOrderKey(): string {
    return localStorage.getItem(ORDER_KEY);
  }
  public removeOrderKey(): any {
    window.localStorage.removeItem(ORDER_KEY);
  }

}
