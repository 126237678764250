import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vrgo-section-footer',
  templateUrl: './vrgo-section-footer.component.html',
  styleUrls: ['./vrgo-section-footer.component.scss']
})
export class VrgoSectionFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
  listImage = [
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-01.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-02.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-03.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-04.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-05.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-06.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-07.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-08.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-09.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-10.png'
    }
  ]
  listImage1 = [
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-01.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-02.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-03.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-04.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-05.png'
    }
  ]
  listImage2 = [
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-06.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-07.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-08.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-09.png'
    },
    {
      img: '../../../../assets/images/vrgo/content_footer/Pic-10.png'
    }
  ]

}
