import { Injectable, NgZone, ViewChild } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FacebookApiService } from '../service/facebook-api.service';
import { HttpClientService } from '../service/httpclient.service';
import { TokenStorageService } from './token-storage.service';
import { Location } from '@angular/common';
import { AuthenticationService } from '../service/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LineApiService } from '../service/line-api.service';
import base64url from "base64url";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import VConsole from 'vconsole';
import { SocialUser } from 'angular4-social-login';
import { UserProfileModel } from '../model/userProfile.model';
import { environment } from '../../../environments/environment';
declare var liff: any;

@Injectable({
  providedIn: 'root'
})
export class AuthSocialGuard implements CanActivate {

  constructor(
    private facebookApi: FacebookApiService,
    private httpService: HttpClientService,
    private rountLocation: Location,
    private storageService: TokenStorageService,
    private authenService: AuthenticationService,
    private modalService: NgbModal,
    private lineApi: LineApiService,
    private rounter: Router,
    private ngxService: NgxUiLoaderService,
    private ngZone: NgZone
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    // let vConsole = new VConsole()
    let url: string = state.url;
    // console.log("next ", next);
    // console.log("state ", state);
    if (liff.isInClient()) {
      // console.log("isInClient ");
      return this.lineLiff(route.routeConfig.path);
    } else {
      return this.checkLogin(route.routeConfig.path, route);
    }

  }

  async checkLogin(path: any, route: any) {
    this.modalService.dismissAll();
    this.ngxService.start();

    // if (next.params && next.params.name) {
    //   url = next.params.name;
    // }

    // if (next.data && next.data.path) {
    //   url = next.data.path;
    //   if (url == '/') {
    //     url = "";
    //   }
    // }


    if (route.queryParams['state']) {
      // console.log("code ", next.queryParams.code);
      // console.log("url ", this.storageService.getURLPath());
      if (route.params['name']) {
        path = route.params['name'];
      }

      let responseReturn = this.lineApi.afterLoginLine(route.queryParams['code'], path);
      return await responseReturn.then(() => {
        this.ngxService.stop();
        return true;
      });
      
    } else if (route.queryParams['code'] && !route.queryParams['state']) {
      if (route.params['name']) {
        path = route.params['name'];
      }
      this.facebookApi.facebookAccesTokenToMain(route.queryParams['code'], path).toPromise().then((response: any) => {

        var accessToken = response['access_token'];
        return accessToken;

      }).then((accessToken) => {

        this.facebookApi.getUserId(accessToken).toPromise().then(userData => {
          return userData;

        }).then((userData) => {

          this.facebookApi.getUserProfile(userData.data.user_id, accessToken).toPromise()

            .then(profileData => {

              // console.log("profileData 1 > ", profileData);
              // console.log("profileData 1 > ", profileData.picture);
              // console.log("profileData 1 > ", profileData.picture.data.url);

              // console.log("profileData 2 > ", profileData['picture']);
              // console.log("profileData 2 > ", profileData['picture'].data.url);
              // console.log("profileData 2 > ", profileData['picture']['data']['url']);

              const userProfile: any = new Object;
              userProfile['provider'] = "FACEBOOK";
              userProfile['id'] = profileData['id'];
              userProfile['firstName'] = profileData['first_name'];
              userProfile['lastName'] = profileData['last_name'];
              userProfile['email'] = profileData['email'];
              userProfile['photoUrl'] = profileData.picture.data.url;
              userProfile['providerId'] = profileData['id'];

              // console.log("userProfile", userProfile);


              this.authenService.createSocialProfile(userProfile).toPromise()
                .then((response: any) => {

                  // console.log("socialAuthentication FACEBOOK > ", response);

                  this.storageService.saveToken(response.token);
                  var userProfileS = new UserProfileModel;
                  userProfileS = response.userProfile
                  userProfileS.expireDate = response.expireDate;
                  this.storageService.saveUserProfile(userProfileS);
                  this.storageService.saveUsername(response.username);
                  this.ngxService.stop();
                  this.rounter.navigate([path]);
                  return true;

                })

            })

        });

      })

    } else {
      this.ngxService.stop();
      return true;
    }
  }

  async lineLiff(path: any) {
    return this.ngZone.run(async () => {

      // console.log("path . ", path);
      let liffId = "1655806469-79mABZ4v";
      if (path == 'upload-vr') {
        liffId = "1655806469-po2obYdX";
      } else if (path == 'my-account') {
        liffId = "1655806469-x4zR2jdM";
      } else if (path == 'my-dashboard') {
        liffId = "1655806469-mEeBlzDX"
      }

      // console.log("ngZone ");
      await liff.init({ liffId: liffId });
      // console.log("liffId ");
      await liff.ready.then(async () => {
        // console.log("ready ");
        if (liff.isLoggedIn()) {
          // console.log("isLoggedIn ");
          let userProfile: any;
          let idToken: any;
          let email: any;
          let imageProfile: any;


          userProfile = await liff.getProfile();
          idToken = await liff.getDecodedIDToken();
          email = await liff.getDecodedIDToken().email;
          imageProfile = await userProfile.pictureUrl;

          const user: SocialUser = new SocialUser;
          (window as any).global = window;
          global.Buffer = global.Buffer || require('buffer').Buffer;

          user['provider'] = 'LINE';
          user.name = '';
          user.firstName = '';
          user.lastName = '';
          user.email = email;
          user.photoUrl = imageProfile;
          user.id = userProfile.userId;
          user['providerId'] = user.id;

          // console.log("before createSocialProfile ");
          let responseReturn = this.createSocialProfile(user);
          await responseReturn.then(() => {
            this.ngxService.stop();
          });

        } else {
          liff.login();
        }
      });
      return true;
    });

  }

  async createSocialProfile(user: any) {
    return await new Promise(resolve => {
      this.authenService.createSocialProfile(user).subscribe((response: any) => {
        // console.log("after createSocialProfile ");
        this.storageService.saveToken(response.token);
        var userProfileS = new UserProfileModel;
        userProfileS = response.userProfile
        userProfileS.expireDate = response.expireDate;
        this.storageService.saveUserProfile(userProfileS);
        this.storageService.saveUsername(response.username);
        resolve(true);
      })
    })
  }

}
