import { Component, ElementRef, Input, AfterViewInit, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenStorageService } from '../../auth/token-storage.service';
import { FlagNationDashboardModel } from '../../model/flagnation-dashboard.model';
import { VRTicket } from '../../model/vrTicket.model';
import { HttpClientService } from '../../service/httpclient.service';

@Component({
  selector: 'app-vrgo-ticket-info-rank',
  templateUrl: './vrgo-ticket-info-rank.component.html',
  styleUrls: ['./vrgo-ticket-info-rank.component.scss']
})
export class VrgoTicketInfoRankComponent implements OnInit {

  @ViewChild("animatedDigitDistance", { static: false }) animatedDigitDistance: ElementRef;

  userProfile: any;

  summaryCurrencyOverAll: string = "";
  summaryRunnerOverAll: string = "";
  fullName: string = "";
  imageProfileUrl: string = "";
  imageUrlEcert: string = "";
  imageUrlEbib: string = "";
  profileStatus: string = "";
  
  summaryAllDistance: any ;
  sumDistance: number ;
  duration: number;
  steps: number;

  flagNation: FlagNationDashboardModel;
  listTeam : FlagNationDashboardModel['listTeam'];
  listUserRanking : FlagNationDashboardModel['listUserRanking'];

  listVRTicket: VRTicket[] = [];
  vrTicket: VRTicket;
  listEbadgeDetail: any;
  imageEbadge: any;
  userRankingDesc: any;


  constructor(
    private httClient: HttpClientService,
    private tokenStored: TokenStorageService,
  ) {

    if(this.tokenStored.getUserProfile()){
      this.userProfile = this.tokenStored.getUserProfile();
      this.fullName = this.userProfile.firstName + " " + this.userProfile.lastName;
      this.imageProfileUrl = this.userProfile.imageProfileUrl;
    }
   

  }


  ngOnInit() {
    this.getFalgOfNationDashboard();
  }


  getFalgOfNationDashboard() {
    let flagNation = new FlagNationDashboardModel;

    if(this.tokenStored.getUserProfile()){
      flagNation.userId = this.userProfile.userId ;
    }else{
      flagNation.userId = 0 ;
    }
    
    this.httClient.getFalgOfNationDashboard(flagNation).subscribe(response => {
      this.flagNation = response;

      this.userRankingDesc = response.userRanking;
      this.profileStatus = response.profileStatus;
      this.summaryAllDistance = response.summaryAllDistance;
      this.summaryRunnerOverAll = response.summaryRunnerOverAll;

      if (this.flagNation.listTeam){
        // console.log(this.flagNation.listTeam);
        this.listTeam =   this.flagNation.listTeam;
      }

      if (this.flagNation.listUserRanking){
        this.listUserRanking = this.flagNation.listUserRanking;
      } 

      if(this.tokenStored.getUserProfile()){
        this.listUserVRTicket();
      }
      
     
    });
  }

  listUserVRTicket() {
    let userObject = new Object();
    userObject['userId'] = this.userProfile.userId;
    this.httClient.listUserVRTicket(userObject).subscribe(data => {

      this.mapData(data);

    });
  }

  mapData(data) {
    this.listVRTicket = data;
    const f = this.listVRTicket.filter(c => c.eventId == this.flagNation.eventId && c.ticketId == this.flagNation.ticketId)[0];
    this.vrTicket = f;

    if(this.vrTicket){
      this.sumDistance =  this.vrTicket.sumDistance;
  
      this.getEcertData();
      this.getEbibData();
      this.getEBadge();
    }

  }

  getEcertData() {
    const param = new Object();

    param['eventId'] = this.flagNation.eventId;
    param['ticketId'] = this.flagNation.ticketId;
    param['orderId'] = this.flagNation.orderId;

    this.httClient.generateEcert(param).subscribe(respons => {

      if (this.vrTicket.completeStatus == 'INCOMPLETE') {
        this.imageUrlEcert = respons.imageTamplateUrl;

      } else if (this.vrTicket.completeStatus == 'COMPLETE') {
        this.imageUrlEcert = respons.imageUrl;
      }

    });
  }

  getEbibData() {
    const param = new Object();

    param['eventId'] = this.vrTicket.eventId;
    param['ticketId'] = this.vrTicket.ticketId;
    param['orderId'] = this.vrTicket.orderId;

    this.httClient.generateEBIB(param).subscribe(respons => {

      if (respons.imageUrl != null) {
        this.imageUrlEbib = respons.imageUrl;
      }

    })
  }

  getEBadge() {

    let param = new FormData();
    param.append('userId', this.userProfile.userId);
    this.httClient.getEBadgeByUser(param).subscribe(response => {
      this.listEbadgeDetail = response['listEbadge'];

      var listEbadgeDetails = []
      if (this.listEbadgeDetail != undefined && this.listEbadgeDetail != null) {

        for (let i = 0; i < this.listEbadgeDetail.length; i++) {
          if (this.listEbadgeDetail[i].eventId == 58) {
            this.imageEbadge = this.listEbadgeDetail[i].imagePath;
            listEbadgeDetails.push(this.listEbadgeDetail[i].imagePath);
          }
        }

        if (listEbadgeDetails.length > 0) {
          this.imageEbadge = listEbadgeDetails[0];
        }

      }
    });
  }



}
