import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from '../../auth/token-storage.service';
import { VRTicket } from '../../model/vrTicket.model';
import { HttpClientService } from '../../service/httpclient.service';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { UsersModel } from '../../share-class/UsersModel';
import { SocialUser } from 'angular4-social-login';
import { AuthenticationService } from '../../service/authentication.service';
import { FormControl, FormGroup } from '@angular/forms';
import { VRGoModalLoginComponent } from '../vrgo-modal-login/vrgo-modal-login.component';
import { FacebookApiService } from '../../service/facebook-api.service';
import { Location } from '@angular/common';
import VConsole from 'vconsole';
import { UserProfileModel } from '../../model/userProfile.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var liff: any;

@Component({
  selector: 'app-vrgo-upload-all-event',
  templateUrl: './vrgo-upload-all-event.component.html',
  styleUrls: ['./vrgo-upload-all-event.component.scss']
})
export class VrgoUploadAllEventComponent implements OnInit {

  @ViewChild(VRGoModalLoginComponent, { static: false }) vrGoModal: VRGoModalLoginComponent;
  @ViewChild('vrValid', { static: false }) vrValid;
  @ViewChild('vrComplete', { static: false }) vrComplete;
  @ViewChild('ImgModal', { static: true }) ImgModal;
  @ViewChild('upLoadVr', { static: true }) upLoadVr;
  @ViewChild('upLoadError', { static: true }) upLoadError;
  @ViewChild('ticketSingleAll', { static: true }) ticketSingleAll;
  @ViewChild('ticketSingleDay', { static: true }) ticketSingleDay;
  @ViewChild('lodingModal', { static: false }) lodingModal;
  @ViewChild('emailNull', { static: false }) emailNull;

  @Output() messageEvent = new EventEmitter<any>();

  vrTicketForm: FormGroup;
  userId: string;
  listVr: VRTicket[] = [];
  filterVr: VRTicket;
  searchSataus: boolean = false;
  fileToUpload: File = null;
  filename: string = '';
  uploaded: boolean = false;
  fileData: File = null;
  previewUrl: any = null;
  orderNumber: string = '';
  orderId: any = '';
  vrId: number;
  ticketId: number;
  distance: string = '';
  location: string = '';
  submited: boolean = false;
  eventId: any;
  listVRLogModel: any[];
  data: any;
  url = '';
  validateUploadLogs: VRTicket;
  statusTicketVr: any;
  submitStatus: any;
  uploadeds: boolean;

  vrTicket: VRTicket[] = [];
  CompeletClass: string = 'form-control';
  btnsubmit: any = 'ส่งผลกิจกรรม';
  fieldDataType: any = 'NUMBER';
  currentLesson: any = '1';
  uploadVRStatus: any = 'AVALABLE';
  second: any = [];
  minute: any = [];
  hour: any = [];
  secondValue: string;
  minuteValue: string;
  hourValue: string;
  period: string = '';
  language: any;
  imgVrLog: any;
  userProfile: any;
  idToken: any;
  email: any;
  imageProfile: any;
  invalidLogin: boolean;

  errStatus: boolean = true;
  listEvent: any[];
  filterListTicket: any;
  listVrTicket: any;
  msgError: string;

  code: string;

  constructor(
    private httClient: HttpClientService,
    private tokenStored: TokenStorageService,
    private param: ParameterUrl,
    private rounter: Router,
    private modalService: NgbModal,
    private modalValid: NgbActiveModal,
    private modalComplete: NgbActiveModal,
    private loding: NgbActiveModal,
    private upLoadErrorModal: NgbActiveModal,
    private translate: TranslateService,
    private authenService: AuthenticationService,
    public rountLocation: Location,
    private ngxService: NgxUiLoaderService
  ) {

    this.buildVrForm();
    var time = new Array(
      '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
      '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
      '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
      '41', '42', '43', '44', '45', '46', '47', '48', '49', '50',
      '51', '52', '53', '54', '55', '56', '57', '58', '59', '60');
    this.second = time;
    this.minute = time;

    this.getListBox();

  }

  ngOnInit() {

    this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
      this.language = result;
      this.btnsubmit = this.language.UPLOAD_VR_PAGE.btnSubmit;
    });

    this.initService();

  }

  ngAfterViewInit(): void {

    // this.initService();

  }

  filterTicket(event) {
    this.vrTicketForm.controls.vrId.setValue("");
    let eventId = event.target.value;
    if (this.vrTicketForm.controls.eventId.value != '') {
      const filterTicket = this.listEvent.filter(c => c.eventId == eventId);
      this.filterListTicket = filterTicket[0].listTicket;
    }
  }

  filterVrTicket(event) {
    let vrId = event.target.value;
    this.vrId = vrId;
    const filterVrTicket = this.listVrTicket.filter(c => c.vrId == vrId);
    this.filterVr = filterVrTicket[0];
  }

  search() {
    if (this.vrTicketForm.status == 'VALID') {
      this.errStatus = false;
      this.loding = this.modalService.open(this.lodingModal, { backdrop: 'static', windowClass: 'myCustomPopup' });

      setTimeout(() => {
        this.loding.close();

        this.uploadVRStatus = this.filterVr.uploadVRStatus;
        if (this.filterVr.completeStatus == 'COMPLETE') {
          this.modalComplete = this.modalService.open(this.vrComplete, { backdrop: 'static', windowClass: 'myCustomModalClass' });
        } else if (this.filterVr.uploadVRStatus == 'UNAVAILABLE') {
          this.modalValid = this.modalService.open(this.vrValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });
        } else {
          this.validateUploadLog();
          const param = new Object();
          param['orderId'] = this.filterVr.orderId;
          param['eventId'] = this.filterVr.eventId;
          param['ticketId'] = this.filterVr.ticketId;
          // console.log("param", param);
          this.getviewVrHistroyLog(param);
        }
      }, 1000);
    }

  }

  handleFileInput(files: FileList) {
    this.loding = this.modalService.open(this.lodingModal, { backdrop: 'static', windowClass: 'myCustomPopup' });
    if (files) {
      this.loding.close();
      this.fileToUpload = files.item(0);
      // console.log("file name >", files.item(0));
      this.filename = this.fileToUpload.name;
      if (this.fileToUpload.type == 'image/jpeg') {
        // console.log("UPLOAD SUCCESS");
      } else if (this.fileToUpload.type == 'image/png') {
        // console.log("UPLOAD SUCCESS");
      } else {
        this.msgError = "ไฟล์ต้องเป็น .jpg หรือ .png เท่านั้น";
        this.upLoadErrorModal = this.modalService.open(this.upLoadError, { backdrop: 'static' });
      }
    }
  }

  fileremove() {
    this.filename = null;
  }

  uploadVR() {

    this.submited = true;
    if (this.hourValue == undefined || this.hourValue == null || this.hourValue == '') {
      this.hourValue = '0';
    }
    if (this.secondValue == undefined || this.secondValue == null || this.secondValue == '') {
      this.secondValue = '0';
    }
    if (this.minuteValue == undefined || this.minuteValue == null || this.minuteValue == '' || this.minuteValue == '0') {
      this.minuteValue = '0'
      this.period = 'B';
    } else {
      this.period = 'A';
    }

    if (this.distance != '' && this.filename != '' && this.location != '' && this.period == 'A') {
      this.currentLesson = '2';
      this.btnsubmit = this.language.REGISTER_SUMMARY_PAGE.wait;
      this.httClient.uploadVRLog(this.fileToUpload, this.filterVr.vrId, Number(this.userId), this.filterVr.ticketId, this.distance, this.location, this.hourValue, this.minuteValue, this.secondValue).subscribe(data => {
        if (data['fileDownloadUri'] != '' && this.submitStatus == 'SINGLE_ALL' && this.uploadeds == false) {
          this.uploaded = true;
          this.modalUploadVr();
        } else if (data['fileDownloadUri'] != '' && this.submitStatus == 'SINGLE_ALL' && this.uploadeds == true) {
          this.modalTicketSingleAll();
        } else if (data['fileDownloadUri'] != '' && this.submitStatus == 'SINGLE_DAY' && this.uploadeds == false) {
          this.uploaded = true;
          this.modalUploadVr();
        } else if (data['fileDownloadUri'] != '' && this.submitStatus == 'SINGLE_DAY' && this.uploadeds == true) {
          this.modalTicketSingleDay();
        } else if (data['fileDownloadUri'] != '' && this.submitStatus == 'MULTI' && this.uploadeds == false) {
          this.uploaded = true;
          this.modalUploadVr();
        }

      }, error => {
        console.log(error);
      });
    }
  }

  modalTicketSingleAll() {
    this.modalService.dismissAll();
    this.modalService.open(this.ticketSingleAll, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }

  modalTicketSingleDay() {
    this.modalService.dismissAll();
    this.modalService.open(this.ticketSingleDay, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }

  modalUploadVr() {
    this.modalService.dismissAll();
    this.modalService.open(this.upLoadVr, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }

  validateUploadLog() {
    this.httClient.validateUploadLog(this.vrId, this.userId).subscribe(data => {
      this.statusTicketVr = data;
      this.submitStatus = this.statusTicketVr.submitStatus;
      this.uploadeds = this.statusTicketVr.uploaded;
    })
  }

  getviewVrHistroyLog(param) {
    this.httClient.getviewVrHistroyLog(param).subscribe(Response => { this.mapdata(Response) });
  }

  mapdata(data) {
    // console.log('data', data)
    this.data = data
    this.listVRLogModel = data.listVRLogModel;
    this.searchSataus = true;
    // console.log('listVRLogModel',this.listVRLogModel);
  }

  openImg(img) {
    this.imgVrLog = img.imageName;
    this.modalService.open(this.ImgModal, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }

  onChange(event, type) {
    const key = event.key;
    if (type === 'NUMBER') {
      if (!key.match('^-?(0|[1-9.]\d*)?$')) {

        event.preventDefault();
      }
    }
  }
  changeSecond(second) {
    // console.log('second >> ', second.value);
    this.secondValue = second.value;

  }
  changeMinute(minute) {
    // console.log('minute >> ', minute.value);
    this.minuteValue = minute.value;
  }
  changeHour(hour) {
    // console.log('hour >> ', hour.value);
    this.hourValue = hour.value;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }
  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
  getListBox() {
    this.httClient.getListBox('HOURTYPE').subscribe(data => {
      this.hour = data
    });
  }

  gotoVrCompelete() {
    this.modalService.dismissAll();
    this.validateUploadLog();
    const param = new Object();
    param['orderId'] = this.filterVr.orderId;
    param['eventId'] = this.filterVr.eventId;
    param['ticketId'] = this.filterVr.ticketId;
    // console.log("param", param);
    this.getviewVrHistroyLog(param);
  }

  closeModel() {
    this.modalService.dismissAll();
    window.location.reload();
  }

  receiveModalPopupError(event) {

  }

  async initService() {
    if (liff.isInClient()) {

      var userDetail = new UserProfileModel;

      userDetail = this.tokenStored.getUserProfileModel();
      this.userId = userDetail.userId;
      // console.log("upload userDetail" ,userDetail);
      if (userDetail.userId) {
        this.listUserEvent(userDetail.userId);
      }
    } else {

      if (this.tokenStored.getUserProfile()) {
        this.modalService.dismissAll();

        var userDetail = new UserProfileModel;

        userDetail = this.tokenStored.getUserProfileModel();
        this.userId = userDetail.userId;

        if (userDetail.userId) {
          this.listUserEvent(userDetail.userId);
        }

      } else {
        this.tokenStored.saveURLPath('/upload-vr');
        this.vrGoModal.popupConfirm(this.rounter.url);
      }
    }
  }

  listUserEvent(userId) {

    this.httClient.listUserEvent(userId).toPromise().then(response => {
      this.listEvent = response;
      let userObject = new Object();
      userObject['userId'] = this.userId;
      userObject['languageStatus'] = this.tokenStored.getLang();
      this.listUserVRTicket(userObject);
    }, err => {
      console.log(err);
    });

  }

  listUserVRTicket(userObject) {
    this.httClient.listUserVRTicket(userObject).toPromise().then(data => {
      this.listVrTicket = data;
      this.ngxService.stop();
    });
  }

  buildVrForm() {
    this.vrTicketForm = new FormGroup({
      eventId: new FormControl(''),
      vrId: new FormControl('')
    })
  }

}
