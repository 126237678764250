import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
@Component({
  selector: 'app-vrgo-credit-card',
  templateUrl: './vrgo-credit-card.component.html',
  styleUrls: ['./vrgo-credit-card.component.scss']
})
export class VRGoCreditCard2C2PComponent implements OnInit {

  @ViewChild('paymentForm', {static: false}) paymentForm: ElementRef;

  // secret_key : string = 'ymVg2b3rQRHs'; // DEMO TEST
  secret_key : string = '6E7CEE53D3B2E81085920D5806A884AFDAF92EFE8C09871A75EADE863D87C842'; //PRODUCTION
  version : string = '8.5';
	merchant_id : string = '764764000001067';
	currency : string = '764';
  // result_url_1 : string = 'http://ckuat.codecaster.cc/PaymentController/responseCreditCard';
  result_url_1 : string = '';
	hash_value : string = '';
  payment_description : string = '';
	order_id : string = '';
  amount : string = '';
  // user_defined_1 : string = '';

  constructor(
    private service: HttpClientService,
    private parameter: ParameterUrl,
    private router:Router) { }

  ngOnInit() {

    // console.log('order_id : '+this.parameter.data.orderNumber);
    if(this.parameter.data != undefined && this.parameter.data !=null){
      this.order_id = this.parameter.data.orderNumber;
      // this.user_defined_1 = this.parameter.data.orderId;
      // console.log('amount : '+this.parameter.data.orderNumber);
      let num = this.parameter.data.amount;
      let n = ''+num.toFixed(2);
      let ne = n.replace('.', '');
      this.amount = this.pad(ne, 12);
  
      // console.log('payment_description : '+this.parameter.data.orderNumber);
      this.payment_description = this.parameter.data.desc;
      
      const param = new Object();
  
      param['param'] = this.version+this.merchant_id+this.payment_description+this.order_id+this.currency+this.amount+this.result_url_1;
      param['secretKey'] = this.secret_key;
  
  
      this.service.calculateHMACSha256(param).subscribe(  
        response => this.onClickSubmit(response)
      );
    }else{
      this.router.navigate(['/']);
    }
   
  }

  onClickSubmit(response) {
    this.hash_value = response.hashValue
    setTimeout(() => {
      this.paymentForm.nativeElement.submit();
    }, 100);
  }

  pad(str, max) {
    str = str.toString();
    return str.length < max ? this.pad("0" + str, max) : str;
  }
  
}
