import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { Order } from '../../model/order.model';
import { TokenStorageService } from '../../auth/token-storage.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FunctionUtils } from '../../utils/functionUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
import { VRTicket } from '../../model/vrTicket.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vrgo-user-vr-ticket',
  templateUrl: './vrgo-user-vr-ticket.component.html',
  styleUrls: ['./vrgo-user-vr-ticket.component.scss']
})
export class VRGoUSERVRTicketComponent implements OnInit {
  @ViewChild('vrValid', { static: false }) vrValid;
  @ViewChild('vrComplete', { static: false }) vrComplete;

  returnedArray: VRTicket[];

  userId: number;
  vrTicket: VRTicket[];
  vrTicketComplete: VRTicket;
  test: any = [];
  btnVrTicket: any = 'ส่งผลการวิ่ง';
  language: any;
  constructor(
    private httClient: HttpClientService,
    private tokenStored: TokenStorageService,
    private fn: FunctionUtils,
    private param: ParameterUrl,
    private rounter: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.vrTicket = [];
    this.test = [];
    const userDetail = this.tokenStored.getUserProfile();
    // //console.log('user detail : '+JSON.stringify(userDetail));
    //console.log('userId : '+userDetail);
    if (userDetail != null && userDetail['userId'] != undefined) {
      this.userId = Number(userDetail['userId']);
      //console.log('userId : '+this.userId);
      if (this.userId != null) {
        this.translate.addLangs(['th', 'en']);
        this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
          this.language = result;
          // console.log("language" ,this.language);
          let userObject = new Object();
          userObject['userId'] = this.userId;
          // //console.log(userObject);
          this.httClient.listUserVRTicket(userObject).subscribe(data => {
            this.mapVRTicket(data);
            this.test = data;
            // console.log('this.test:',this.test);
          });
        });
      }
    } else {
      this.rounter.navigate(['./']);
    }

  }

  mapVRTicket(data: any) {
    this.vrTicket = data;
    //console.log('list ticket : ');
    this.returnedArray = this.fn.slice(this.vrTicket, 0, 10);
    //console.log('list returnedArray : ',this.returnedArray);
    for (let i = 0; i < this.returnedArray.length; i++) {
      if (this.returnedArray[i].uploadVRStatus === 'UNAVAILABLE') {
        this.returnedArray[i].btnVrTicket = this.language.USER_VR_TICKET_PAGE.userVrTicket_Page_table_waiting;
        // this.btnVrTicket = 'ยังไม่ถึงกำหนดส่งผล';
      } else {
        this.returnedArray[i].btnVrTicket = this.language.USER_VR_TICKET_PAGE.userVrTicket_Page_table_col1;
      }
      //  console.log('this.test:',this.language.USER_VR_TICKET_PAGE);
    }
    // this.vrValids();
  }

  pageChanged(event: PageChangedEvent): void {
    this.translate.addLangs(['th', 'en']);
    this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
      this.language = result;
      const startItem = (event.page - 1) * event.itemsPerPage;
      const endItem = event.page * event.itemsPerPage;
      this.returnedArray = this.fn.slice(this.vrTicket, startItem, endItem);
      for (let i = 0; i < this.returnedArray.length; i++) {
        if (this.returnedArray[i].uploadVRStatus === 'UNAVAILABLE') {
          this.returnedArray[i].btnVrTicket = this.language.USER_VR_TICKET_PAGE.userVrTicket_Page_table_waiting;
        } else {
          this.returnedArray[i].btnVrTicket = this.language.USER_VR_TICKET_PAGE.userVrTicket_Page_table_col1;
        }
      }
    });
  }

  vrUpload(vrTicket: VRTicket) {
    this.vrTicketComplete = vrTicket
    if (vrTicket.completeStatus === 'COMPLETE') {
      this.modalService.open(this.vrComplete, { backdrop: 'static', windowClass: 'myCustomModalClass' });
      // this.btnVrTicket = 'ยังไม่ถึงกำหนดส่งผล';
    } else {
      this.param.data = {};
      this.param.data.vrTicket = vrTicket;
      this.rounter.navigate(['./logVrupLoad']);
    }
  }
  gotoVrCompelete() {
    this.modalService.dismissAll();
    this.param.data = {};
    this.param.data.vrTicket = this.vrTicketComplete;
    this.rounter.navigate(['./logVrupLoad']);
  }
  closeModel() {
    this.modalService.dismissAll();
  }
  vrValids() {
    for (let i = 0; i < this.returnedArray.length; i++) {
      if (this.returnedArray[i].uploadVRStatus === 'UNAVAILABLE') {
        this.btnVrTicket = 'ยังไม่ถึงกำหนดส่งผล';
      }
    }
  }

}

