
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

const httpOptions = {
};

@Injectable({
  providedIn: 'root'
})
export class WirtualApiService {
  productionMode : boolean = true;
  constructor() {}

  // Normal Page //
  wirtualLogin() {
    if (!this.productionMode) {
      window.location.href = `https://business-stg.wirtual.co/oauth2/v1/authorize?client_id=1bba4953-de9c-4762-8f87-8a59b35526fa&response_type=code&redirect_uri=http://localhost/callback`;
    } else {
      window.location.href = `https://business.wirtual.co/oauth2/v1/authorize?client_id=aca61502-19f5-44fa-bd60-981b5ceba974&response_type=code&redirect_uri=https://checkrace.com/callback`;
    }
   
    // https://business-stg.wirtual.co/oauth2/v1/authorize?client_id=1bba4953-de9c-4762-8f87-8a59b35526fa&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%2Flanding%3Fpage%3Dmain%26wirtual%3Dcallback
    // http://localhost/landing?page=main&wirtual=callback
  }

}
