export class UsersModel {
    userId :string;
	email :string;
	firstName :string;
	middleName :string;
	lastName :string;
	password :string;
	tel :string;
	authType :string;
	newPassword :string;
	oldPassword :string;
	imageProfile :string;
	imageProfileUrl :string;
	validate : string;
	errorMessage :string;
	invalidUser :string;	
	provider :string;
	providerId :string;
	idCard :string ;
	birthDate :Date ;
	gender :string ;
	address: string;
	province:string;
	amphure:string;
	district:string;
	zipcode:string;
}
