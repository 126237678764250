
import { Component, ElementRef, Input, AfterViewInit, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { TokenStorageService } from '../../auth/token-storage.service';
import { FlagNationDashboardModel } from '../../model/flagnation-dashboard.model';
import { VRTicket } from '../../model/vrTicket.model';
import { HttpClientService } from '../../service/httpclient.service';

@Component({
  selector: 'app-vrgo-ticket-info',
  templateUrl: './vrgo-ticket-info.component.html',
  styleUrls: ['./vrgo-ticket-info.component.scss']
})
export class VrgoTicketInfoComponent implements OnInit {
  @ViewChild("animatedDigitDistance", { static: false }) animatedDigitDistance: ElementRef;
  @ViewChild("animatedDigitDonate", { static: false }) animatedDigitDonate: ElementRef;
  @ViewChild("animatedDigitTotalTeam", { static: false }) animatedDigitTotalTeam: ElementRef;
  @ViewChild("animatedDigitRunner", { static: false }) animatedDigitRunner: ElementRef;
  

  userProfile: any;
  flagNation: any;
  eventName: any;
  summaryCurrencyOverAll: any;
  summaryRunnerOverAll: any;
  totalTeam: any;
  summaryAllDistance: any;
  summaryAllDonate: any;

  duration: number;
  steps: number;

  constructor(
    private httClient: HttpClientService,
    private tokenStored: TokenStorageService,
  ) {
    if(this.tokenStored.getUserProfile()){
      this.userProfile = this.tokenStored.getUserProfile();
    }
    
  }

  ngOnInit() {
    this.getFalgOfNationDashboard();
  }

  getFalgOfNationDashboard() {
    let flagNation = new FlagNationDashboardModel;
    if(this.userProfile = this.tokenStored.getUserProfile()){
      flagNation.userId = this.userProfile.userId ;
    }else{
      flagNation.userId = 0 ;
    }
    
    this.httClient.getFalgOfNationDashboard(flagNation).subscribe(response => {
      this.eventName = response.eventModel.eventName;
      this.flagNation = response;
      response.summaryAllDistance = response.summaryAllDistance + 36780;
      this.summaryAllDistance = response.summaryAllDistance;
      this.summaryAllDonate = response.summaryAllDistance;
      this.summaryCurrencyOverAll = response.summaryCurrencyOverAll;
      this.summaryRunnerOverAll = response.summaryRunnerOverAll + 1;
      this.totalTeam = response.totalTeam + 1;
      // console.log("totalTeam >> ",this.totalTeam);

      if (this.summaryAllDistance) {
        this.animateCountDistance();
      }
      if (this.summaryAllDonate) {
        this.animateCountDonate();
      }
      if(this.totalTeam){
        this.animateCountTeam();
      }
      if(this.summaryRunnerOverAll){
        this.animateCountRunner();
      }
     
    });
  }

  animateCountDistance() {
    if (!this.duration) {
      this.duration = 4500;
    }
    this.counterFuncDistance(this.summaryAllDistance, this.duration, this.animatedDigitDistance);

  }

  counterFuncDistance(endValue, durationMs, element) {

    if (!this.steps) {
      this.steps = 20;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }



  animateCountDonate() {
    if (!this.duration) {
      this.duration = 4500;
    }
    this.counterFuncDonate(this.summaryAllDonate, this.duration, this.animatedDigitDonate);

  }

  counterFuncDonate(endValue, durationMs, element) {
    if (!this.steps) {
      this.steps = 20;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }



  animateCountTeam() {
    if (!this.duration) {
      this.duration = 4500;
    }
    this.counterFuncTeam(this.totalTeam, this.duration, this.animatedDigitTotalTeam);

  }

  counterFuncTeam(endValue, durationMs, element) {
    if (!this.steps) {
      this.steps = 20;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  animateCountRunner() {
    if (!this.duration) {
      this.duration = 4500;
    }
    this.counterFuncTeam(this.summaryRunnerOverAll, this.duration, this.animatedDigitRunner);

  }

  counterFuncRunner(endValue, durationMs, element) {
    if (!this.steps) {
      this.steps = 20;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

}
