import { Component, OnInit, ViewChild } from '@angular/core';
import { VRGoModalLoginComponent } from '../vrgo-modal-login/vrgo-modal-login.component';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { TokenStorageService } from '../../auth/token-storage.service';

@Component({
  selector: 'vrgo-footer',
  templateUrl: './vrgo-footer.component.html',
  styleUrls: ['./vrgo-footer.component.scss']
})
export class VRGoFooterComponent implements OnInit {
  @ViewChild(VRGoModalLoginComponent, { static: false }) vrGoModal: VRGoModalLoginComponent;
  route;
  statusHome: boolean = true;
  statusEvent: boolean = false;
  statusPayment: boolean = false;
  statusDashboard: boolean = false;
  statusEmedal: boolean = false;
  statusUpload: boolean = false;
  constructor(
    private rounter: Router,
    public location: Location,
    public tokenStored: TokenStorageService,
  ) {
    rounter.events.subscribe(val => {
      this.route = location.path();
      if (this.route == '') {
        this.home();
      } else if (this.route == "/events") {
        this.event();
      } else if (this.route == "/userOrderTransaction") {
        this.payment();
      } else if (this.route == "/my-dashboard") {
        this.dashboard();
      } else if (this.route == "/e-badge") {
        this.eMedal();
      } else if (this.route == "/upload-vr") {
        this.upload();
      } else {
        this.statusEvent = false;
        this.statusPayment = false;
        this.statusDashboard = false;
        this.statusHome = false;
        this.statusUpload = false;
      }
    });

  }

  ngOnInit() {
  }

  public openLogin(event) {
    if(event){
      // this.tokenStored.saveURLPath(event);
      this.vrGoModal.popupConfirm(event);
    }else{
      this.vrGoModal.popupConfirm(this.rounter.url);
    }
   
  }

  home() {
    this.statusHome = true;
    if (this.statusHome == true) {
      this.statusEvent = false;
      this.statusPayment = false;
      this.statusDashboard = false;
      this.statusEmedal = false;
      this.statusUpload = false;
    }
  }
  event() {
    this.statusEvent = true;
    if (this.statusEvent == true) {
      this.statusHome = false;
      this.statusPayment = false;
      this.statusDashboard = false;
      this.statusEmedal = false;
      this.statusUpload = false;
    }
  }
  payment() {
    this.statusPayment = true;
    if (this.statusPayment == true) {
      this.statusHome = false;
      this.statusEvent = false;
      this.statusDashboard = false;
      this.statusEmedal = false;
      this.statusUpload = false;
    }
  }
  dashboard() {
    this.statusDashboard = true;
    if (this.statusDashboard == true) {
      this.statusHome = false;
      this.statusEvent = false;
      this.statusPayment = false;
      this.statusEmedal = false;
      this.statusUpload = false;
    }
  }
  eMedal() {
    this.statusEmedal = true;
    if (this.statusEmedal == true) {
      this.statusHome = false;
      this.statusEvent = false;
      this.statusPayment = false;
      this.statusDashboard = false;
      this.statusUpload = false;
    }
  }
  upload(){
    this.statusUpload = true;
    
    if (this.statusUpload == true) {
      this.statusHome = false;
      this.statusEvent = false;
      this.statusPayment = false;
      this.statusDashboard = false;
      this.statusEmedal = false;
    }
  }

  receiveModalPopupError($event) {

  }
}
