import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vrgo-faq',
  templateUrl: './vrgo-faq.component.html',
  styleUrls: ['./vrgo-faq.component.scss']
})
export class VrgoFaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  list: any[] = [
    {
      id: 'static-1',
      question: 'วิธีการสมัคร',
      answer: '',
      image:'../../../../assets/images/event-detail/register.jpg'
    },
    {
      id: 'static-2',
      question: 'วิธีการแจ้งชำระเงิน',
      answer: '',
      image:'../../../../assets/images/event-detail/payment.jpg'
    },
    {
      id: 'static-3',
      question: 'วิธีการดาวน์โหลด E-BIB / E-CERTIFICATE',
      answer: '',
      image:'../../../../assets/images/event-detail/load bib.jpg'
    },
    {
      id: 'static-4',
      question: 'วิธีการส่งผลวิ่ง และตรวจสอบผลวิ่ง',
      answer: '',
      image:'../../../../assets/images/event-detail/send result.jpg'
    },
    {
      id: 'static-5',
      question: 'วิธีการใช้คูปอง',
      answer: '',
      image:'../../../../assets/images/event-detail/code coupon.jpg'
    },
    {
      id: 'static-6',
      question: 'วิธีการตรวจสอบหมายเลขพัสดุ',
      answer: '',
      image:'../../../../assets/images/event-detail/tracking.jpg'
    },
    {
      id: 'static-7',
      question: 'ติดต่อสอบถาม',
      answer: 'Line id: @checkrace<br>FB: @runtourthai<br>โทร. 060 010-4669 (จ.-ศ. 08.30 – 17.00 น.)',
      image:'../../../../assets/images/event-detail/Checkrace-07.jpg'
    }

  ];
}
