import { Injectable, ViewChild, Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EventModel } from '../share-class/EventModel';
import { TicketDetail } from '../model/ticketDetail.model';
import { Province } from '../model/province.model';
import { Amphur } from '../model/Amphur.model';
import { District } from '../model/District.model';
import { ZipCode } from '../model/ZipCode.model';
import { Order } from '../model/order.model';
import { VRTicket } from '../model/vrTicket.model';
import { UsersModel } from '../share-class/UsersModel';
import { JwtResponse } from '../auth/jwt-response';
import { OrderDetail } from '../model/orderDetail.model';
import { DashBoardModel } from '../model/dashboard.model';
import { CouponCodeModel } from '../model/CouponCode.model';
import { CouponModel } from '../model/coupon.model';
import { environment } from 'src/environments/environment';

const httpOptions = {
};

@Injectable({
  providedIn: 'root'
})
export class HttpClientService implements OnInit {
  constructor(
    private httpClient: HttpClient
  ) { }

  handleError(error: HttpErrorResponse) {

    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // console.log(error.status);
      if (error.status == 401) {
      }
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  ngOnInit() {
  }

  getListRankDistance(param): Observable<DashBoardModel> {
    return this.httpClient.post<DashBoardModel>(environment.serviceUrl + 'DashBoardController/getListRankDistance', param, httpOptions);
  }

  getRanking(param): Observable<DashBoardModel> {
    return this.httpClient.post<DashBoardModel>(environment.serviceUrl + 'DashBoardController/getRanking', param, httpOptions);
  }

  getAllCountry(): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'CommonController/getAllCountry', httpOptions);
  }

  validateThaiCharecter(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'CommonController/validateThaiCharecter', param, httpOptions);
  }

  getviewVrHistroyLog(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'VRController/viewVrHistroyLog', param, httpOptions);
  }

  UpdateUserAddress(param): Observable<UsersModel> {
    return this.httpClient.post<UsersModel>(environment.serviceUrl + 'UserProfileController/UpdateUserAddress', param, httpOptions).pipe(catchError(this.handleError));
  }

  UpdateUserProfile(param): Observable<UsersModel> {
    return this.httpClient.post<UsersModel>(environment.serviceUrl + 'UserProfileController/UpdateUserProfile', param, httpOptions).pipe(catchError(this.handleError));
  }

  validateUploadLog(vrId, userId): Observable<VRTicket> {
    return this.httpClient.post<VRTicket>(environment.serviceUrl + 'VRController/validateUploadLog?VRId=' + vrId + '&UserId=' + userId, httpOptions).pipe(catchError(this.handleError)).pipe(catchError(this.handleError));
  }

  validateRegisteredTicket(param): Observable<EventModel> {
    return this.httpClient.post<EventModel>(environment.serviceUrl + 'EventController/validateRegisteredTicket', param, httpOptions);
  }

  getTicketDetail(param): Observable<TicketDetail> {
    return this.httpClient.post<TicketDetail>(environment.serviceUrl + 'TicketController/getTicketDetail', param, httpOptions);
  }

  getHomeBannerEvent(): Observable<EventModel> {
    return this.httpClient.post<EventModel>(environment.serviceUrl + 'EventController/getHomeBannerEvent', httpOptions);
  }

  getHomeEvent(): Observable<EventModel> {
    return this.httpClient.post<EventModel>(environment.serviceUrl + 'EventController/getHomeEvent', httpOptions);
  }

  getEventPreview(param): Observable<EventModel> {
    return this.httpClient.post<any>(environment.serviceUrl + 'EventController/getEventPreview', param, httpOptions);
  }

  getEventDetail(param): Observable<EventModel> {
    return this.httpClient.post<any>(environment.serviceUrl + 'EventController/getEventDetail', param, httpOptions);
  }

  getEventById(param): Observable<EventModel> {
    return this.httpClient.post<EventModel>(environment.serviceUrl + 'EventController/getAllEvent', param, httpOptions);
  }

  getEventAll(): Observable<EventModel> {
    return this.httpClient.post<EventModel>(environment.serviceUrl + 'EventController/getAllEvent', httpOptions);
  }

  register(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'UserProfileController/createProfile', param, httpOptions).pipe(catchError(this.handleError)).pipe(catchError(this.handleError));
  }

  registerGoogle(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'UserProfileController/createProfileGoogle', param, httpOptions).pipe(catchError(this.handleError)).pipe(catchError(this.handleError));
  }

  resetPassword(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'UserProfileController/resetPassword?email=' + param, httpOptions).pipe(catchError(this.handleError));
  }

  updatePassword(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'UserProfileController/updatePassword', param, httpOptions).pipe(catchError(this.handleError));
  }

  getUserProfileByEmail(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'UserProfileController/getUserProfileByEmail?email=' + param, httpOptions).pipe(catchError(this.handleError));
  }

  checkUserAndEmail(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'UserProfileController/checkUserAndEmail', param, httpOptions).pipe(catchError(this.handleError));
  }

  getAllProvince(): Observable<Province> {
    return this.httpClient.post<Province>(environment.serviceUrl + 'CommonController/getAllProvince', httpOptions).pipe(catchError(this.handleError));
  }

  getAmphureByProvince(param): Observable<Amphur> {
    return this.httpClient.post<Amphur>(environment.serviceUrl + 'CommonController/getAmphureByProvince?provinceId=' + param, httpOptions).pipe(catchError(this.handleError));
  }

  getDistrictByAmphure(param): Observable<District> {
    return this.httpClient.post<District>(environment.serviceUrl + 'CommonController/getDistrictByAmphure?amphureId=' + param, httpOptions).pipe(catchError(this.handleError));
  }

  getZipcodeByDistrict(districtId, amphureCode): Observable<ZipCode> {
    return this.httpClient.post<ZipCode>(environment.serviceUrl + 'CommonController/getZipcodeByDistrict?districtId=' + districtId + '&amphureCode=' + amphureCode, httpOptions).pipe(catchError(this.handleError));
  }

  saveOrder(param): Observable<Order> {
    return this.httpClient.post<Order>(environment.serviceUrl + 'OrderController/saveOrder', param, httpOptions).pipe(catchError(this.handleError));
  }

  saveFreeOrder(param): Observable<Order> {
    return this.httpClient.post<Order>(environment.serviceUrl + 'OrderController/saveFreeOrder', param, httpOptions).pipe(catchError(this.handleError));
  }

  listTicketTransaction(param): Observable<Order> {
    return this.httpClient.post<Order>(environment.serviceUrl + 'TicketController/listTicketTransaction', param, httpOptions).pipe(catchError(this.handleError));
  }

  calculateHMACSha256(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'CommonController/calculateHMACSha256', param, httpOptions).pipe(catchError(this.handleError));
  }

  uploadSlip(fileToUpload: File, orderNumber: string, orderId: number, userId: number, ticketId: number): Observable<boolean> {
    const endpoint = environment.serviceUrl + 'PaymentController/uploadSlip';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('orderNumber', orderNumber);
    formData.append('orderId', String(orderId));
    formData.append('userId', String(userId));
    formData.append('ticketId', String(ticketId));
    return this.httpClient.post<any>(endpoint, formData, httpOptions)
  }

  getUserOrderTransaction(param): Observable<Order> {
    return this.httpClient.post<Order>(environment.serviceUrl + 'OrderController/getUserOrderTransaction', param, httpOptions).pipe(catchError(this.handleError));
  }

  listUserVRTicket(param): Observable<VRTicket> {
    return this.httpClient.post<VRTicket>(environment.serviceUrl + 'VRController/listVRTicket', param, httpOptions).pipe(catchError(this.handleError));
  }

  uploadVRLog(fileToUpload: File, vrId: number, userId: number, ticketId: number, distance: string, location: string, hour: string, minute: string, second: string): Observable<boolean> {
    // uploadVRLog(fileToUpload: File, vrId: number, userId: number, ticketId: number, distance: string, location: string ,period:string): Observable<boolean> {
    const endpoint = environment.serviceUrl + 'VRController/uploadVRLog';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('VRId', String(vrId));
    formData.append('userId', String(userId));
    formData.append('ticketId', String(ticketId));
    formData.append('distance', String(distance));
    formData.append('location', String(location));
    // formData.append('period', String(period));
    formData.append('hour', String(hour));
    formData.append('minute', String(minute));
    formData.append('second', String(second));
    return this.httpClient.post<any>(endpoint, formData, httpOptions)
  }

  generateEBIB(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'VRController/generateEBIB', param, httpOptions).pipe(catchError(this.handleError));
  }

  generateEcert(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'VRController/generateEcert', param, httpOptions).pipe(catchError(this.handleError));
  }
  
  LineLogin() {
    window.location.href = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=' + environment.LnClientId + '&redirect_uri=https://checkrace.com/&state=LN-RU&scope=profile%20openid%20email';
  }

  getAccesToken(param) {
    const body = new HttpParams()
      .set(`grant_type`, 'authorization_code')
      .set(`code`, param)
      .set(`redirect_uri`, 'https://checkrace.com/')
      .set(`client_id`, environment.LnClientId)
      .set(`client_secret`, environment.LnClientSecret);
    return this.httpClient.post<any>('https://api.line.me/oauth2/v2.1/token', body.toString(), { headers: { "Content-Type": "application/x-www-form-urlencoded" } }).pipe(catchError(this.handleError));
  }

  getEBadgeByUser(param): Observable<EventModel> {
    return this.httpClient.post<EventModel>(environment.serviceUrl + 'EbadgeController/getEBadgeByUser', param, httpOptions);
  }

  // FacebookLogin() {
  //   window.location.href = 'https://www.facebook.com/v9.0/dialog/oauth?state=FB-RU&client_id=2625583094248090&redirect_uri=https://checkrace.com/&scope=email%20public_profile&response_type=code';
  // }

  // getAccesTokenFB(param) {
  //   return this.httpClient.get<any>('https://graph.facebook.com/v9.0/oauth/access_token?client_id=2625583094248090&redirect_uri=https://checkrace.com/&client_secret=854a8542a98188bf82b82bb39dcef36c&code=' + param, httpOptions);
  // }

  // getUserId(param) {
  //   return this.httpClient.get<any>('https://graph.facebook.com/v9.0/debug_token?input_token=' + param + '&access_token=2625583094248090|854a8542a98188bf82b82bb39dcef36c', httpOptions).pipe(catchError(this.handleError));
  // }

  // getUserProfile(param, param2) {
  //   return this.httpClient.get<any>('https://graph.facebook.com/v9.0/' + param + '/?fields=email,name,first_name,last_name,picture&access_token=' + param2, httpOptions);
  // }

  getRunningEvent(): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'CommonController/getRunningEvent', httpOptions).pipe(catchError(this.handleError));
  }

  getUserConfirm(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'OrderController/getUserConfirm', param, httpOptions).pipe(catchError(this.handleError));
  }

  editImageProfile(fileToUpload: File, userId): Observable<boolean> {
    const endpoint = environment.serviceUrl + 'UserProfileController/editImageProfile';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('userId', userId);
    return this.httpClient.post<any>(endpoint, formData, httpOptions)
  }

  getListBox(listBoxGroup): Observable<ZipCode> {
    return this.httpClient.post<ZipCode>(environment.serviceUrl + 'CommonController/getListBox?listBoxGroup=' + listBoxGroup, httpOptions).pipe(catchError(this.handleError));
  }

  getEventByTag(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'EventController/getEventByTag?tag=' + param, httpOptions).pipe(catchError(this.handleError));
  }

  listEventHome(): Observable<EventModel> {
    return this.httpClient.post<EventModel>(environment.serviceUrl + 'EventController/listLimitEvent', httpOptions);
  }

  sendMailOrder(param): Observable<Order> {
    return this.httpClient.post<Order>(environment.serviceUrl + 'OrderController/sendMailOrder', param, httpOptions).pipe(catchError(this.handleError));
  }

  sendRegisterMail(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'UserProfileController/sendGreetingMail', param, httpOptions).pipe(catchError(this.handleError)).pipe(catchError(this.handleError));
  }

  sendResetPasswordMail(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'UserProfileController/sendResetPasswordMail', param, httpOptions).pipe(catchError(this.handleError)).pipe(catchError(this.handleError));
  }

  checkCouponCode(param): Observable<CouponModel> {
    return this.httpClient.post<CouponModel>(environment.serviceUrl + 'OrderController/checkCouponCode', param, httpOptions);
  }

  redeemCouponCode(param): Observable<CouponModel> {
    return this.httpClient.post<CouponModel>(environment.serviceUrl + 'OrderController/redeemCouponCode', param, httpOptions);
  }

  listUserEvent(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'EventController/listUserEvent?userId=' + param, httpOptions);
  }

  getFalgOfNationDashboard(param): Observable<any> {
    return this.httpClient.post<any>(environment.serviceUrl + 'DashBoardController/getFalgOfNationDashboard', param, httpOptions);
  }


}
