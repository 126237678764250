import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vrgo-ticket-only',
  templateUrl: './vrgo-ticket-only.component.html',
  styleUrls: ['./vrgo-ticket-only.component.scss']
})
export class VrgoTicketOnlyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
