import { Component, OnInit ,HostListener} from '@angular/core';
import { EventModel } from '../../share-class/EventModel';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
import { HttpClientService } from '../../service/httpclient.service';
import { Products, ColorFilter, TagFilter } from '../../model/product.model';
import { ProductsService } from '../../service/products.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-vrgo-event-all',
  templateUrl: './vrgo-event-all.component.html',
  styleUrls: ['./vrgo-event-all.component.scss']
})
export class VrgoEventAllComponent implements OnInit {

  public products: Products[] = [];
  public activeCategory: string = 'all';
  windowsScreen:number;
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // //console.log('[scroll]', scrollPosition);
  }
  constructor(
    private httpClienService: HttpClientService,
    private productsService: ProductsService,
    private modalService: NgbModal,
    private param: ParameterUrl,
    private rounter: Router,
    private tokenstorage: TokenStorageService,
    public translate: TranslateService,
  ) {
    this.windowsScreen = window.screen.width;
    this.checkScroll();
    //console.log('windowsScreen >>',this.windowsScreen);
    translate.addLangs(['th', 'en']);
    translate.reloadLang((this.tokenstorage.getLang() || 'th')).toPromise().then(result => {
      this.language = result;
    });
  }

  listEvent: EventModel[] = [];
  listEventFocus: EventModel[] = [];
  language: any = {};
  listeventTag: any = {};
  requestParam: any = {};
  eventDetail: any = {};
  userId: any;
  eventId = '';
  eventName: string = '';
  titleEvent: string = '';
  listImgEvent = [];
  listEventComming = [];
  listEventFree = [];
  listEventPopular = [];
  listEventCombo = [];
  listEventPostEvent = [];
  listEventNew = [];
  allEventCategoryStatus = 'YES';
  allEventStatus = '';
  isLoder : boolean = false ;

  isAllEvent  : boolean = false ;

  ngOnInit() {
    //console.log('lang',this.tokenstorage.getLang())
    this.modalService.dismissAll();
    this.tokenstorage.removeOrderKey();
    this.isAllEvent = true;
    this.getEventAll();
    this.getImg();
    this.getEventComming();
    this.getEventFree();
    this.getEventPopular();
    this.getEventCombo();
    this.getEventNew();
    this.getEventPostEvent();
  }


  openEventId(eventId :any) {
    console.log(eventId)
    let eventDetail = this.listEvent.find((x : any) => x.eventId == eventId);
    console.log("listEvent > ",this.listEvent);
    console.log("eventDetail ",eventDetail)
    if (eventDetail) {
      this.rounter.navigate(['/'+ eventDetail.urlPath]);
    } else {
      this.rounter.navigate(['/']);
    }
  }

  getEventAll() {
    this.httpClienService.getEventAll().subscribe(response => this.mapEventAll(response));
  }
  getImg() {
    this.httpClienService.getHomeBannerEvent().subscribe(response => this.mapImgEventAll(response))
  }
  mapImgEventAll(response) {
    this.listImgEvent = response;
    //console.log('', this.listImgEvent);
  }
  mapEventAll(Response) {
    console.log("Response" ,Response)
    this.listEvent = Response;
    this.isAllEvent = false;
    this.getCategoryProduct(this.activeCategory);
  }
  getCategoryProduct(category) {
    this.isLoder = true ;
    this.activeCategory = category
    if(category == 'all'){
      // this.allEventStatus = 'YES';
      // this.allEventCategoryStatus = '';
      this.titleEvent = 'All Event';
      this.listEventFocus = this.listEvent ;
      this.isLoder = false ;
      // this.gotoTop();
    }else if(category == 'category'){
      this.allEventStatus = '';
      // this.allEventCategoryStatus = 'YES';
      // this.gotoTop();
    }else if(category == 'Combo'){
      // this.allEventStatus = 'YES';
      // this.allEventCategoryStatus = '';
      this.titleEvent = 'All Combo Event';
      this.listEventFocus = this.listEventCombo ;
      this.isLoder = false ;
    }else if(category == 'FREE'){
      // this.allEventStatus = 'YES';
      // this.allEventCategoryStatus = '';
      this.titleEvent = 'All Free Event';
      this.listEventFocus = this.listEventFree ;
      this.isLoder = false ;
    }
  }
  gotoTop() {
    if(this.windowsScreen <= 320){
      window.scroll({ 
        top: 3440, 
        left: 0, 
        behavior: 'smooth' 
      });
    }else if(this.windowsScreen <= 376 && this.windowsScreen >=321){
      window.scroll({ 
        top: 3500, 
        left: 0, 
        behavior: 'smooth' 
      });
    }else if(this.windowsScreen <= 414 && this.windowsScreen >= 376){
      window.scroll({ 
        top: 3600, 
        left: 0, 
        behavior: 'smooth' 
      }); 
    }else if(this.windowsScreen == 768){
        window.scroll({ 
          top: 2157, 
          left: 0, 
          behavior: 'smooth' 
        }); 
    }else if(this.windowsScreen == 1024){
      window.scroll({ 
        top: 1826, 
        left: 0, 
        behavior: 'smooth' 
      }); 
  }else if(this.windowsScreen >= 1920){
      window.scroll({ 
        top: 2100, 
        left: 0, 
        behavior: 'smooth' 
      });
    }
    
  }
  open(content, eventId) {
    const param = new Object();
    param['eventId'] = eventId;
    this.httpClienService.getEventById(param).subscribe(Response => this.eventDetail = Response);
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' })
  }

  ecombannerOptions = {
    items: 1,
    nav: true,
    navClass: ['owl-prev style="right:250px"', 'owl-next'],
    navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
    dots: true,
    autoplay: false,
    slideSpeed: 300,
    smartSpeed: 1100,
    loop: false
  }

  blogCarouselOptions = {
    items: 3,
    // navClass:[],
    // navText:[],
    nav: false,
    // navClass: ['owl-prev', 'owl-next'],
    // navText: ['<i class="icon-angle-left" style="top:30%"></i>', '<i class="icon-angle-right"></i>'],
    margin: 10,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: false,
    dots: true,
    stagePadding: 15,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 2,
      },
      1250: {
        items: 3,
        // margin: 30
      }
    }
    
  }
  getEventComming() {
    this.httpClienService.getEventByTag('Comming Soon').subscribe(response => this.mapEventComming(response));
  }
  mapEventComming(response) {
    //console.log('mapEventComming >>', response);
    this.listEventComming = response;
  }
  getEventFree() {
    this.httpClienService.getEventByTag('Free').subscribe(response => this.mapEventFree(response));
  }
  mapEventFree(response) {
    //console.log('mapEventFree >>', response);
    this.listEventFree = response;
  }
  getEventPopular() {
    this.httpClienService.getEventByTag('Popular').subscribe(response => this.mapEventPopular(response));
  }
  mapEventPopular(response) {
    //console.log('mapEventPopular >>', response);
    this.listEventPopular = response;
  }
  getEventCombo() {
    this.httpClienService.getEventByTag('Combo').subscribe(response => this.mapEventCombo(response));
  }
  getEventNew() {
    this.httpClienService.getEventByTag('New').subscribe(response => this.mapEventNew(response));
  }
  mapEventNew(response){
    this.listEventNew = response;
    // console.log('Combo >>', response);
  }
  mapEventCombo(response) {
    // console.log('Combo >>', response);
    this.listEventCombo = response;
  }
  getEventPostEvent() {
    this.httpClienService.getEventByTag('Post Event').subscribe(response => this.mapEventPostEvent(response));
  }
  mapEventPostEvent(response) {
    //console.log('PostEvent >>', response);
    this.listEventPostEvent = response;
  }
}
