import { TicketModel } from './TicketModel';

export class EventModel {
    eventId: string ; 
    orgId: string ; 
    eventName: string ; 
    eventDetail: string; 
    eventDate: string; 
    eventRegisStartDate: string; 
    eventRegisEndDate: string; 
    eventLocation: string; 
    province: string; 
    status: string; 
    shippingStatus: string; 
    shippingFee: string; 
    additionalShippingFee: string; 
    createDate: string; 
    createBy: string; 
    updateDate: string; 
    updateBy: string;
    price : string;
    priceType : string;
    userId : string ;
    registerStatus : string ;
    registerStatusDesc : string ;
    listTicket : TicketModel[];
    listEventTag : any[];
    listEventImage : any [] = [];
    listEventSection : any[];
    urlPath : string;
    languageStatus:string;
    eventCategory:string;
    ticketId : number;
}