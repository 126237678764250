import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { Order } from '../../model/order.model';
import { TokenStorageService } from '../../auth/token-storage.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FunctionUtils } from '../../utils/functionUtils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
import { VRTicket } from '../../model/vrTicket.model';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { SocialUser } from 'angular4-social-login';
import { AuthenticationService } from '../../service/authentication.service';

declare var liff: any;

@Component({
  selector: 'app-vrgo-my-dashboard',
  templateUrl: './vrgo-my-dashboard.component.html',
  styleUrls: ['./vrgo-my-dashboard.component.scss']
})
export class VrgoMyDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('vrValid', { static: false }) vrValid;
  @ViewChild('vrComplete', { static: false }) vrComplete;
  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('emailNull', { static: false }) emailNull;

  myControl = new FormControl();
  noneSelectOptions: string[] = []
  noneSelectfilteredOptions: Observable<string[]>;
  searchControl: FormControl = new FormControl();
  returnedArray: VRTicket[] = [];
  orderArray: any[];
  searchMoviesCtrl = new FormControl();
  filteredMovies: any;
  data: any = [];
  data1: any = [];
  errorMsg: string;
  isLoadingResult: boolean;
  statusOrder: boolean = false;
  statusReturned: boolean = false;
  order: Order[];
  userId: number;
  vrTicket: VRTicket[];
  vrTicketComplete: VRTicket;
  test: any = [];
  btnVrTicket: any = 'ส่งผลการวิ่ง';
  language: any;
  listVRLogModel: any;
  eventName: any[] = [];
  userRegis: any[] = [];
  userDetail: any;
  imageProfile: string;
  sumDistance: number = 0;
  sumPeriod: number = 0;
  sumEvent: number = 0;
  agePace: number = 0;
  userProfile: any;
  idToken: any;
  email: any;
  invalidLogin: boolean;

  constructor(
    private httClient: HttpClientService,
    private tokenStored: TokenStorageService,
    private fn: FunctionUtils,
    private param: ParameterUrl,
    private rounter: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private http: HttpClient,
    private loginService: AuthenticationService,
  ) {
    this.activeRount();
  }

  ngAfterViewInit(): void {
    this.imageProfile = this.tokenStored.getImgProfile();
  }

  ngOnInit() {

  }

  listUserVRTicket(userObject) {
    // console.log("userObject", userObject);
    this.httClient.listUserVRTicket(userObject).subscribe(data => {
      this.mapVRTicket(data);
    });
  }

  mapVRTicket(data: any) {
    // console.log(data);
    this.vrTicket = data;
    this.getEventName();
    this.sumEvent = this.vrTicket.length;
    const param = new Object();
    var returnedArray = this.fn.slice(this.vrTicket, 0, 50);
    for (let i = 0; i < returnedArray.length; i++) {
      if (returnedArray[i].paymentStatus != 'CANCEL') {
        if (returnedArray[i].uploadVRStatus === 'UNAVAILABLE') {
          returnedArray[i].btnVrTicket = this.language.USER_VR_TICKET_PAGE.userVrTicket_Page_table_waiting;
        } else {
          returnedArray[i].btnVrTicket = this.language.USER_VR_TICKET_PAGE.userVrTicket_Page_table_col1;
        }
        if (returnedArray[i].paymentStatus == 'PENDING_PAYMENT') {
          returnedArray[i].btnPayment = returnedArray[i].orderStatusDesc;
        }
        this.returnedArray.push(returnedArray[i]);
      }
    }
    // console.log('Vr >> ', this.returnedArray);
  }

  getEventName() {
    this.sumDistance = 0;
    if (this.vrTicket != undefined && this.vrTicket != null) {
      for (let i = 0; i < this.vrTicket.length; i++) {
        this.sumPeriod = this.sumPeriod + Number(this.vrTicket[i].sumPeriod);
        this.sumDistance = this.sumDistance + Number(this.vrTicket[i].sumDistance);
      }
      this.agePace = this.sumDistance / this.sumPeriod;
      if (this.agePace == Infinity) {
        this.agePace = 0;
      }
    }
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.fn.slice(this.vrTicket, startItem, endItem);
  }

  viewDashboard(vrTicket: VRTicket) {
    let sessionVrLog = JSON.stringify(vrTicket);
    this.tokenStored.saveSessionVrLog(sessionVrLog);
    this.rounter.navigate(['./my-sub-dashboard/' + vrTicket.orderId + '/' + vrTicket.userId]);
  }
  vrUpload(vrTicket: VRTicket) {
    this.vrTicketComplete = vrTicket
    if (vrTicket.completeStatus === 'COMPLETE') {
      this.modalService.open(this.vrComplete, { backdrop: 'static', windowClass: 'myCustomModalClass' });
      // this.btnVrTicket = 'ยังไม่ถึงกำหนดส่งผล';
    } else if (vrTicket.uploadVRStatus == 'UNAVAILABLE') {
      this.modalService.open(this.vrValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    } else {
      this.param.data = {};
      this.param.data.vrTicket = vrTicket;
      this.rounter.navigate(['./logVrupLoad']);
    }
  }
  gotoVrCompelete() {
    this.modalService.dismissAll();
    this.param.data = {};
    this.param.data.vrTicket = this.vrTicketComplete;
    this.rounter.navigate(['./logVrupLoad']);
  }
  closeModel() {
    this.modalService.dismissAll();
  }
  vrValids() {
    for (let i = 0; i < this.returnedArray.length; i++) {
      if (this.returnedArray[i].uploadVRStatus === 'UNAVAILABLE') {
        this.btnVrTicket = 'ยังไม่ถึงกำหนดส่งผล';
      }
    }
  }
  getviewVrHistroyLog(param) {
    this.httClient.getviewVrHistroyLog(param).subscribe(Response => { this.mapdata(Response) });
  }

  mapdata(data) {
    this.listVRLogModel = data.listVRLogModel
  }

  validImageUrl(path) {
    if (path != null && (path.search("facebook") > 0 || path.search("google") > 0)) {
      return true;
    } else {
      var imgae = new XMLHttpRequest();
      imgae.open('HEAD', path, false);
      try {
        imgae.send();
      } catch (error) {
        return false;
      }
      return imgae.status != 404;
    }
  }

  getServerResponse(value: string) {
    let userObject = new Object();
    userObject['userId'] = this.userId;
    this.httClient.listUserVRTicket(userObject).subscribe(data => {
      let event: any = [];
      let eventName: any = [];
      event = data
      if (value != '') {
        this.isLoadingResult = true;
        for (let i = 0; i < event.length; i++) {
          if (event[i].eventName != undefined) {
            eventName.push(event[i].eventName);
          }
        }
        const filterValue = value.toLowerCase();
        if (eventName.filter(data => data.toLowerCase().includes(filterValue)).length == 0) {
          if (this.tokenStored.getLang() == 'th') {
            this.data = ["ไม่พบข้อมูล"];
            this.isLoadingResult = false;
          } else {
            this.data = ["Data Not Found"];
            this.isLoadingResult = false;
          }
        } else {
          this.data = eventName.filter(data => data.toLowerCase().includes(filterValue));
          this.isLoadingResult = false;
        }
      } else {
        this.httClient.listUserVRTicket(userObject).subscribe(data => {
          this.mapVRTicket(data);
        });
      }
    });
  }

  searchCleared() {
    let userObject = new Object();
    userObject['userId'] = this.userId;
    this.data = [];
    this.httClient.listUserVRTicket(userObject).subscribe(data => {
      this.mapVRTicket(data);
    });
  }

  selectEvent(item) {
    let userObject = new Object();
    userObject['userId'] = this.userId;
    const filterValue = item.toLowerCase();
    // console.log('returnedArray',this.returnedArray.filter(data => data.eventName.toLowerCase().includes(filterValue)));
    this.returnedArray = this.returnedArray.filter(data => data.eventName.toLowerCase().includes(filterValue))
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  viewTicketDetail(order) {
    this.param.data = {};
    this.param.data.order = order;
    this.rounter.navigate(['./orderDetail']);
  }

  activeRount() {
    this.vrTicket = [];
    this.tokenStored.removeSessionVrLog();
    const userDetail = this.tokenStored.getUserProfile();
    this.userDetail = userDetail;
    if (userDetail != null && userDetail['userId'] != undefined) {
      this.userId = Number(userDetail['userId']);
      if (this.userId != null) {
        this.translate.addLangs(['th', 'en']);
        this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
          this.language = result;
          let userObject = new Object();
          userObject['userId'] = this.userId;
          userObject['languageStatus'] = this.tokenStored.getLang();
          this.listUserVRTicket(userObject);
        });
      }
    } else {
      this.rounter.navigate(['./']);
    }
  }

}

