import { Component } from '@angular/core';

@Component({
  selector: 'app-vrgo-main',
  templateUrl: './vrgo-main.component.html',
  styleUrls: ['./vrgo-main.component.scss']
})

export class VRGoMainComponent {

  constructor() { }

  ngOnInit() {
  }
}
