export class OrderObject {
    orderObjectId: number;
	orderDetailId: number;
	ticketId: number;
	ticketFieldId: number;
	objectValue: string;
	status: string;
	fieldLabel: string;
  fieldName: any;
  objectDesc: string;
}