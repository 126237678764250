import { Component, OnInit, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClientService } from '../../service/httpclient.service';
import * as _ from 'lodash';
import { EventModel } from '../../share-class/EventModel';
import { TokenStorageService } from '../../auth/token-storage.service';
import { AuthenticationService } from '../../service/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { VRGoModalLoginComponent } from '../vrgo-modal-login/vrgo-modal-login.component';
import { DatePipe } from '@angular/common';
import { SocialUser } from 'angular4-social-login';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FacebookPixelService } from '../../service/facebook-pixel.service';
import { UsersModel } from '../../share-class/UsersModel';
import { FacebookApiService } from '../../service/facebook-api.service';
import { Location } from '@angular/common';

declare var liff: any;
@Component({
  selector: 'app-vrgo-event-detail',
  templateUrl: './vrgo-event-detail.component.html',
  styleUrls: ['./vrgo-event-detail.component.scss']
})
export class VRGoEventDetailComponent implements OnInit {

  isShow: boolean;
  topPosToStartShowing = 1200;
  scrall: boolean = true;
  windowsScreen: number;
  code: any;
  // 2768

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // console.log(scrollPosition)
    if (scrollPosition <= 0) {
      this.scrall = true
    } else {
      this.scrall = false
    }
    // console.log('scroll >> ', this.scrall);

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  @ViewChild('ticketRegistered', { static: true }) ticketRegistered;
  @ViewChild(VRGoModalLoginComponent, { static: false }) vrGoModal: VRGoModalLoginComponent;
  @ViewChild('emailNull', { static: false }) emailNull;
  @Output() messageEvent = new EventEmitter<any>();



  btnClass: string = 'btn btn-register mt-8';
  btnClassEn: string = 'btn btn-registerEN mt-8'
  text: any =
    {
      "Days": "Day",
      "Hours": "Hour",
      "Minutes": "Min",
      "Seconds": "Sec",
    };

  endTime: any;

  language: any;

  requestParam: any = {};
  eventDetail: EventModel;
  eventDetailById: any = {};



  ticketDetail: any;

  userProfile: any;
  eventId: string = '';
  userId: EventModel;
  lisTicket = [];
  listEventImages = [];

  ticketId: any;
  // userId: any;
  validateRegistered: EventModel;
  validateRegistereds: any;

  dateToDay = new Date().toISOString();
  name: string = '';
  listTicketStatus: boolean;

  idToken: any;
  email: string;
  imageProfile: string;



  invalidLogin: boolean = (this.tokenStored.getUsername() == null ? false : true);

  constructor(
    private param: ParameterUrl,
    private rounter: Router,
    private modalService: NgbModal,
    private httpClienService: HttpClientService,
    private loginService: AuthenticationService,
    private tokenstorage: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private tokenStored: TokenStorageService,
    private translate: TranslateService,
    private facePixelService: FacebookPixelService,
    private facebookApi: FacebookApiService,
    public rountLocation: Location,) {

    this.windowsScreen = window.screen.width;
    this.eventDetail = new EventModel();
    this.getAllProvince();

    // if (liff.isInClient()) {
    //   this.initLineLiff();
    // } else {
    //   // this.loginFacebook();
    this.activeRount();
    // }

  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(param => {
      // console.log(param['fbclid']);
      if (param['fbclid']) {
        this.rountLocation.replaceState(this.tokenStored.getURLEvent());
      }
    });

    if (this.tokenstorage.getLang() == 'en') {
      this.btnClass = 'btn btn-registerEN mt-8';
    } else {
      this.btnClass = 'btn btn-register mt-8';
    }
  }

  getparam() {
    // console.log("getparam");
    const queryString = decodeURIComponent(window.location.search).replace("?liff.state=", "");
    const params = new URLSearchParams(queryString);
    const name = params.get('name');
    if (name != null && name != '') {
      this.eventDetail.urlPath = name;
      this.eventDetail.languageStatus = this.tokenstorage.getLang();
      this.tokenstorage.removeSessionOrder();
      if (this.eventDetail.urlPath != null && this.eventDetail.urlPath != undefined && this.eventDetail.urlPath != '') {
        this.tokenstorage.saveURLEvent(name);
        this.getEventDetail(this.eventDetail);
      } else {
        this.rounter.navigate(['./']);
      }
    }
  }

  activeRount() {
    // console.log("ปกติ");
    this.activatedRoute.queryParams.subscribe(params => {
      if (params["liff.state"] != undefined) {
        this.getparam();
      } else {
        this.name = params['name'];
        if (this.name != null && this.name != '' && this.name != undefined && this.name != 'null') {
          // console.log("params['name']", this.name);
          this.eventDetail.urlPath = this.name;
          this.eventDetail.languageStatus = this.tokenstorage.getLang();
          this.tokenstorage.removeSessionOrder();
          if (this.eventDetail.urlPath != null && this.eventDetail.urlPath != undefined && this.eventDetail.urlPath != '') {

            this.tokenstorage.saveURLEvent(this.name);

            this.getEventDetail(this.eventDetail);

          } else {

            this.rounter.navigate(['./']);

          }
        } else {
          this.route.params.subscribe(routeParams => {
            this.name = routeParams.name;
            if (this.name != null && this.name != '' && this.name != undefined && this.name != 'null') {
              // console.log("routeParams", routeParams);
              this.tokenstorage.saveURLEvent(this.name);

              this.eventDetail.urlPath = this.name;
              this.eventDetail.languageStatus = this.tokenstorage.getLang();
              this.tokenstorage.removeSessionOrder();
              if (this.eventDetail.urlPath != null && this.eventDetail.urlPath != undefined && this.eventDetail.urlPath != '') {
                // console.log('events',this.eventDetail);
                this.getEventDetail(this.eventDetail);
              }
            } else {
              // console.log("this.name != null", this.name);
              this.rounter.navigate(['./']);
            }

          });
        }
      }
    });
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ticketRegister(ticketId) {

    const userDetail = this.tokenstorage.getUserProfile();
    if (userDetail == null) {

      // console.log(this.rounter.url);
      this.vrGoModal.popupConfirm(this.rounter.url);
    } else {
      this.validateRegisteredTicket(ticketId);

    }

  }

  getEventDetail(eventDetail) {

    this.httpClienService.getEventDetail(eventDetail).subscribe(
      response => {
        if (response.eventId != '0') {
          this.eventDetail = response;

          if (this.eventDetail.urlPath == "olympic2020") {
            this.facePixelService.load();
          }

          this.checkListTicketStatus(this.eventDetail.listTicket);
          this.eventDetail.languageStatus = this.tokenstorage.getLang();
          this.endTime = new Date(this.eventDetail.eventDate).toISOString()
          const userDetail = this.tokenstorage.getUserProfile();
          if (userDetail != null) {
            this.eventDetail.userId = userDetail.userId
            this.httpClienService.validateRegisteredTicket(this.eventDetail).subscribe(
              response => {
                this.validateRegistered = response;
                if (this.validateRegistered.registerStatus == 'SINGLE' && this.validateRegistered.registerStatusDesc == 'REGISTERED') {
                  this.eventDetail.listTicket = this.validateRegistered.listTicket;
                }
              });
          }
        } else {
          // this.rounter.navigate(['./']);
        }

      });
  }

  checkListTicketStatus(listTicket) {
    if (listTicket.length >= 4) {
      this.listTicketStatus = true;
    } else {
      this.listTicketStatus = false;
    }
  }

  receiveModalPopupError(event) {
    // console.log(event);
    event = JSON.parse(event);
    if (event.status == 'success') {
      const userDetail = this.tokenstorage.getUserProfile();
      this.eventDetail.userId = userDetail.userId
      // console.log('eventDetail167',this.eventDetail)
      if (userDetail != null) {
        this.httpClienService.validateRegisteredTicket(this.eventDetail).subscribe(
          response => {
            this.validateRegistered = response;
            // console.log('this.validateRegistered',this.validateRegistered)
            // เช็คตอนที่เรา ล็อคอินจากหน้า eventDetail แล้วเข้าเงื่อนไขดังนี้
            if (this.validateRegistered.registerStatus == 'SINGLE' && this.validateRegistered.registerStatusDesc == 'REGISTERED') {
              this.eventDetail.listTicket = this.validateRegistered.listTicket;
              this.openModelValidate();
            }
          });
      }
    }

  }

  validateRegisteredTicket(ticketId) {
    const userDetail = this.tokenstorage.getUserProfile();
    this.eventDetail.userId = userDetail.userId
    // console.log('ticketId',this.eventDetail)
    this.httpClienService.validateRegisteredTicket(this.eventDetail).subscribe(
      response => {
        this.validateRegistered = response;
        const userDetail = this.tokenstorage.getUserProfile();
        // เช็คตอนที่เราล็อคอินมาก่อนแล้ว แล้วมากิน ticket ที่มี เงื่อนไขดังนี้
        if (userDetail != null && this.validateRegistered.registerStatus == 'SINGLE' && this.validateRegistered.registerStatusDesc == 'REGISTERED') {
          this.openModelValidate();
        } else {
          this.param.data = {};
          this.param.data.eventDetail = this.eventDetail;
          this.param.data.eventImgItem = this.eventDetail.listEventImage;
          this.param.data.ticketId = ticketId;
          this.rounter.navigate(['./registerForm']);
        }
      });
  }

  openModelValidate() {
    this.modalService.dismissAll();
    this.modalService.open(this.ticketRegistered, { windowClass: 'myCustomPopup', backdrop: 'static' })

  }
  closeModel() {
    this.modalService.dismissAll();
  }

  list: any[] = [
    {
      id: 'static-1',
      question: 'วิธีการสมัคร',
      answer: '',
      image: '../../../../assets/images/event-detail/register.jpg'
    },
    {
      id: 'static-2',
      question: 'วิธีการแจ้งชำระเงิน',
      answer: '',
      image: '../../../../assets/images/event-detail/payment.jpg'
    },
    {
      id: 'static-3',
      question: 'วิธีการดาวน์โหลด E-BIB / E-CERTIFICATE',
      answer: '',
      image: '../../../../assets/images/event-detail/load bib.jpg'
    },
    {
      id: 'static-4',
      question: 'วิธีการส่งผลวิ่ง และตรวจสอบผลวิ่ง',
      answer: '',
      image: '../../../../assets/images/event-detail/send result.jpg'
    },
    {
      id: 'static-5',
      question: 'วิธีการใช้คูปอง',
      answer: '',
      image: '../../../../assets/images/event-detail/code coupon.jpg'
    },
    {
      id: 'static-6',
      question: 'วิธีการตรวจสอบหมายเลขพัสดุ',
      answer: '',
      image: '../../../../assets/images/event-detail/tracking.jpg'
    },
    {
      id: 'static-7',
      question: 'ติดต่อสอบถาม',
      answer: 'Line id: @checkrace<br>FB: @runtourthai<br>โทร. 060 010-4669 (จ.-ศ. 08.30 – 17.00 น.)',
      image: '../../../../assets/images/event-detail/Checkrace-07.jpg'
    }

  ];



  imgeventitemsCarouselOptions = {
    items: 2,
    nav: false,
    navClass: [],
    navText: [],
    margin: 20,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: false,
    dots: true,
    stagePadding: 40,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 3,
      },
      1250: {
        items: 3,
        margin: 30
      }
    }
  }
  retureBack() {
    this.rounter.navigate(['./']);
  }

  async initLineLiff() {

    await liff.init({
      liffId: "1654053317-bYev9rPa" // production
      // liffId: "1655426134-pENEbayN" // dev
    })

    await liff.ready.then(async () => {
      if (liff.isLoggedIn()) {
        this.userProfile = await liff.getProfile();
        this.idToken = await liff.getDecodedIDToken();
        this.email = await liff.getDecodedIDToken().email;
        this.imageProfile = await this.userProfile.pictureUrl;

        await this.afterRegisLine();
      } else {
        if (liff.isInClient()) {
          this.userProfile = await liff.getProfile();
          this.idToken = await liff.getDecodedIDToken();
          this.email = await liff.getDecodedIDToken().email;
          this.imageProfile = await this.userProfile.pictureUrl;

          await this.afterRegisLine();

        } else {
          if (liff.isLoggedIn()) {

          } else {
            await liff.login({ redirectUri: window.location.href });
          }

        }
      }
    })
      .catch((err) => {
        console.log("ERROR LIFF", err.code, err.message);
      });
  }

  afterRegisLine() {
    // console.log('AFTER REGISTER LINE...');
    const user: SocialUser = new SocialUser;
    (window as any).global = window;
    global.Buffer = global.Buffer || require('buffer').Buffer;
    // console.log('user', user);
    user['provider'] = 'LINE';
    user.name = '';
    user.firstName = '';
    user.lastName = '';
    user.email = this.email;
    user.photoUrl = this.imageProfile;
    user.id = this.userProfile.userId;
    user['providerId'] = user.id;
    if (user.email === undefined || user.email === null || user.email === '') {
      this.modalService.open(this.emailNull, { backdrop: 'static' })
    } else {
      this.loginService.createSocialProfile(user).subscribe(
        data => {
          let status = { status: 'success' }
          // console.log('data', data)
          this.tokenStored.saveToken(data.token);
          this.tokenStored.saveUsername(data.username);
          let userProfile = JSON.stringify(data.userProfile);

          this.activeRount();

          // console.log(userProfile);
          this.tokenStored.saveUserProfile(userProfile);
          this.invalidLogin = true;
          this.modalService.dismissAll();
          this.messageEvent.emit(JSON.stringify(status));
          // this.rounter.navigate(['./'])
        }, error => {
          this.invalidLogin = false;
        }
      )
    }
  }

  getAllProvince() {
    this.httpClienService.getAllProvince().subscribe(data => { this.mapProvinceAll(data) });
  }

  mapProvinceAll(data) {
    this.tokenStored.saveAllProvince(JSON.stringify(data));
    // console.log("get list all province", );
  }

  // loginFacebook() {
  //   this.activatedRoute.queryParams.subscribe(params => {
  //     var code = params['code'];
  //     this.code = params['code'];

  //     if (code) {


  //       this.facebookApi.facebookAccesTokenToEventDetail(code).subscribe(response => {


  //         var accessToken = response['access_token'];


  //         this.facebookApi.getUserId(accessToken).toPromise()

  //           .then(userData => {


  //             this.facebookApi.getUserProfile(userData.data.user_id, accessToken).toPromise()
  //               .then(profileData => {

  //                 const userProfile: any = new Object;

  //                 userProfile['provider'] = 'FACEBOOK';
  //                 userProfile['id'] = profileData['id'];
  //                 userProfile['firstName'] = profileData['first_name'];
  //                 userProfile['lastName'] = profileData['last_name'];
  //                 userProfile['email'] = profileData['email'];
  //                 userProfile['photoUrl'] = profileData['picture.data.url'];
  //                 userProfile['providerId'] = profileData['id'];


  //                 this.loginService.createSocialProfile(userProfile)
  //                   .subscribe(data => {

  //                     this.tokenStored.saveToken(data.token);
  //                     this.tokenStored.saveUsername(data.username);
  //                     let userProfile = JSON.stringify(data.userProfile);
  //                     this.tokenStored.saveUserProfile(userProfile);

  //                     // var imgSplit;

  //                     // if (userProfile != null) {
  //                     //   if (JSON.parse(userProfile).imageProfile != null) {
  //                     //     imgSplit = JSON.parse(userProfile).imageProfile.split("_")[0]
  //                     //     if (imgSplit == 'IM') {
  //                     //       this.tokenStored.saveImgProfile(JSON.parse(userProfile).imageProfileUrl);
  //                     //     } else {
  //                     //       this.tokenStored.saveImgProfile(JSON.parse(userProfile).imageProfile);
  //                     //     }
  //                     //   } else {
  //                     //     this.tokenStored.saveImgProfile('https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png');
  //                     //   }
  //                     // }

  //                     this.userProfile = new UsersModel;
  //                     this.messageEvent.emit(JSON.stringify(status));
  //                     const userDetail = this.tokenStored.getUserProfile();
  //                     this.userProfile = userDetail;
  //                     this.userId = this.userProfile.userId;


  //                     this.eventDetail.urlPath = this.tokenStored.getURLEvent();
  //                     this.eventDetail.languageStatus = this.tokenstorage.getLang();
  //                     this.getEventDetail(this.eventDetail);
  //                     this.rountLocation.replaceState(this.tokenStored.getURLPath());
  //                   }, error => { console.log(error); });
  //               });
  //           });
  //       });
  //     } else {
  //       this.activeRount();
  //     }

  //   });
  // }

}