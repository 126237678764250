import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { Order } from '../../model/order.model';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-vrgo-upload-slip',
  templateUrl: './vrgo-upload-slip.component.html',
  styleUrls: ['./vrgo-upload-slip.component.scss']
})
export class VRGoUploadSlipComponent implements OnInit {

  @ViewChild('ErrorMessage', { static: false }) ErrorMessage
  @ViewChild('openUpload', { static: false }) openUpload;

  order: Order;
  userId: number;
  ticketId: number;
  fileToUpload: File = null;
  filename: string = '';
  uploaded: boolean = false;
  fileData: File = null;
  previewUrl: any = null;
  orderNumber: string = '';
  orderId: any = '';
  url = '';
  eventDetail: any;
  price: number;
  birthDate: any;
  btnsubmit: any = '';
  currentLesson: any = '1';
  language: any;
  iconStatus: string = '';
  imagePreviewStatus: boolean = false;
  msgError: string;

  constructor(
    private param: ParameterUrl,
    private service: HttpClientService,
    private httClient: HttpClientService,
    private rounter: Router,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private tokenStored: TokenStorageService,
    private translate: TranslateService,
    private errorMessageModal: NgbActiveModal
  ) {
    translate.addLangs(['th', 'en']);
    translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
      this.language = result;
      this.btnsubmit = this.language.REGISTER_SUMMARY_PAGE.submitPaymentslip;
    });
  }



  ngOnInit() {
    if (this.param.data != null && this.param.data.order != undefined) {
      this.order = this.param.data.order;

      // console.log(' this.order', this.order);
      this.orderNumber = this.order.orderNumber;
      this.order.listOrderDetail[0].birthDate = this.datePipe.transform(this.order.listOrderDetail[0].birthDate, 'dd/MM/yyyy');
      this.orderId = this.order.orderId;
      this.orderId = this.order.orderId;
      const userDetail = this.tokenStored.getUserProfile();
      // console.log(this.tokenStored.getOrderKey());
      if (userDetail != null && userDetail['userId'] != undefined) {
        this.userId = Number(userDetail['userId']);
      }
      this.ticketId = this.order.ticketId;
    } else {
      this.order = new Order;
      this.rounter.navigate(['./']);
    }
    this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
      this.language = result;
      this.btnsubmit = this.language.UPLOAD_SLIP_PAGE.submitPaymentslip;
    });
  }

  openUploadModal() {
    this.modalService.open(this.openUpload, { backdrop: 'static' });
  }

  changeIconStatus() {
    this.iconStatus = '';
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.filename = this.fileToUpload.name;
    if (this.fileToUpload.type == 'image/jpeg') {
      // console.log("UPLOAD SUCCESS");
    } else if (this.fileToUpload.type == 'image/png') {
      // console.log("UPLOAD SUCCESS");
    } else {
      this.msgError = "ไฟล์ต้องเป็น .jpg หรือ .png เท่านั้น";
      this.errorMessageModal = this.modalService.open(this.ErrorMessage, { backdrop: 'static' });
    }
  }

  fileremove() {
    this.filename = null;
  }

  uploadSlip() {
    if (this.filename == '') {
      this.onclickerror();
    } else {
      if (this.fileToUpload.type == 'image/jpeg') {
        this.currentLesson = '2';
        this.btnsubmit = this.language.REGISTER_SUMMARY_PAGE.wait;
        this.service.uploadSlip(this.fileToUpload, this.orderNumber, this.orderId, this.userId, this.ticketId).subscribe(data => {
          // console.log(data);
          if (data['fileDownloadUri'] != '') {
            this.uploaded = true;
            this.modalService.dismissAll();
            this.param.data = {};
            this.param.data.order = this.order;
            this.rounter.navigate(['./paymentDirect']);
          }
        }, error => {
          console.log(error);
        });
      } else if (this.fileToUpload.type == 'image/png') {
        this.currentLesson = '2';
        this.btnsubmit = this.language.REGISTER_SUMMARY_PAGE.wait;
        this.service.uploadSlip(this.fileToUpload, this.orderNumber, this.orderId, this.userId, this.ticketId).subscribe(data => {
          // console.log(data);
          if (data['fileDownloadUri'] != '') {
            this.uploaded = true;
            this.modalService.dismissAll();
            this.param.data = {};
            this.param.data.order = this.order;
            this.rounter.navigate(['./paymentDirect']);
          }
        }, error => {
          console.log(error);
        });
      } else {
        this.msgError = "ไฟล์ต้องเป็น .jpg หรือ .png เท่านั้น";
        this.errorMessageModal = this.modalService.open(this.ErrorMessage, { backdrop: 'static' });
      }
    }
  }
  onclickerror() {
    this.modalService.dismissAll();
    this.msgError = "กรุณาแนบหลักฐานการชำระเงินของท่าน";
    this.errorMessageModal = this.modalService.open(this.ErrorMessage, { backdrop: 'static' });
  }

  close() {
    this.modalService.dismissAll();
  }
  fileProgress(fileInput: any) {
    this.iconStatus = '';
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
    this.iconStatus = 'bounce';
  }
  openImagePreview() {
    this.imagePreviewStatus = !this.imagePreviewStatus;
  }
  closeErrorMessageModal() {
    this.errorMessageModal.close();
  }
}

