export class FunctionUtils{

    getPad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    convertDateToString(date : Date){
        let result = '';
        // console.log(date);
        if(date != null){
            // console.log('converse');
            let dd = this.getPad(date.getDate(), 2);
            let mm = this.getPad((date.getMonth()+1), 2);
            let yyyy = date.getFullYear();
            result = yyyy+'-'+mm+'-'+dd;
            // console.log(result);
        }
        return result
    }

    findStringInObject(inputKey, fieldName, fieldResp, myArray){
        for (let i = 0; i < myArray.length; i++) {
            let element = myArray[i];
            let objKey = Object.keys(element);
            for (const key of objKey) {
                if (key === fieldName) {
                    if(element[fieldName] === inputKey){
                        return element[fieldResp];
                    }
                }
            }
        }
    }

    slice(object:any[], start: number, end: number ): any{
        let result = [];
        let index = 0;
        // console.log(object);
        if(object.length > 0){
            if(object.length < end){
                end = object.length;
            }
            for (start; start<end; start++){
                // console.log(object[start]);
                result.push(object[start]);
                index = index+1;
            }
        }
        return result;
    }
}