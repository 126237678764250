import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtResponse } from '../auth/jwt-response';
import { AuthLoginInfo } from '../auth/login-info';
import { SocialUser } from 'angular4-social-login';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient
  ) {  }
// 

  authenticate(credentials: AuthLoginInfo): Observable<JwtResponse> {
      return this.httpClient.post<JwtResponse>(environment.serviceUrl+'authenticate', credentials, httpOptions);
  }

  createSocialProfile(socialUser: SocialUser): Observable<JwtResponse> {
      return this.httpClient.post<JwtResponse>(environment.serviceUrl+'createSocialProfile', socialUser, httpOptions);
  }

  checkSession(token: JwtResponse): Observable<JwtResponse> {
    return this.httpClient.post<JwtResponse>(environment.serviceUrl+'checkSession', token, httpOptions);
}

validateProviderEmail(code: string): Observable<JwtResponse> {
    return this.httpClient.post<JwtResponse>(environment.serviceUrl+'validateProviderEmail?code='+code, httpOptions);
}

  logOut() {
    sessionStorage.removeItem('username')
  }
}