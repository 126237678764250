import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { TokenStorageService } from '../../auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-vrgo-events',
  templateUrl: './vrgo-events.component.html',
  styleUrls: ['./vrgo-events.component.scss']
})
export class VRGoEventsComponent implements OnInit {

  listEvent = [];
  language: any = {};
  eventName: string;
  listEventComming = [];
  listEventFree = [];

  constructor(
    private httpClienService: HttpClientService,
    private tokenstorage: TokenStorageService,
    public translate: TranslateService,
    private rounter: Router,
  ) {
    translate.addLangs(['th', 'en']);
    translate.reloadLang((this.tokenstorage.getLang() || 'th')).toPromise().then(result => {
      this.language = result;
    });
  }
  ngOnInit() {
    this.listEventHome();
    this.getEventComming();
    this.getEventFree();
  }
  listEventHome() {
    this.httpClienService.listEventHome().subscribe(response => this.mapEventAll(response));
  }
  mapEventAll(Response) {
    this.listEvent = Response;
    // console.log('log ..',this.listEvent.length[6])
  }
  getEventComming() {
    this.httpClienService.getEventByTag('Comming Soon').subscribe(response => this.mapEventComming(response));
  }
  mapEventComming(response) {
    //console.log('mapEventComming >>',response);
    this.listEventComming = response;
  }
  getEventFree() {
    this.httpClienService.getEventByTag('Free').subscribe(response => this.mapEventFree(response));
  }
  mapEventFree(response) {
    //console.log('mapEventFree >>',response);
    this.listEventFree = response;
  }
  openEventId(eventId: any) {
    let eventDetail = this.listEvent.find((x: any) => x.eventId == eventId);
    if (eventDetail) {
      this.rounter.navigate(['/' + eventDetail.urlPath]);
    } else {
      this.rounter.navigate(['/']);
    }
  }

  blogCarouselOptions = {
    items: 2,
    // navClass:[],
    // navText:[],
    nav: false,
    // navClass: ['owl-prev', 'owl-next'],
    // navText: ['<i class="icon-angle-left" style="top:30%"></i>', '<i class="icon-angle-right"></i>'],
    margin: 20,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: false,
    dots: true,
    stagePadding: 40,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 2,
      },
      1250: {
        items: 3,
        margin: 30
      }
    }
  }

}

