import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
	
    
    {
			title: 'บล็อก', type: 'sub', children: [
            { path: '/404', title: '404',  type: 'link' },
            { path: '/page/faq', title: 'FAQ',  type: 'link' },
            { path: '/page/typography', title: 'Typography',  type: 'link' },
            { path: '/maintenance', title: 'Maintenance',  type: 'link' },
            { path: '/page/about-us', title: 'About-us',  type: 'link' },
          ]
    },
    {
      title: 'อืน ๆ',  type: 'sub', children: [
        { path: '/404', title: '404',  type: 'link' },
            { path: '/page/faq', title: 'FAQ',  type: 'link' },
            { path: '/page/typography', title: 'Typography',  type: 'link' },
            { path: '/maintenance', title: 'Maintenance',  type: 'link' },
            { path: '/page/about-us', title: 'About-us',  type: 'link' },
      ]
    }
    
   ]
  
  	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
   
}
