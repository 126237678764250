import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-vrfo-credit-fail',
  templateUrl: './vrfo-credit-fail.component.html',
  styleUrls: ['./vrfo-credit-fail.component.scss']
})
export class VrfoCreditFailComponent implements OnInit {

  constructor(
    private rounter: Router
  ) { }

  ngOnInit() {
  }

  onClickto(){
    this.rounter.navigate(['./'])
  }
}
