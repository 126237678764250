import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { TokenStorageService } from '../../auth/token-storage.service';
import { VRTicket } from '../../model/vrTicket.model';
import { NgbProgressbarConfig} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientService } from '../../service/httpclient.service';
import { DashBoardModel } from '../../model/dashboard.model';
import { RankingModel } from '../../model/ranking.model';
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-vrgo-my-sub-dashboard',
  templateUrl: './vrgo-my-sub-dashboard.component.html',
  styleUrls: ['./vrgo-my-sub-dashboard.component.scss'],
  providers: [NgbProgressbarConfig]
})
export class VrgoMySubDashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private param: ParameterUrl,
    private tokenstorage:TokenStorageService,
    config: NgbProgressbarConfig,
    private clientservice : HttpClientService
  ) { 
    config.height = '20px';
  }
  text: any =
  {
    "Days": "วัน",
    "Hours": "ชั่วโมง",
    "Minutes": "นาที",
    "Seconds": "วินาที",
  };

  endTime: any;
  idUrl :any;
  userProfile :any;
  gender : any ;
  vrTicket :VRTicket;
  sumDistance : any;
  maxDistance : any;
  sumPace :DashBoardModel;
  userRank: DashBoardModel;
  distance: DashBoardModel;
  unit : DashBoardModel ;
  genderRank : DashBoardModel ;
  sumAllDistance: any;
  total:any;
  listTop5Distance :RankingModel [] = [] ;
  listTop5Pace : RankingModel [] = [] ;
  listUserRankPace :  RankingModel [] = [] ;
  listUserRankDistance:  RankingModel [] = [] ;
  cardRankUser : any ='';
  userId: string ;
  listUserRankPaceClass : any [] =[];
  listTopRankClass: any [] =[];
  listTopDistanceClass : any [] =[];
  listUserDistanceClass: any [] =[];
  listNewRunner: any [] =[];
  listEbadgeDetail : any [] =[];
  rankPaceNumber : string ;
  rankTopPaceNumber : string ;
  rankTopDistanceNumber : string ;
  rankUserDistanceNumber : string ;
  sumEvent : number = 0;
  imageUrlEbib : string;
  imageUrlEcert : string;
  eventId  : any;
  ticketId : any;
  orderId: number;
  imageProfile : string; 
  imageEbadge: string =''; 
  imageEbadgestatus: string =''; 
  regisUser : string;

  flagSum : number;

  ngOnInit() {
   this.userProfile =  this.tokenstorage.getUserProfile();
      // //console.log('User >>',this.userProfile.imageProfile);
      this.imageProfile = this.tokenstorage.getUserProfile().imageProfileUrl;
    this.activatedRoute.params.subscribe(routeParams => {
      this.orderId = routeParams.orderId;
    });
    this.activatedRoute.data.subscribe(data => {
      this.vrTicket = JSON.parse(this.tokenstorage.getSessionVrLog());
      this.getEcertData();
      this.getEbibData();
      const ranking = new DashBoardModel ;
      // this.regisUser = this.vrTicket.regisUser
      ranking['userId'] = this.vrTicket.userId;
      ranking['ticketId'] = this.vrTicket.ticketId;
      ranking['vrId'] = this.vrTicket.vrId;
      ranking['sumDistance'] = 0;
      ranking['sumPeriod'] = 0;
      ranking['sumPace'] = 0;
      ranking['genderStatus'] = 'ALL';   
      // //console.log('ranking',JSON.stringify(ranking));
      this.clientservice.getRanking(ranking).subscribe( data => {
        this.mapRanking(data);
      });
    });
  }
  mapRanking(data){
    //console.log('data',data);
    this.tokenstorage.getImgProfile();
    //console.log('getImgProfile',this.tokenstorage.getImgProfile());
    this.sumPace = data.sumPace;
    this.userRank = data.userRank;
    this.distance = data.distance;
    this.total = data.sumDistance;
    this.unit = data.unit;
    this.userId = data.userId;
    this.eventId = data.eventId;
    this.ticketId = data.ticketId;
    this.regisUser = data.userCount;
    this.sumAllDistance = data.sumAllDistance;

    if(data.eventId == 58){
      var currency = this.sumAllDistance;
      var number = Number(currency.replace(/[^0-9.-]+/g,""))
      this.flagSum = number + 36780; // -46753
      // console.log("flagSum",this.flagSum.toString());
    }
    let param = new FormData();
    param.append('userId', this.userId);
    this.getEBadge(param);
    let userObject = new Object();
    userObject['userId'] = this.userId;
    this.getListUserVR(userObject);
    // this.listNewRunner = data.listNewRunner;
    this.genderRank = data.gender;
    if(data.listNewRunner != null && data.listNewRunner.length > 0){
      for(let i = 0 ; i < data.listNewRunner.length; i++ ){
        if(!this.validImageUrl(data.listNewRunner[i].userImage)){
          data.listNewRunner[i].userImage = 'https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
        }
      }
      this.listNewRunner = data.listNewRunner; 
    }
    if(data.listTop5Distance != null && data.listTop5Distance.length > 0){
      this.listTop5Distance = data.listTop5Distance;
    }
    if(data.listTop5Pace != null && data.listTop5Pace.length > 0){
      this.listTop5Pace = data.listTop5Pace;
    }
    if(data.listUserRankPace != null && data.listUserRankPace.length > 0){
      this.listUserRankPace = data.listUserRankPace;
    }
    if(data.listUserRankDistance != null && data.listUserRankDistance.length > 0){
      this.listUserRankDistance = data.listUserRankDistance;
    }
    
    
    this.endTime = new Date(data.eventRegisEndDate).toISOString();
    // //console.log('distance',data.distance)
    this.sumDistance = (Number(data.sumDistance) / data.distance) * 100;
    // this.sumDistance = data.sumDistance;
    this.maxDistance =  data.distance
    //console.log('sumDistance',this.sumDistance )
    if(data.gender == 'M'){
      this.gender = 'ชาย';
    }else{
      this.gender = 'หญิง';
    }
    this.getDistanceTop();
    this.getDistanceUser();
    this.getPaceTop();
    this.getPaceUser();
  }
  getListUserVR(userObject){
    this.clientservice.listUserVRTicket(userObject).subscribe(data => {
      this.mapVRTicket(data);
    });
  }
  mapVRTicket(data: any = []) {
    this.orderId = data.orderId;
    if(data.length != undefined && data.length != null){
      this.sumEvent = data.length;
    }else{
      this.sumEvent = 0;
    }
  }
  changListPaceByGroup(event){
    // //console.log('event',event);
    const ranking = new DashBoardModel ;
      ranking['userId'] = this.vrTicket.userId;
      ranking['ticketId'] = this.vrTicket.ticketId;
      ranking['vrId'] = this.vrTicket.vrId;
      ranking['sumDistance'] = 0;
      ranking['sumPeriod'] = 0;
      ranking['sumPace'] = 0;
      ranking['gender'] = this.genderRank; 
      ranking['genderStatus'] = event; 
      // //console.log('ranking Pace',ranking);
    this.clientservice.getListRankDistance(ranking).subscribe( data => {
      this.mapListPaceByGroup(data);
    });
  }
  mapListPaceByGroup(data){
    this.listTop5Pace = data.listTop5Pace;
    this.listUserRankPace = data.listUserRankPace;
    this.getPaceTop();
    this.getPaceUser();
  }
  changListDistanceByGroup(event){
    // //console.log('event',event);
    const ranking = new DashBoardModel ;
      ranking['userId'] = this.vrTicket.userId;
      ranking['ticketId'] = this.vrTicket.ticketId;
      ranking['vrId'] = this.vrTicket.vrId;
      ranking['sumDistance'] = 0;
      ranking['sumPeriod'] = 0;
      ranking['sumPace'] = 0;
      ranking['gender'] = this.genderRank; 
      ranking['genderStatus'] = event; 
      // //console.log('ranking di',ranking);
    this.clientservice.getListRankDistance(ranking).subscribe( data => {
      this.mapListDistanceByGroup(data);
    });
  }
  mapListDistanceByGroup(data){
    this.listTop5Distance = data.listTop5Distance;
    // //console.log('listTop5Distance >>',this.listTop5Distance);
    this.listUserRankDistance = data.listUserRankDistance;
    this.getDistanceTop();
    this.getDistanceUser();
  }
  getDistanceTop(){
    this.listTopDistanceClass = []
    if(this.listTop5Distance.length != null){
      for(let i=0; i<this.listTop5Distance.length;i++){
        if(!this.validImageUrl(this.listTop5Distance[i].userImage)){
          this.listTop5Distance[i].userImage = 'https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
        }
        if(this.listTop5Distance[i].userId == this.userId){
          this.listTop5Distance[i].userImage = this.tokenstorage.getImgProfile();
          this.cardRankUser = this.listTop5Distance[i].rank;
          this.listTopDistanceClass.push(this.listTop5Distance[i].rank);
        }
      }
    }
    this.rankTopDistanceNumber =  this.listTopDistanceClass[0];
  }
  getDistanceUser(){
    this.listUserDistanceClass = [];
    if(this.listUserRankDistance.length != null){
      for(let i=0; i<this.listUserRankDistance.length;i++){
        if(!this.validImageUrl(this.listUserRankDistance[i].userImage)){
          this.listUserRankDistance[i].userImage = 'https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
        }
        if(this.listUserRankDistance[i].userId == this.userId){
          this.listUserRankDistance[i].userImage = this.tokenstorage.getImgProfile();
          this.cardRankUser = this.listUserRankDistance[i].rank;
          this.listUserDistanceClass.push(this.listUserRankDistance[i].rank);
        }
      }
    }
    this.rankUserDistanceNumber = this.listUserDistanceClass[0];
    // //console.log('rankUserDistanceNumber',this.rankUserDistanceNumber)
  }
  getPaceUser(){
    this.listUserRankPaceClass = [];
    if(this.listUserRankPace.length != null){
      for(let i=0; i<this.listUserRankPace.length;i++){
        if(!this.validImageUrl(this.listUserRankPace[i].userImage)){
          this.listUserRankPace[i].userImage = 'https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
        }
        if(this.listUserRankPace[i].userId == this.userId){
          this.listUserRankPace[i].userImage = this.tokenstorage.getImgProfile();
          this.cardRankUser = this.listUserRankPace[i].rank;
          this.listUserRankPaceClass.push(this.listUserRankPace[i].rank);

        }
      }
    }
    this.rankPaceNumber = this.listUserRankPaceClass[0];
  }
  getPaceTop(){
    this.listTopRankClass = [];
    if(this.listTop5Pace.length != null){
      for(let i=0; i<this.listTop5Pace.length;i++){
        if(!this.validImageUrl(this.listTop5Pace[i].userImage)){
          this.listTop5Pace[i].userImage = 'https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
        }
        if(this.listTop5Pace[i].userId == this.userId){
          this.listTop5Pace[i].userImage = this.tokenstorage.getImgProfile();
          this.cardRankUser = this.listTop5Pace[i].rank;
          this.listTopRankClass.push(this.listTop5Pace[i].rank);
        }
      }
    }
    this.rankTopPaceNumber = this.listTopRankClass[0];

  }

  getEbibData(){
    const param = new Object();
    param['eventId'] =this.vrTicket.eventId;
    param['ticketId'] = this.vrTicket.ticketId;
    param['orderId'] = this.vrTicket.orderId;
    // //console.log('param >>',param);
    this.clientservice.generateEBIB(param).subscribe(respons =>{
      // //console.log(JSON.stringify(respons));
      if(respons.imageUrl != null){
        this.imageUrlEbib = respons.imageUrl;
      }
    })
  }

  downloadEBib(){
    const link = document.createElement('a');
    link.href = this.imageUrlEbib;
    link.setAttribute('visibility','hidden');
    link.download = 'picture';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  getEcertData(){
    const param = new Object();
    param['eventId'] =this.vrTicket.eventId;
    param['ticketId'] = this.vrTicket.ticketId;
    param['orderId'] = this.vrTicket.orderId;
    this.clientservice.generateEcert(param).subscribe(respons =>{
      // console.log(respons);
      // console.log(this.vrTicket);
      if(respons.imageUrl != null){
        if(this.vrTicket.completeStatus == 'INCOMPLETE'){
          this.imageUrlEcert = respons.imageTamplateUrl;
        }else if (this.vrTicket.completeStatus == 'COMPLETE'){
          this.imageUrlEcert = respons.imageUrl;
        }
      }
    });
  }
  downloadECert(){
    const link = document.createElement('a');
    link.href = this.imageUrlEcert;
    link.setAttribute('visibility','hidden');
    link.download = 'picture';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  validImageUrl(path){
    if(path != null && (path.search("facebook") > 0  || path.search("google") > 0)){
      return true;
    }else{
      var imgae = new XMLHttpRequest();
      imgae.open('HEAD',path,false);
      try{
        imgae.send();
      }catch(error){
        return false;
      }
      return imgae.status != 404;
    }
  }
  getEBadge(param){
    this.clientservice.getEBadgeByUser(param).subscribe(response => {
      this.listEbadgeDetail = response['listEbadge'];
      var listEbadgeDetails = []
      if(this.listEbadgeDetail != undefined && this.listEbadgeDetail != null){
        // console.log('listEbadgeDetail',this.listEbadgeDetail);
        for(let i = 0; i < this.listEbadgeDetail.length; i++ ){
          if(this.listEbadgeDetail[i].eventId == this.eventId){
            this.imageEbadge = this.listEbadgeDetail[i].imagePath;
            listEbadgeDetails.push(this.listEbadgeDetail[i].imagePath);
          }
        }
        if(listEbadgeDetails.length > 0){
          this.imageEbadge = listEbadgeDetails[0];
        }
        //console.log('this.imageEbadgestatus',this.imageEbadge);
      }
    });
  }
  uploadback(){
    this.router.navigate(['./my-dashboard']);
  }
}
