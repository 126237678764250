import { Component, Injectable, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { VRTicket } from '../../model/vrTicket.model';
import { Router } from '@angular/router';
import canvasToImage from 'canvas-to-image';


@Component({
  selector: 'vrgo-download-ebib',
  templateUrl: './vrgo-download-ebib.component.html',
  styleUrls: ['./vrgo-download-ebib.component.scss']
})
@Injectable()
export class VRGoDownloadEbibComponent implements AfterViewInit {

  imageUrl : string;

  vrTicket: VRTicket;

  eventId: number;
  ticketId: number;
  orderId: number;
  

  constructor(
    private httpClient : HttpClientService,
    private rounter: Router,
    private param: ParameterUrl
    ) {}

  ngAfterViewInit() {
    if (this.param.data != null && this.param.data.vrTicket != undefined) {
      this.vrTicket = this.param.data.vrTicket;
      this.eventId = this.vrTicket.eventId;
      this.ticketId = this.vrTicket.ticketId;
      this.orderId = this.vrTicket.orderId;
    }

    this.getEbibData();
  }

  getEbibData(){
    const param = new Object();
    param['eventId'] = this.eventId;
    param['ticketId'] = this.ticketId;
    param['orderId'] = this.orderId;
    this.httpClient.generateEBIB(param).subscribe(respons =>{
      // console.log(JSON.stringify(respons));
      if(respons.imageUrl != null){
        this.imageUrl = respons.imageUrl;
      }
    })
  }

  downloadEBib(){
    const link = document.createElement('a');
    link.href = this.imageUrl;
    link.setAttribute('visibility','hidden');
    link.download = 'picture';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  uploadback(){
    this.rounter.navigate(['./userVRTicket']);
  }
}