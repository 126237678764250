import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TokenStorageService } from '../../auth/token-storage.service';
import { FieldProfile } from '../../model/fieldProfile.model';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClientService } from '../../service/httpclient.service';
import { Province } from '../../model/province.model';
import { Amphur } from '../../model/Amphur.model';
import { District } from '../../model/District.model';
import { ZipCode } from '../../model/ZipCode.model';
import { FunctionUtils } from '../../utils/functionUtils';
import { UsersModel } from '../../share-class/UsersModel';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { VRGoMenuComponent } from '../vrgo-menu/vrgo-menu.component';
import { SocialUser } from 'angular4-social-login';
import { AuthenticationService } from '../../service/authentication.service';
import { NgxImageCompressService } from 'ngx-image-compress';

declare var liff: any;


@Component({
  selector: 'app-vrgo-profile',
  templateUrl: './vrgo-profile.component.html',
  styleUrls: ['./vrgo-profile.component.scss']
})
export class VrgoProfileComponent implements OnInit {

  @ViewChild('SuccessMessage', { static: false }) SuccessMessage;
  @ViewChild('errorMessage', { static: false }) errorMessage;
  @ViewChild('errorMsgbox', { static: true }) errorMsgbox;
  @ViewChild('errorMsgbox602', { static: true }) errorMsgbox602;
  @ViewChild('dialogSuccess', { static: false }) dialogSuccess;
  @ViewChild(VRGoMenuComponent, { static: false }) vrgoMenu: VRGoMenuComponent;
  @ViewChild('emailNull', { static: false }) emailNull;
  @Output() messageEvent = new EventEmitter<any>();

  dialogSuccessMessage: string = 'ระบบทำการบันทึกเสร็จเรียบร้อย';
  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: false,
    containerClass: 'theme-dark-blue',
    adaptivePosition: true
  };
  imgProfile: any = 'https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
  fileData: File = null;
  previewUrl: any = null;
  fileToUpload: File = null;
  filename: string = '';
  uploaded: boolean = false;
  submitted = false;
  syscStatus: boolean = true;
  newStatus: boolean = false;

  registerForm: FormGroup;
  addressForm: FormGroup;
  syscProfileForm: FormGroup;

  userProfile: any;
  keyFieldDefaul: Array<any> = [];

  listProvince: Province[] = [];
  listAmphur: Amphur[] = [];
  listDistrict: District[] = [];
  listZipCode: ZipCode[] = [];

  profileModel: UsersModel
  userId: any;
  birthDate: any;
  address: UsersModel;
  province: UsersModel;
  amphure: UsersModel;
  district: UsersModel;
  zipcode: UsersModel;
  birthDateStr: string;

  address1: string;
  provinceCode: string;
  amphureCode: string;
  districtCode: string;

  oldPassword: string = '';
  password: string = '';
  confirmPassword: string = '';

  telLength: number = 0;
  idCardLength: number = 0;

  oldPasswordClass: string = 'form-control';
  oldPasswordClassErrorMsg: string = '';

  passwordClass: string = 'form-control';
  PasswordClassErrorMsg: string = '';

  newpasswordClass: string = 'form-control';
  newPasswordClassErrorMsg: string = '';

  telClass: string = 'form-control';
  telClassErrorMsg: string = '';
  errorMsg: any = '';
  language: any;
  status: any;
  langStatus: any;
  imgSplit: any;
  idToken: any;
  email: any;
  invalidLogin: boolean;



  constructor(
    private fieldprofile: FieldProfile,
    private param: ParameterUrl,
    private clientService: HttpClientService,
    private rounter: Router,
    private tokenStored: TokenStorageService,
    private formBuilder: FormBuilder,
    private fn: FunctionUtils,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private loginService: AuthenticationService,
    private imageCompress: NgxImageCompressService,
  ) {

    // if (liff.isInClient()) {
    //   this.initLineLiff();
    // } else {
    this.activeRount();
    // }

  }

  ngOnInit() {


  }

  get regisForm() { return this.registerForm.controls; }
  get addrForm() { return this.addressForm.controls; }

  userProfilefield() {
    const dataObj = {};
    for (let i = 0; i < this.userProfile.length; i++) {
      this.keyFieldDefaul.push(this.userProfile[i].fieldName);
      let validate: Array<any> = [];
      let patternNum = "^\\d*$";
      let password: any;
      let option: any;
      let limittel;
      if (this.userProfile[i].filedDisabled === 'Y') {
        option = { value: "", disabled: true }
      } else {
        option = { value: "", disabled: false }
      }
      if (this.userProfile[i].fieldRequired === 'Y') {
        validate.push(Validators.required);
        if (this.userProfile[i].fieldDataType === 'NUMBER') {
          validate.push(Validators.pattern(patternNum));


        }
        if (this.userProfile[i].fieldDataType === 'EMAIL') {
          validate.push(Validators.email);
        }

        if (this.userProfile[i].fieldPattern != null && this.userProfile[i].fieldPattern != '') {
          validate.push(Validators.pattern(this.userProfile[i].fieldPattern));
        }

        if (this.userProfile[i].fieldLimit != null || this.userProfile[i].fieldLimit != 0) {
          if (this.userProfile[i].fieldName === 'tel') {
            // this.telLength = this.userProfile[i].fieldLimit;
          } else if (this.userProfile[i].fieldName === 'idCard') {
            // this.idCardLength = this.userProfile[i].fieldLimit;
          }
        }
      }
      dataObj[this.userProfile[i].fieldName] = new FormControl(option, validate);
      if (i == (this.userProfile.length - 1)) {
        this.registerForm = this.formBuilder.group(dataObj);
        this.registerForm = this.formBuilder.group(dataObj);
        this.registerForm.controls['firstName'].setValue(this.profileModel.firstName);
        this.registerForm.controls['lastName'].setValue(this.profileModel.lastName);
        this.registerForm.controls['tel'].setValue(this.profileModel.tel);
        this.registerForm.controls['email'].setValue(this.profileModel.email);
        this.registerForm.controls['idCard'].setValue(this.profileModel.idCard);
        if (this.profileModel.birthDate == null) {
          this.registerForm.controls['birthDate'].setValue(
            this.datePipe.transform('01/01/1990', 'd/M/yyyy'));
        } else {
          this.registerForm.controls['birthDate'].setValue(
            this.datePipe.transform(this.profileModel.birthDate, 'd/M/yyyy'));
        }


        if (this.profileModel.gender == '' || this.profileModel.gender == null) {
          this.profileModel.gender = '==== กรุณาระบุ ====';
        } else {
          this.registerForm.controls['gender'].setValue(this.profileModel.gender);

        }
      }
    }

  }
  buildAddressForm() {
    this.addressForm = this.formBuilder.group({
      address: new FormControl(this.profileModel.address, Validators.required),
      province: new FormControl('', Validators.required),
      amphur: new FormControl('', Validators.required),
      district: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
    });
  }

  buildFormSyscProfile() {
    this.syscProfileForm = this.formBuilder.group({
      checkFacebook: new FormControl(false),
      checkLine: new FormControl(true),
      checkSystem: new FormControl(true),

    });
  }

  ondateValueChange(value: Date, fieldName: String) {
    this.birthDateStr = value.getDate() + '/' + (value.getMonth() + 1) + '/' + value.getFullYear();
    // console.log(fieldName);
    // console.log(this.birthDateStr);
  }


  UpdateUserProfile() {
    // console.log(this.registerForm);
    this.submitted = true;

    let tel = this.registerForm.controls['tel'].value;
    let idCard = this.registerForm.controls['idCard'].value;
    if (tel != null) {
      if (tel.length < this.telLength) {
        this.registerForm.controls['tel'].setErrors({ 'minLength': true });
      }
    }
    if (idCard != null) {
      if (idCard.length < this.idCardLength) {
        this.registerForm.controls['idCard'].setErrors({ 'minLength': true });
      }
    }

    if (this.registerForm.status === 'VALID') {
      const userDetail = this.tokenStored.getUserProfile();
      this.userId = userDetail['userId'];
      var profileModel = {
        userId: this.userId,
        firstName: this.registerForm.controls['firstName'].value,
        lastName: this.registerForm.controls['lastName'].value,
        gender: this.registerForm.controls['gender'].value,
        idCard: this.registerForm.controls['idCard'].value,
        tel: this.registerForm.controls['tel'].value,
        birthDate: this.birthDateStr,
        email: this.registerForm.controls['email'].value,
      };
      this.clientService.validateThaiCharecter(profileModel).subscribe(data => {
        this.errorMsg = data
        // console.log('data' ,this.errorMsg)
        if (this.errorMsg.errorCode === 601) {
          this.modalService.open(this.errorMsgbox, { windowClass: 'popup', backdrop: 'static' });
        }
        if (this.errorMsg.errorCode === 602) {
          this.modalService.open(this.errorMsgbox602, { windowClass: 'popup', backdrop: 'static' });
        }
        if (this.errorMsg.errorCode === 200) {

          // console.log("update user profile ...")
          this.clientService.UpdateUserProfile(profileModel).subscribe(
            response => this.mapUpdateUserProfile(response));
          // this.saveImageProfile();
        }

      });
    }
  }
  updateUserPassword() {
    if (this.oldPassword === '') {
      this.oldPasswordClass = 'form-control input-vrgo-err';
      this.oldPasswordClassErrorMsg = '* กรุณาระบุรหัสผ่านเก่า'
      return;
    } else {
      this.oldPasswordClass = 'form-control input-vrgo';
      this.oldPasswordClassErrorMsg = ''
    }
    if (this.password === '') {
      this.passwordClass = 'form-control input-vrgo-err';
      this.PasswordClassErrorMsg = '* กรุณาระบุรหัสผ่าน'
      return;
    } else {
      this.passwordClass = 'form-control input-vrgo';
      this.PasswordClassErrorMsg = ''
    }
    if (this.confirmPassword === '') {
      this.newpasswordClass = 'form-control input-vrgo-err';
      this.newPasswordClassErrorMsg = '* กรุณายืนยันรหัสผ่าน'
      return;
    } else {
      this.newpasswordClass = 'form-control input-vrgo';
      this.newPasswordClassErrorMsg = ''
    }


    const userDetail = this.tokenStored.getUserProfile();
    this.userId = userDetail['userId'];
    // console.log(this.birthDate);
    var profileModel: any = {
      userId: this.userId,
      firstName: this.registerForm.controls['firstName'].value,
      lastName: this.registerForm.controls['lastName'].value,
      gender: this.registerForm.controls['gender'].value,
      idCard: this.registerForm.controls['idCard'].value,
      tel: this.registerForm.controls['tel'].value,
      birthDate: this.birthDateStr,
      email: this.registerForm.controls['email'].value,
    };

    if (this.password != '' && this.confirmPassword != '') {
      if (this.password == this.confirmPassword) {
        profileModel.newPassword = this.password;
        profileModel.oldPassword = this.oldPassword;
        // console.log('profile =',profileModel)
        this.clientService.updatePassword(profileModel).subscribe(
          response => this.mapUpdatepassword(response)
        );
      } else {
        this.newPasswordClassErrorMsg = 'กรุณากรอกรหัสผ่านให้เหมือนกัน';
        this.passwordClass = 'form-control input-vrgo-err';
        this.newpasswordClass = 'form-control input-vrgo-err';
      }
    }
  }



  closeModel() {
    this.modalService.dismissAll();
  }

  mapUpdatepassword(response) {
    response
    if (response.code == "-101") {
      this.oldPasswordClass = 'form-control input-vrgo-err';
      this.oldPasswordClassErrorMsg = "* รหัสผ่านเก่าไม่ถูกต้อง";
    } else {
      this.openModal();
    }

  }

  UpdateUserAddress() {
    const userDetail = this.tokenStored.getUserProfile();
    this.userId = userDetail['userId'];
    const addr = new UsersModel();
    addr.address = this.addressForm.value['address'];
    addr.amphure = this.addressForm.value['amphur'];
    addr.district = this.addressForm.value['district'];
    addr.province = this.addressForm.value['province'];
    addr.zipcode = this.addressForm.value['zipCode'];
    addr.userId = userDetail['userId'];
    this.clientService.UpdateUserAddress(addr).subscribe(
      response => this.mapUpdateUserAddress(response)
    );
  }

  mapUpdateUserAddress(response) {
    this.mapUserProfileByUsername(this.clientService.getUserProfileByEmail(this.profileModel.email).subscribe(
      data => {
        let userProfile = JSON.stringify(data);
        this.tokenStored.saveUserProfile(userProfile);
      }));
    this.modalService.dismissAll();
    this.dialogSuccessMessage = 'ระบบทำการบันทึกเสร็จเรียบร้อย';
    this.openModal();
  }

  mapUpdateUserProfile(Response) {

    // console.log('mapUpdateUserProfile... ');
    this.mapUserProfileByUsername(this.clientService.getUserProfileByEmail(Response.email).subscribe(
      data => {

        data.expireDate = this.tokenStored.getUserProfile().expireDate;
        // let userProfile = JSON.stringify(data);

        this.tokenStored.saveUserProfile(data);
      }));
    this.modalService.dismissAll();
    this.dialogSuccessMessage = 'ระบบทำการบันทึกเสร็จเรียบร้อย';
    this.openModal();
  }

  submit() {
    this.modalService.dismissAll();
    this.rounter.navigate(['./']);
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  openModal() {
    this.modalService.open(this.SuccessMessage, { backdrop: 'static' });
  }

  mapUserProfileByUsername(email) {
    email
  }

  getAllProvince() {
    this.clientService.getAllProvince().subscribe(data => { this.mapProvinceAll(data) });
  }

  getAmphureByProvince(event) {
    let provinceId = event.target.value;
    this.clientService.getAmphureByProvince(provinceId).subscribe(data => { this.mapAmphure(data) });
  }



  getDistrictByAmphure(event) {
    let amphurId = event.target.value;
    this.clientService.getDistrictByAmphure(amphurId).subscribe(data => { this.mapDistrict(data) });
  }

  getZipcodeByDistrict(event) {
    let districtId = event.target.value;
    let amphureCode = this.addressForm.value.amphur;
    this.clientService.getZipcodeByDistrict(districtId, amphureCode).subscribe(data => { this.mapZipcode(data) });
  }

  changeProvince(provinceId) {
    this.clientService.getAmphureByProvince(provinceId).subscribe(data => { this.mapAmphure(data) });
  }
  changeAmphure(amphureId) {
    this.clientService.getDistrictByAmphure(amphureId).subscribe(data => { this.mapDistrict(data) });
  }
  changeDistrict(districtId, amphure) {
    this.clientService.getZipcodeByDistrict(districtId, amphure).subscribe(data => { this.mapZipcode(data) });
  }

  mapProvinceAll(data: any) {
    this.listProvince = data;

    if (null != this.profileModel.province && undefined != this.profileModel.province) {
      // this.addressForm.controls['province'].setValue(this.profileModel.province);
      this.addressForm.controls['province'].patchValue(this.profileModel.province);
      this.changeProvince(this.profileModel.province);
      this.addressForm.controls['amphur'].setValue(this.profileModel.amphure);
      this.changeAmphure(this.profileModel.amphure);
      this.addressForm.controls['district'].setValue(this.profileModel.district);
      this.changeDistrict(this.profileModel.district, this.profileModel.amphure);
      this.addressForm.controls['zipCode'].setValue(this.profileModel.zipcode);
    }
  }

  mapAmphure(data: any) {
    this.listAmphur = data;
    // console.log(this.listAmphur);
  }

  mapDistrict(data: any) {
    this.listDistrict = data;
    // console.log(this.listDistrict);
  }

  mapZipcode(data: any) {
    this.listZipCode = data;
    this.addressForm.controls['zipCode'].setValue(this.listZipCode[0].zipcode);
  }

  goback() {
    this.rounter.navigate(['./']);
  }

  onChange(event, type) {
    const key = event.key;
    if (type === 'NUMBER') {
      if (!key.match('^-?(0|[1-9]\d*)?$')) {
        event.preventDefault();
      }
    } else if (type === 'TEXT') {
      if (!key.match('^[a-zA-Z]')) {
        event.preventDefault();
      }
    } else if (type === 'TEL') {
      if (!key.match('^-?(0|[1-9+]\d*)?$')) {
        event.preventDefault();
      }
    } else if (type === 'ALL') {
      if (!key.match('^[A-Za-z0-9\.\-\s\\w+,]+$')) {
        event.preventDefault();
      }
    }
  }

  handleFileInput(files: FileList) {
    // console.log('handleFileInput');
    this.fileToUpload = files.item(0);
    if (this.fileToUpload.type == "image/png" || this.fileToUpload.type == "image/jpeg") {
      this.filename = this.fileToUpload.name;
    } else {
      this.dialogSuccessMessage = 'กรุณาเลือกไฟล์ .png หรือ .jpeg';
      this.modalService.open(this.dialogSuccess, { backdrop: 'static' });
    }
  }

  saveImageProfile() {
    if (this.filename != null && this.filename != '') {
      const userProfile = new UsersModel;
      userProfile['userId'] = this.userId;
      this.clientService.editImageProfile(this.fileToUpload, this.userId).subscribe(data => {
        this.mapEditProfile(data);
      }, error => {
        console.log(error);
      });
    }
  }

  mapEditProfile(data) {
    this.clientService.getUserProfileByEmail(this.profileModel.email).subscribe(
      data => {
        if (data) {
          this.tokenStored.saveUserProfile(data);
          this.dialogSuccessMessage = 'ระบบทำการบันทึกเสร็จเรียบร้อย';
          this.openModal();
        }
      })
  }

  fileremove() {
    this.filename = null;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }
  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.imgProfile = reader.result;
    }
  }

  receiveModalPopupError(event) {

  }

  openSysc() {
    this.syscStatus = !this.syscStatus;
  }

  openNew() {
    this.newStatus = !this.newStatus
  }

  public logout() {
    this.tokenStored.signOut();
    this.rounter.navigate(['./']);
  }

  activeRount() {
    this.buildFormSyscProfile();
    this.langStatus = this.tokenStored.getLang();
    this.translate.addLangs(['th', 'en']);
    this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
      this.language = result;

      this.fieldprofile = new FieldProfile();
      this.profileModel = this.tokenStored.getUserProfile();
      // this.imgProfile = this.profileModel.imageProfile;
      if (this.profileModel.imageProfile != null) {
        this.imgSplit = this.profileModel.imageProfile.split("_")[0];
        if (this.imgSplit == 'IM') {
          this.imgProfile = this.profileModel.imageProfileUrl;
        } else {
          this.imgProfile = this.profileModel.imageProfile;
        }
      } else {
        this.imgProfile = 'https://s3-ap-southeast-1.amazonaws.com/web42-assets/images/icons/icons-8-user.png';
      }
      this.userId = this.profileModel.userId;
      // console.log('',this.profileModel)
      if (this.langStatus == 'en') {
        this.fieldprofile.FieldProfile[0].fieldLabel = this.language.REGISTER_FORM_PAGE.customerName;
        this.fieldprofile.FieldProfile[0].fieldPlacehold = this.language.REGISTER_FORM_PAGE.customerName;
        this.fieldprofile.FieldProfile[1].fieldLabel = this.language.REGISTER_FORM_PAGE.lastName;
        this.fieldprofile.FieldProfile[1].fieldPlacehold = this.language.REGISTER_FORM_PAGE.lastName;
        this.fieldprofile.FieldProfile[2].fieldLabel = this.language.REGISTER_FORM_PAGE.dateOfBirth;
        this.fieldprofile.FieldProfile[3].fieldLabel = this.language.REGISTER_FORM_PAGE.gender;
        this.fieldprofile.FieldProfile[3].listFieldObject[0].objectDesc = this.language.REGISTER_FORM_PAGE.female;
        this.fieldprofile.FieldProfile[3].listFieldObject[1].objectDesc = this.language.REGISTER_FORM_PAGE.male;
        this.fieldprofile.FieldProfile[4].fieldLabel = this.language.REGISTER_FORM_PAGE.phone;
        this.fieldprofile.FieldProfile[5].fieldLabel = this.language.REGISTER_FORM_PAGE.mail;
        this.fieldprofile.FieldProfile[6].fieldLabel = this.language.REGISTER_FORM_PAGE.idCard;
        this.userProfile = this.fieldprofile.FieldProfile
        this.status = 'A';
        if (this.status == 'A') {
          this.userProfilefield();
          this.buildAddressForm();
          this.getAllProvince();
        }
      } else if (this.langStatus == 'th') {
        this.fieldprofile.FieldProfileTH[0].fieldLabel = this.language.REGISTER_FORM_PAGE.customerName;
        this.fieldprofile.FieldProfileTH[0].fieldPlacehold = this.language.REGISTER_FORM_PAGE.customerName;
        this.fieldprofile.FieldProfileTH[1].fieldLabel = this.language.REGISTER_FORM_PAGE.lastName;
        this.fieldprofile.FieldProfileTH[1].fieldPlacehold = this.language.REGISTER_FORM_PAGE.lastName;
        this.fieldprofile.FieldProfileTH[2].fieldLabel = this.language.REGISTER_FORM_PAGE.dateOfBirth;
        this.fieldprofile.FieldProfileTH[3].fieldLabel = this.language.REGISTER_FORM_PAGE.gender;
        this.fieldprofile.FieldProfileTH[3].listFieldObject[0].objectDesc = this.language.REGISTER_FORM_PAGE.female;
        this.fieldprofile.FieldProfileTH[3].listFieldObject[1].objectDesc = this.language.REGISTER_FORM_PAGE.male;
        this.fieldprofile.FieldProfileTH[4].fieldLabel = this.language.REGISTER_FORM_PAGE.phone;
        this.fieldprofile.FieldProfileTH[4].fieldPlacehold = this.language.REGISTER_FORM_PAGE.fieldPlaceholdphone;
        this.fieldprofile.FieldProfileTH[5].fieldLabel = this.language.REGISTER_FORM_PAGE.mail;
        this.fieldprofile.FieldProfileTH[6].fieldLabel = this.language.REGISTER_FORM_PAGE.idCard;
        this.fieldprofile.FieldProfileTH[6].fieldPlacehold = this.language.REGISTER_FORM_PAGE.fieldPlaceholdidCard;
        this.userProfile = this.fieldprofile.FieldProfileTH;
        this.status = 'A';
        if (this.status == 'A') {
          this.userProfilefield();
          this.buildAddressForm();
          this.getAllProvince();
        }
      }
    });
  }

  compressFile() {

    let imageCompress;
    this.imageCompress.uploadFile().then(({ image, orientation }) => {

      var currentMinute = new Date().getTime();
      let fileName = "IMAGE_UPLOAD_" + currentMinute + ".jpg";

      this.imageCompress.compressFile(image, orientation, 100, 60).then(result => {
        imageCompress = this.imageCompress.byteCount(result);

        this.previewUrl = result;
        const imageBlob = this.dataURItoBlob(result);
        this.fileToUpload = new File([imageBlob], fileName, { type: "image/jpeg", });
        this.filename = this.fileToUpload.name;
        this.saveImageProfile();
      });
    });
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob(
      [new Uint8Array(array)],
      {
        type: 'image/jpeg',
      },
    );
  }

}
