export class CouponModel {
	couponId : any;
	eventId  : any;
	ticketId : any;
	couponType : any;
	couponMode : any;
	prefix : any;
	suffix : any;
	discountRate : any;
	unitRate : any;
	maxRegister : any;
	qty : any;
	startDate : any;
	expireDate : any;
	status : any;
	descTh : any;
	descEn : any;
	createDate : any;
	createBy : any;
	updateDate : any;
	updateBy : any;
	couponCode : any;
	netAmt : any;
	ticketPrice : any;
	discountAmt : any;
	couponCodeId : any;
	couponCodeStatus : any;
}