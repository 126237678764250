import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductsService } from '../../service/products.service';
import { Observable, of } from 'rxjs';
import { VRGoModalLoginComponent } from '../vrgo-modal-login/vrgo-modal-login.component';
import { TokenStorageService } from '../../auth/token-storage.service';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vrgo-header',
  templateUrl: './vrgo-header.component.html',
  styleUrls: ['./vrgo-header.component.scss']
})
export class VRGoHeaderComponent implements OnInit {
  @ViewChild(VRGoModalLoginComponent, { static: false }) vrGoModal: VRGoModalLoginComponent;

  public showItem: boolean = false;
  invalidLogin: boolean = (this.tokenStored.getUsername() == null ? false : true);
  route;
  statusAccount: boolean = false;
  languageForm: FormGroup;
  language: any;

  constructor(
    private productService: ProductsService,
    public tokenStored: TokenStorageService,
    public location: Location,
    private rounter: Router,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {
    this.buildChangeLanguage();
    if (this.tokenStored.getLang() == 'en') {
      this.languageForm.controls.checkland.setValue(false);
    } else {
      this.languageForm.controls.checkland.setValue(true);
    }
    if (this.tokenStored.getImgProfile() == null) {
      this.tokenStored.saveImgProfile('../../../../assets/images/icon/icons-8-user.png');
    }
    this.translate = translate;
    this.translate.setDefaultLang('th');
    translate.addLangs(['th', 'en']);
    translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
      this.language = result;

    });
    rounter.events.subscribe(val => {
      this.route = location.path();
    });
  }

  ngOnInit() {
    if (this.tokenStored.getUsername() != null) {
      this.invalidLogin = true;
    } else {
      this.invalidLogin = false;
    }

  }
  changeLanguage() {
    var lang;
    if (this.languageForm.controls.checkland.value == false) {
      lang = 'en';
    } else {
      lang = 'th';
    }
    this.tokenStored.getUserProfile();
    this.tokenStored.saveLang(lang);
    window.location.reload();
  }

  public updateCurrency(curr) {
    this.productService.currency = curr;
  }

  account() {
    this.statusAccount = !this.statusAccount;
  }

  buildChangeLanguage() {
    this.languageForm = this.formBuilder.group({
      checkland: new FormControl(true),

    });
  }

}
