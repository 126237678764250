import { Component, OnInit, ViewChild } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TokenStorageService } from '../../auth/token-storage.service';
// import { VRGoModalLoginComponent } from '../vrgo-modal-login/vrgo-modal-login.component';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClientService } from '../../service/httpclient.service';
import { TicketField } from '../../model/ticketField.model';
import { FieldFormDefault } from '../../model/fieldDefault.model';
import { FunctionUtils } from '../../utils/functionUtils';
import { TicketDetail } from '../../model/ticketDetail.model';
import { Order } from '../../model/order.model';
import { OrderDetail } from '../../model/orderDetail.model';
import { OrderObject } from '../../model/orderObject.model';
import { Province } from '../../model/province.model';
import { Amphur } from '../../model/Amphur.model';
import { District } from '../../model/District.model';
import { ZipCode } from '../../model/ZipCode.model';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
import { EventModel } from '../../share-class/EventModel';
import { UsersModel } from '../../share-class/UsersModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CouponCodeModel } from '../../model/CouponCode.model';
import { CouponModel } from '../../model/coupon.model';
import { FacebookPixelService } from '../../service/facebook-pixel.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-vrgo-register-form',
  templateUrl: './vrgo-register-form.component.html',
  styleUrls: ['./vrgo-register-form.component.scss']
})
export class VRGoRegisterFormComponent implements OnInit {

  // @ViewChild(VRGoModalLoginComponent,{static: false}) vrGoModal: VRGoModalLoginComponent;
  @ViewChild('errorMsgbox', { static: true }) errorMsgbox;
  @ViewChild('errorMsgbox602', { static: true }) errorMsgbox602;
  @ViewChild('errorCoupon', { static: true }) errorCoupon;



  invalidLogin: boolean = (this.tokenStored.getUsername() == null ? false : true);
  submitted = false;
  profileModel: UsersModel;

  registerForm: FormGroup;
  specialFieldForm: FormGroup;
  objectFieldForm: FormGroup;
  addressForm: FormGroup;
  couponForm: FormGroup;

  sessionOrder: any = {};
  ticketId: any = null;
  ticketDetail: TicketDetail;

  order: Order;
  orderDetail: OrderDetail;
  orderObject: OrderObject;

  listProvince: Province[] = [];
  listAmphur: Amphur[] = [];
  listDistrict: District[] = [];
  listZipCode: ZipCode[] = [];

  keyFieldDefaul: Array<any> = [];
  keyFieldObject: Array<any> = [];

  shippingStatus: string;
  addressStatus: string;

  userProfile: any;
  firstName: any;
  lastName: any;
  tel: any;
  idCard: any;
  email: any;
  birthDate: any;
  birthDateStr: string;
  gender: any;
  eventDetail: EventModel;

  telLength: number = 0;
  idCardLength: number = 0;

  errorMsg: any = '';
  language: any;
  status: any;
  langStatus: any;
  allCountry: any;
  countryClass: string = 'form-control';
  countryMsg: string = '';
  eventImgItem: any = [];
  listEventImgItem: any = [];

  couponStatus: boolean;
  useCouponStatus: string = '';
  couponMsg: string = '';
  ticketType: string;
  constructor(
    private param: ParameterUrl,
    public tokenStored: TokenStorageService,
    private rounter: Router,
    private fieldFormDefault: FieldFormDefault,
    private clientService: HttpClientService,
    private formBuilder: FormBuilder,
    private fn: FunctionUtils,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private facePixelService: FacebookPixelService
  ) {

    this.listProvince = JSON.parse(this.tokenStored.getAllProvince());
    this.langStatus = this.tokenStored.getLang();
    this.eventDetail = new EventModel;
    this.sessionOrder = JSON.parse(this.tokenStored.getSessionOrder());
    this.ticketField = new Array();

    this.eventDetail = new EventModel();
    if (this.sessionOrder != null) {
      this.eventDetail = this.sessionOrder.eventDetail;
      this.ticketId = this.sessionOrder.ticketId;
      // console.log("eventDetail session >",this.eventDetail);
      if (this.eventDetail.urlPath == "olympic2020") {
        this.facePixelService.load();
      }
    } else {
      if (this.param.data != null && this.param.data != undefined) {
        this.ticketId = this.param.data.ticketId;
        this.eventDetail = this.param.data.eventDetail;
        // console.log("param eventDetail >",this.eventDetail);
        if (this.eventDetail.urlPath == "olympic2020") {
          this.facePixelService.load();
        }
        this.eventImgItem = this.param.data.eventImgItem;
        this.getEventImgItem();
      }
    }

    this.getTicketType();

    if (this.param.data != null && this.ticketId != undefined) {

      this.translate.addLangs(['th', 'en']);
      this.translate.reloadLang((this.tokenStored.getLang() || 'th')).toPromise().then(result => {
        this.language = result;

        this.fieldFormDefault = new FieldFormDefault();

        if (this.langStatus == 'en') {

          this.fieldFormDefault.fieldDefault[0].fieldLabel = this.language.REGISTER_FORM_PAGE.customerName;
          this.fieldFormDefault.fieldDefault[0].fieldPlacehold = this.language.REGISTER_FORM_PAGE.customerName;
          this.fieldFormDefault.fieldDefault[1].fieldLabel = this.language.REGISTER_FORM_PAGE.lastName;
          this.fieldFormDefault.fieldDefault[1].fieldPlacehold = this.language.REGISTER_FORM_PAGE.lastName;
          this.fieldFormDefault.fieldDefault[2].fieldLabel = this.language.REGISTER_FORM_PAGE.dateOfBirth;
          this.fieldFormDefault.fieldDefault[3].fieldLabel = this.language.REGISTER_FORM_PAGE.gender;
          this.fieldFormDefault.fieldDefault[3].listFieldObject[0].objectDesc = this.language.REGISTER_FORM_PAGE.female;
          this.fieldFormDefault.fieldDefault[3].listFieldObject[1].objectDesc = this.language.REGISTER_FORM_PAGE.male;
          this.fieldFormDefault.fieldDefault[4].fieldLabel = this.language.REGISTER_FORM_PAGE.phone;
          this.fieldFormDefault.fieldDefault[5].fieldLabel = this.language.REGISTER_FORM_PAGE.mail;
          this.fieldFormDefault.fieldDefault[6].fieldLabel = this.language.REGISTER_FORM_PAGE.idCard;
          this.ticketField = this.fieldFormDefault.fieldDefault;

        } else if (this.langStatus == 'th') {

          this.fieldFormDefault.fieldDefaultTH[0].fieldLabel = this.language.REGISTER_FORM_PAGE.customerName;
          this.fieldFormDefault.fieldDefaultTH[0].fieldPlacehold = this.language.REGISTER_FORM_PAGE.customerName;
          this.fieldFormDefault.fieldDefaultTH[1].fieldLabel = this.language.REGISTER_FORM_PAGE.lastName;
          this.fieldFormDefault.fieldDefaultTH[1].fieldPlacehold = this.language.REGISTER_FORM_PAGE.lastName;
          this.fieldFormDefault.fieldDefaultTH[2].fieldLabel = this.language.REGISTER_FORM_PAGE.dateOfBirth;
          this.fieldFormDefault.fieldDefaultTH[3].fieldLabel = this.language.REGISTER_FORM_PAGE.gender;
          this.fieldFormDefault.fieldDefaultTH[3].listFieldObject[0].objectDesc = this.language.REGISTER_FORM_PAGE.female;
          this.fieldFormDefault.fieldDefaultTH[3].listFieldObject[1].objectDesc = this.language.REGISTER_FORM_PAGE.male;
          this.fieldFormDefault.fieldDefaultTH[4].fieldLabel = this.language.REGISTER_FORM_PAGE.phone;
          this.fieldFormDefault.fieldDefaultTH[4].fieldPlacehold = this.language.REGISTER_FORM_PAGE.fieldPlaceholdphone;
          this.fieldFormDefault.fieldDefaultTH[5].fieldLabel = this.language.REGISTER_FORM_PAGE.mail;
          this.fieldFormDefault.fieldDefaultTH[6].fieldLabel = this.language.REGISTER_FORM_PAGE.idCard;
          this.fieldFormDefault.fieldDefaultTH[6].fieldPlacehold = this.language.REGISTER_FORM_PAGE.fieldPlaceholdidCard;
          this.ticketField = this.fieldFormDefault.fieldDefaultTH;

        }

        this.status = 'A';

        if (this.status == 'A') {
          this.buildRegisterForm();
          this.buildAddressForm();
          this.mapProvinceAll();
          this.buildCoupon();
          this.getuserProfile();
          this.getAllCountry();
        }

      });

    } else {
      this.rounter.navigate(['./']);
    }

  }

  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: false,
    containerClass: 'theme-dark-blue',
    adaptivePosition: true
  };


  ticketField: TicketField[] = new Array();
  ticketFieldobj: TicketField[] = new Array();

  ngOnInit() {

  }


  getEventImgItem() {
    if (this.eventImgItem != undefined) {
      for (let i = 0; i < this.eventImgItem.length; i++) {
        if (this.eventImgItem[i].imageType == 'EVENT_ITEM') {
          this.listEventImgItem.push(this.eventImgItem[i]);
        }
      }
    }
  }

  getTicketType() {
    if (this.eventDetail['listTicket'] != undefined) {
      for (let j = 0; j < this.eventDetail['listTicket'].length; j++) {
        if (this.ticketId == this.eventDetail['listTicket'][j].ticketId) {
          this.ticketType = this.eventDetail['listTicket'][j].ticketType;
        }
      }
    }
  }

  buildRegisterForm() {
    const ticketObj = new Object();
    const dataObj = {};
    this.sessionOrder = JSON.parse(this.tokenStored.getSessionOrder());
    const userDetail = this.tokenStored.getUserProfile();
    for (let i = 0; i < this.ticketField.length; i++) {
      this.keyFieldDefaul.push(this.ticketField[i].fieldName);
      let validate: Array<any> = [];
      let patternNum = "^\\d*$";
      let option: any;

      if (this.ticketField[i].filedDisabled === 'Y') {
        option = { value: "", disabled: true }
      } else {
        option = { value: "", disabled: false }
      }
      if (this.ticketField[i].fieldRequired === 'Y') {
        validate.push(Validators.required);
        if (this.ticketField[i].fieldDataType === 'NUMBER') {
          validate.push(Validators.pattern(patternNum));
        }
        if (this.ticketField[i].fieldDataType === 'EMAIL') {
          validate.push(Validators.email);
        }
        if (this.ticketField[i].fieldPattern != null && this.ticketField[i].fieldPattern != '') {
          validate.push(Validators.pattern(this.ticketField[i].fieldPattern));
        }

        if (this.ticketField[i].fieldLimit != null || this.ticketField[i].fieldLimit != 0) {
          if (this.ticketField[i].fieldName === 'tel') {
          } else if (this.ticketField[i].fieldName === 'idCard') {
          }
        }
      }

      dataObj[this.ticketField[i].fieldName] = new FormControl(option, validate);

      // console.log("dataObj",dataObj);
      // console.log("length",this.ticketField.length);
      if (i == (this.ticketField.length - 1)) {
        this.registerForm = this.formBuilder.group(dataObj);
        this.firstName = userDetail['firstName'];
        this.lastName = userDetail['lastName'];
        this.email = userDetail['email'];
        this.tel = userDetail['tel'];
        this.idCard = userDetail['idCard'];
        this.birthDate = userDetail['birthDate'];
        this.birthDate = new Date(this.birthDate);
        if (userDetail['gender'] == '') {
          userDetail['gender'] = '==== กรุณาระบุ ====';
        } else {
          this.gender = userDetail['gender'];
        }

        this.setSessionOrder();

        this.registerForm = this.formBuilder.group(dataObj);
        this.registerForm.controls['firstName'].setValue(this.firstName);
        this.registerForm.controls['lastName'].setValue(this.lastName);
        this.registerForm.controls['tel'].setValue(this.tel);
        this.registerForm.controls['email'].setValue(this.email);
        this.registerForm.controls['idCard'].setValue(this.idCard);
        this.registerForm.controls['birthDate'].setValue(this.birthDate);
        // console.log('BD='+this.birthDate);
        this.registerForm.controls['gender'].setValue(this.gender);
      }
    }

    ticketObj['ticketId'] = this.ticketId;
    ticketObj['languageStatus'] = this.tokenStored.getLang();
    this.clientService.getTicketDetail(ticketObj).subscribe(data => {

      this.ticketDetail = data;

      this.couponStatus = this.ticketDetail.couponStatus;
      this.addressStatus = this.ticketDetail.addressStatus;
      this.shippingStatus = this.ticketDetail.shippingStatus;
      let ticketField = data['listTicketTicketField'];

      if (ticketField != null && ticketField.length > 0) {
        // console.log("มี");
        for (let i = 0; i < ticketField.length; i++) {
          const objectField = ticketField[i];
          this.keyFieldObject.push(ticketField[i].fieldName);
          this.ticketField.push(objectField);
        }
        for (let i = 0; i < this.ticketField.length; i++) {
          let validate: Array<any> = [];
          let patternNum = "^\\d*$";
          let option: any;
          if (this.ticketField[i].filedDisabled === 'Y') {
            option = { value: "", disabled: true }
          } else {
            option = { value: "", disabled: false }
          }
          if (this.ticketField[i].fieldRequired === 'Y') {
            validate.push(Validators.required);
            if (this.ticketField[i].fieldDataType === 'NUMBER') {
              validate.push(Validators.pattern(patternNum));
            }
            if (this.ticketField[i].fieldDataType === 'EMAIL') {
              validate.push(Validators.email);
            }
            if (this.ticketField[i].fieldPattern != null && this.ticketField[i].fieldPattern != '') {
              validate.push(Validators.pattern(this.ticketField[i].fieldPattern));
            }
          }

          dataObj[this.ticketField[i].fieldName] = new FormControl(option, validate);

          if (i == (this.ticketField.length - 1)) {
            this.firstName = userDetail['firstName'];
            this.lastName = userDetail['lastName'];
            this.email = userDetail['email'];
            this.tel = userDetail['tel'];
            this.idCard = userDetail['idCard'];
            this.birthDate = userDetail['birthDate'];
            // this.birthDate=new Date();
            this.birthDate = new Date(this.birthDate);
            // this.birthDate = this.fn.convertDateToString(this.registerForm.setValue(this.birthDate));
            if (userDetail['gender'] == '') {
              userDetail['gender'] = '==== กรุณาระบุ ====';
            } else {
              this.gender = userDetail['gender'];
            }
            this.registerForm = this.formBuilder.group(dataObj);

            this.setSessionOrder();

            this.registerForm.controls['firstName'].setValue(this.firstName);
            this.registerForm.controls['lastName'].setValue(this.lastName);
            this.registerForm.controls['tel'].setValue(this.tel);
            this.registerForm.controls['email'].setValue(this.email);
            this.registerForm.controls['idCard'].setValue(this.idCard);
            this.registerForm.controls['birthDate'].setValue(this.birthDate);
            // console.log('BD='+this.birthDate);
            if (this.gender == null) {
              this.gender = '';
            }
            this.registerForm.controls['gender'].setValue(this.gender);
          }

        }
        for (let j = 0; j < this.ticketField.length; j++) {

          if (this.ticketField[j].fieldInputType != 'TEXTHIDE') {
            this.ticketFieldobj.push(this.ticketField[j])
          }

        }

      } else {
        // console.log("ไม่มี");
        // console.log( this.ticketField);

        for (let j = 0; j < this.ticketField.length; j++) {

          if (this.ticketField[j].fieldInputType != 'TEXTHIDE') {
            this.ticketFieldobj.push(this.ticketField[j])
          }

        }
      }
    });
  }

  buildCoupon() {
    this.couponForm = this.formBuilder.group(
      {
        couponCode: new FormControl(''),
        couponDesc: new FormControl('')
      }
    )
  }

  buildAddressForm() {
    this.addressForm = this.formBuilder.group(
      {
        address: new FormControl('', Validators.required),
        province: new FormControl('', Validators.required),
        amphur: new FormControl('', Validators.required),
        district: new FormControl('', Validators.required),
        zipCode: new FormControl('', Validators.required),
        country: new FormControl(''),
      }
    );
  }

  // getAllProvince() {
  //   this.clientService.getAllProvince().subscribe(data => { this.mapProvinceAll(data) });
  // }
  getAllCountry() {
    this.clientService.getAllCountry().subscribe(data => { this.mapAllCountry(data) });
  }
  mapAllCountry(data) {
    this.allCountry = data;
  }
  getAmphureByProvince(event) {
    let provinceId = event.target.value;
    this.clientService.getAmphureByProvince(provinceId).subscribe(data => { this.mapAmphure(data) });
  }

  getDistrictByAmphure(event) {
    let amphurId = event.target.value;
    this.clientService.getDistrictByAmphure(amphurId).subscribe(data => { this.mapDistrict(data) });
  }

  getZipcodeByDistrict(event) {
    let districtId = event.target.value;
    let amphureCode = this.addressForm.value.amphur;
    this.clientService.getZipcodeByDistrict(districtId, amphureCode).subscribe(data => { this.mapZipcode(data) });
  }

  mapProvinceAll() {
    let addressDetail: any;
    addressDetail = this.sessionOrder;

    if (addressDetail != undefined && addressDetail != null) {

      var amphure = (addressDetail.amphureCode || addressDetail.amphure);
      var district = (addressDetail.districtCode || addressDetail.district);
      var province = (addressDetail.provinceCode || addressDetail.province);
      this.addressForm.controls['address'].patchValue(addressDetail.address);
      this.addressForm.controls['province'].patchValue(province);
      this.changeProvince(province);
      this.addressForm.controls['amphur'].setValue(amphure);
      this.changeAmphure(amphure);
      this.addressForm.controls['district'].setValue(district);
      this.changeDistrict(district, amphure);
      this.addressForm.controls['zipCode'].setValue(addressDetail.zipcode);
    }

  }

  mapAmphure(data: any) {
    this.listAmphur = data;
  }

  mapDistrict(data: any) {
    this.listDistrict = data;
  }

  mapZipcode(data: any) {
    this.listZipCode = data;
    this.addressForm.controls['zipCode'].setValue(this.listZipCode[0].zipcode);
  }

  get regisForm() { return this.registerForm.controls; }
  get addrForm() { return this.addressForm.controls; }
  get coupForm() { return this.couponForm.controls; }

  ondateValueChange(value: Date, fieldName: String) {
    if (fieldName == 'birthDate') {
      this.birthDateStr = value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate();
    }
  }

  submitForm() {
    this.submitted = true;
    let formStatus = 'VALID';
    let addressFormStatus: boolean;
    let validCouponStatus: boolean;
    this.order = new Order;
    this.orderDetail = new OrderDetail;
    let couponModel = new CouponModel;
    let arrOrderDetail: Array<OrderDetail> = [];
    let arrOrderObject: Array<OrderObject> = [];

    let tel = this.registerForm.controls['tel'].value;
    let idCard = this.registerForm.controls['idCard'].value;
    if (tel != null) {
      if (tel.length < this.telLength) {
        formStatus = 'INVALID';
        this.registerForm.controls['tel'].setErrors({ 'minLength': true });
      }
    }

    // if (idCard != null) {
    //   if (idCard.length < this.idCardLength) {
    //     formStatus = 'INVALID';
    //     this.registerForm.controls['idCard'].setErrors({ 'minLength': true });
    //   }
    // }

    if (this.langStatus == 'en') {
      if (this.addressForm.controls.country.value == '' || this.addressForm.controls.country.value == null || this.addressForm.controls.country.value == undefined) {
        this.countryClass = 'form-control input-genstar-err';
        this.countryMsg = 'please specify country';
      } else {
        this.countryClass = 'form-control';
        this.countryMsg = '';
      }
    }


    if (this.registerForm.status === 'VALID') {
      let objKey = Object.keys(this.registerForm.value);
      const userDetail = this.tokenStored.getUserProfile();

      if (this.shippingStatus === 'ACTIVE' && this.addressStatus === 'ACTIVE') {
        if (this.addressForm.status === 'VALID' && this.langStatus == 'th') {
          this.order['address'] = this.addressForm.value['address'];
          this.order['province'] = this.fn.findStringInObject(this.addressForm.value['province'], 'provinceCode', 'provinceName', this.listProvince);
          this.order['amphure'] = this.fn.findStringInObject(this.addressForm.value['amphur'], 'amphurCode', 'amphurName', this.listAmphur);
          this.order['amphureCode'] = this.addressForm.value['amphur'];
          this.order['provinceCode'] = this.addressForm.value['province'];
          this.order['districtCode'] = this.addressForm.value['district'];
          this.order['district'] = this.fn.findStringInObject(this.addressForm.value['district'], 'districtCode', 'districtName', this.listDistrict);
          this.order['zipcode'] = this.addressForm.value['zipCode'];
          addressFormStatus = true;
        } else if (this.addressForm.status === 'VALID' && this.langStatus == 'en') {
          this.order['address'] = this.addressForm.value['address'];
          this.order['province'] = this.addressForm.value['province'];
          this.order['amphure'] = this.addressForm.value['amphur'];
          this.order['amphureCode'] = this.addressForm.value['amphur'];
          this.order['provinceCode'] = this.addressForm.value['province'];
          this.order['districtCode'] = this.addressForm.value['district'];
          this.order['district'] = this.addressForm.value['district'];
          this.order['zipcode'] = this.addressForm.value['zipCode'];
          addressFormStatus = true;
        } else {
          addressFormStatus = false;
          formStatus = 'INVALID';
        }
      }
      this.order['userId'] = Number(userDetail['userId']);
      this.order['createBy'] = userDetail['firstName'];
      this.order['eventId'] = this.ticketDetail.eventId;
      this.order['ticketId'] = this.ticketId;
      this.order['price'] = this.ticketDetail.price;
      this.order['priceDesc'] = this.ticketDetail.priceDesc;
      this.order['paymentGatewayFee'] = this.ticketDetail.paymentGatewayFee;
      this.order['vat'] = this.ticketDetail.vat;
      this.order['platformFee'] = this.ticketDetail.platformFee;
      this.order['shippingStatus'] = this.ticketDetail.shippingStatus;
      this.order['shippingAmount'] = this.ticketDetail.shippingFee;
      if (this.langStatus == 'en') {
        this.order['country'] = this.addressForm.controls.country.value;
      } else {
        this.order['country'] = 'ประเทศไทย';
      }
      this.order['eventName'] = this.ticketDetail.eventName;
      this.order['ticketName'] = this.ticketDetail.ticketName;
      this.order['distance'] = this.ticketDetail.distance;
      this.order['eventRegisDate'] = this.ticketDetail.eventRegisStartDate;
      this.order['eventRegisEnd'] = this.ticketDetail.eventRegisEndDate;
      this.order['unit'] = this.ticketDetail.unit;
      this.order['startTime'] = this.ticketDetail.startTime;

      this.order['firstName'] = this.registerForm.controls['firstName'].value;
      this.order['lastName'] = this.registerForm.controls['lastName'].value;
      this.order['tel'] = this.registerForm.controls['tel'].value;
      this.order['email'] = this.registerForm.controls['email'].value;
      this.order['idCard'] = this.registerForm.controls['idCard'].value;
      this.order['birthDate'] = this.birthDateStr;
      this.order['gender'] = this.registerForm.controls['gender'].value;

      for (const key of objKey) {
        this.orderObject = new OrderObject;
        if (this.keyFieldDefaul.includes(key)) {
          this.orderDetail[key] = this.registerForm.value[key];
          if (key === 'birthDate') {
          }
        }
        if (this.keyFieldObject.includes(key)) {
          let fieldId = this.fn.findStringInObject(key, 'fieldName', 'ticketFieldId', this.ticketField);
          var fieldObject = this.ticketField.filter(t => t.ticketFieldId === fieldId)[0];
          var fieldObjectDesc = fieldObject.listFieldObject.filter(t => t.objectValue == this.registerForm.value[key]);
          if (fieldObject['ticketObjectStatus'] == "OBJECT") {
            this.orderObject['ticketObjectStatus'] = fieldObject['ticketObjectStatus'];
            this.orderObject['ticketObjectImage'] = fieldObject['ticketObjectImage'];
            this.orderObject['fieldLabel'] = fieldObject['fieldLabel'];
            this.orderObject['fieldName'] = fieldObject['fieldName'];
          } else {
            this.orderObject['fieldLabel'] = fieldObject['fieldLabel'];
          }
          this.orderObject['ticketId'] = this.ticketId;
          this.orderObject['ticketFieldId'] = fieldId;
          this.orderObject['objectValue'] = this.registerForm.value[key];
          if (fieldObjectDesc.length > 0) {
            this.orderObject['objectDesc'] = fieldObjectDesc[0].objectDesc;
          }
          this.orderObject['fieldInputType'] = fieldObject['fieldInputType'];
          if (fieldObject['fieldInputType'] == "TEXTHIDE") {
            this.orderObject['objectValue'] = fieldObject['fieldLabel'];
            this.orderObject['fieldLabel'] = fieldObject['fieldLabel'];
            this.orderObject['objectDesc'] = fieldObject['fieldLabel'];
            if (!fieldObject['ticketObjectStatus']) {
              this.orderObject['ticketObjectImage'] = fieldObject['ticketObjectImage'];
            }
          }
          this.orderObject['status'] = 'ACTIVE';
          arrOrderObject.push(this.orderObject);
        }
      }
      this.orderDetail['status'] = 'ACTIVE';
      this.orderDetail['createBy'] = this.tokenStored.getUsername();
      this.orderDetail.listOrderDetailObject = arrOrderObject;
      // console.log("🚀 ~ file: vrgo-register-form.component.ts:633 ~ VRGoRegisterFormComponent ~ submitForm ~ arrOrderObject:", arrOrderObject)
      arrOrderDetail.push(this.orderDetail);
      this.order.listOrderDetail = arrOrderDetail;
      // console.log("🚀 ~ file: vrgo-register-form.component.ts:645 ~ VRGoRegisterFormComponent ~ submitForm ~ arrOrderDetail:", arrOrderDetail)
    } else {
      formStatus = 'INVALID';
    }


    if (this.coupForm.couponCode.value != '' && (addressFormStatus == true || this.eventDetail.eventCategory == 'RUNNING')) {
      const couponCodeModel = new CouponCodeModel;
      couponCodeModel['couponCode'] = this.coupForm.couponCode.value;
      couponCodeModel['ticketId'] = this.ticketId;
      this.clientService.checkCouponCode(couponCodeModel).subscribe(data => {
        couponModel = data;

        // console.log('coupon model ', couponModel);

        if (couponModel.status == 'EXPIRE') {
          if (this.langStatus == 'th') {
            this.couponMsg = 'คูปองหมดอายุการใช้งาน';
          } else {
            this.couponMsg = 'Coupon has expired';
          }
          this.modalService.open(this.errorCoupon, { windowClass: 'popup', backdrop: 'static' });
          formStatus = 'INVALID';
        } else if (couponModel.status == 'NOTYET') {
          if (this.langStatus == 'th') {
            this.couponMsg = 'ยังไม่ถึงวันที่ใช้คูปองได้';
          } else {
            this.couponMsg = 'The date of use of the coupon is not yet reached.';
          }
          this.modalService.open(this.errorCoupon, { windowClass: 'popup', backdrop: 'static' });
          formStatus = 'INVALID';
        } else if (couponModel.status == 'MAXLIMIT') {
          if (this.langStatus == 'th') {
            this.couponMsg = 'ยอดผู้ใช้คูปองครบกำหนด';
          } else {
            this.couponMsg = 'Validity of coupon users';
          }
          this.modalService.open(this.errorCoupon, { windowClass: 'popup', backdrop: 'static' });
          formStatus = 'INVALID';
        } else if (couponModel.status == 'BOOK') {
          if (this.langStatus == 'th') {
            this.couponMsg = 'คูปองได้ถูกจองไปแล้ว';
          } else {
            this.couponMsg = 'The coupon has been booked.';
          }
          this.modalService.open(this.errorCoupon, { windowClass: 'popup', backdrop: 'static' });
          formStatus = 'INVALID';
        } else if (couponModel.status == 'USED') {
          if (this.langStatus == 'th') {
            this.couponMsg = 'คูปองได้ถูกใช้ไปแล้ว';
          } else {
            this.couponMsg = 'The coupon has been used.';
          }
          this.modalService.open(this.errorCoupon, { windowClass: 'popup', backdrop: 'static' });
          formStatus = 'INVALID';
        } else if (couponModel.status == 'INACTIVE') {
          if (this.langStatus == 'th') {
            this.couponMsg = 'คูปองไม่สามารถใช้งานได้';
          } else {
            this.couponMsg = 'Coupons cannot be used.';
          }
          this.modalService.open(this.errorCoupon, { windowClass: 'popup', backdrop: 'static' });
          formStatus = 'INVALID';
        } else {
          this.order['couponCode'] = couponModel.couponCode;
          this.order['couponType'] = couponModel.couponType;
          this.order['couponMode'] = couponModel.couponMode;
          this.order['discountAmt'] = couponModel.discountAmt;
          this.order['netAmt'] = couponModel.netAmt;
          this.order['useCouponStatus'] = true;
          this.order['couponId'] = couponModel.couponId;
          this.order['couponCodeId'] = couponModel.couponCodeId;
          if (couponModel.couponMode == 'FREE') {
            this.order['ticketType'] = 'VR_FREE';
          } else if (this.order['netAmt'] == 0) {
            // console.log("ราคา รวม",this.order['netAmt'])
            this.order['ticketType'] = 'VR_FREE';
          } else {
            this.order['ticketType'] = this.ticketType;
          }


          // console.log('ORDER COUPON >>', this.order);

          // console.log('formStatus >> ', formStatus);
          if (formStatus === 'VALID' && this.order['useCouponStatus'] == true) {
            this.validateThaiCharecter();
          }
        }
      });
    } else {
      couponModel = undefined;
      this.order['netAmt'] = 0;
      this.order['useCouponStatus'] = false;
      this.order['ticketType'] = this.ticketType;
    }
    // console.log('formStatus >> ', formStatus);
    if (formStatus === 'VALID' && this.order['useCouponStatus'] == false) {
      this.validateThaiCharecter();
    }
  }

  validateThaiCharecter() {
    this.clientService.validateThaiCharecter(this.order).subscribe(data => {
      this.errorMsg = data
      if (this.errorMsg.errorCode === 601) {
        this.modalService.open(this.errorMsgbox, { windowClass: 'popup', backdrop: 'static' });
      }
      if (this.errorMsg.errorCode === 602) {
        this.modalService.open(this.errorMsgbox602, { windowClass: 'popup', backdrop: 'static' });
      }
      if (this.errorMsg.errorCode === 200) {
        this.param.data = {};
        // console.log('order  >> ', this.order);
        this.param.data.order = this.order;
        this.param.data.eventDetail = this.eventDetail;
        this.param.data.listEventImgItem = this.listEventImgItem;
        this.order['eventDetail'] = this.eventDetail;
        this.order['ticketId'] = this.ticketId;
        let sessionOrder = JSON.stringify(this.order);
        this.tokenStored.saveSessionOrder(sessionOrder);
        this.tokenStored.saveForm(this.param.data.order);
        this.tokenStored.saveOrderKey("");
        this.rounter.navigate(['./summaryRegister']);
      }
    });
  }

  closeModel() {
    this.modalService.dismissAll();
  }

  clearForm() {
    let objKey = Object.keys(this.registerForm.value);
    for (const key of objKey) {
      this.registerForm.controls[key].setValue('');
    }
    this.submitted = false;
  }

  retureBack() {
    // console.log("SESSION ORDER...",this.sessionOrder);
    if (this.eventDetail.urlPath == null || this.eventDetail.urlPath == undefined || this.eventDetail.urlPath == '' || this.eventDetail.urlPath == 'null') {
      this.rounter.navigate(['./']);
    } else {
      // this.rounter.navigate(['/eventDetail/'], { queryParams: { name: this.eventDetail.urlPath } });
      this.rounter.navigate(['' + this.eventDetail.urlPath]);
      // history.pushState(this.eventDetail.urlPath , "",""+this.eventDetail.urlPath);
    }
  }

  getuserProfile() {
    this.userProfile = this.tokenStored.getUserProfile();
  }

  changeProvince(provinceId) {
    this.clientService.getAmphureByProvince(provinceId).subscribe(data => { this.mapAmphure(data) });
  }
  changeAmphure(amphureId) {
    this.clientService.getDistrictByAmphure(amphureId).subscribe(data => { this.mapDistrict(data) });
  }
  changeDistrict(districtId, amphure) {
    this.clientService.getZipcodeByDistrict(districtId, amphure).subscribe(data => { this.mapZipcode(data) });
  }
  setSessionOrder() {
    if (this.sessionOrder != null) {
      this.firstName = this.sessionOrder.firstName;
      this.lastName = this.sessionOrder.lastName;
      this.tel = this.sessionOrder.tel;
      this.email = this.sessionOrder.email;
      this.idCard = this.sessionOrder.idCard;
      this.birthDate = new Date(this.sessionOrder.birthDate);
      this.gender = this.sessionOrder.gender;
    }
  }
  onChange(event, type) {
    const key = event.key;
    if (type === 'NUMBER') {
      if (!key.match('^-?(0|[1-9]\d*)?$')) {
        event.preventDefault();
      }
    } else if (type === 'TEXT') {
      if (!key.match('^[a-zA-Z]')) {
        event.preventDefault();
      }
    } else if (type === 'TEL') {
      if (!key.match('^-?(0|[1-9+]\d*)?$')) {
        event.preventDefault();
      }
    } else if (type === 'ALL') {
      if (!key.match('^[A-Za-z0-9\.\-\\\s\\w+,]+$')) {
        event.preventDefault();
        // ^[A-Za-z0-9.-" "s\w+,]+$
      }
    }
  }
}

