import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../auth/token-storage.service';
import { HttpClientService } from '../../service/httpclient.service';
import { EventModel } from '../../share-class/EventModel';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { VRGoModalLoginComponent } from '../vrgo-modal-login/vrgo-modal-login.component';

@Component({
  selector: 'app-vrgo-ticket-info-content',
  templateUrl: './vrgo-ticket-info-content.component.html',
  styleUrls: ['./vrgo-ticket-info-content.component.scss']
})
export class VrgoTicketInfoContentComponent implements OnInit {

  @ViewChild(VRGoModalLoginComponent, { static: false }) vrGoModal: VRGoModalLoginComponent;
  @ViewChild('ticketRegistered', { static: true }) ticketRegistered;

  eventDetail = new EventModel();
  validateRegistered: EventModel;

  constructor(
    private httClient: HttpClientService,
    private tokenStored: TokenStorageService,
    private rounter: Router,
    private modalService: NgbModal,
    private param: ParameterUrl
  ) { }

  ngOnInit() {
    this.buildService();
  }

  buildService() {
    this.getEventDetail();
  }

  getEventDetail() {

    this.eventDetail.urlPath = "olympic2020";
    this.eventDetail.languageStatus = this.tokenStored.getLang();
    this.tokenStored.removeSessionOrder();
    this.httClient.getEventDetail(this.eventDetail).subscribe(response => {
      this.eventDetail = response;
    });

  }

  ticketRegister(ticketId) {

    const userDetail = this.tokenStored.getUserProfile();

    if (userDetail == null) {

      this.vrGoModal.popupConfirm(this.rounter.url);

    } else {

      this.validateRegisteredTicket(ticketId);

    }

  }

  validateRegisteredTicket(ticketId) {

    const userDetail = this.tokenStored.getUserProfile();

    this.eventDetail.userId = userDetail.userId;
    this.eventDetail.ticketId = ticketId;

    this.httClient.validateRegisteredTicket(this.eventDetail).subscribe(
      response => {

        this.validateRegistered = response;
        const userDetail = this.tokenStored.getUserProfile();
        // เช็คตอนที่เราล็อคอินมาก่อนแล้ว แล้วมากิน ticket ที่มี เงื่อนไขดังนี้
        if (userDetail != null && this.validateRegistered.registerStatus == 'SINGLE' && this.validateRegistered.registerStatusDesc == 'REGISTERED') {

          this.openModelValidate();

        } else {

          this.param.data = {};
          this.param.data.eventDetail = this.eventDetail;
          this.param.data.eventImgItem = this.eventDetail.listEventImage;
          this.param.data.ticketId = ticketId;
          this.rounter.navigate(['./registerForm']);

        }
      });
  }

  receiveModalPopupError(event) {

  }

  openModelValidate() {

    this.modalService.dismissAll();
    this.modalService.open(this.ticketRegistered, { windowClass: 'myCustomPopup', backdrop: 'static' })

  }
 

  openEventDetail() {

    this.rounter.navigate(['/' + "olympic2020"]);

  }

}
