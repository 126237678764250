export class UserProfileModel {
    address: any;
    amphure: any;
    authType: any;
    birthDate: any;
    code: any;
    createBy: any;
    createDate: any;
    district: any;
    email: any;
    errorMessage: any;
    firstName: any;
    gender: any;
    idCard: any;
    imageProfile: any;
    imageProfileUrl: any;
    invalidUser: any;
    lastName: any;
    listUserProvider: any;
    message: any;
    middleName: any;
    newPassword: any;
    oldPassword: any;
    password: any;
    provider: any;
    providerId: any;
    province: any;
    registerStatus: any;
    status: any;
    tel: any;
    updateBy: any;
    updateDate: any;
    userId: any;
    validate: any;
    zipcode: any;
    expireDate : any;
}