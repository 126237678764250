import { RankingModel } from '../model/ranking.model';
export class DashBoardModel {
userId : any;	
ticketId: any;	
vrId: any;	
eventId: any;	
orderId: any;		

userName: any;
email: any;
userImage: any;

eventRegisEndDate: any;
eventImage: any;
eventName: any;

distance: any;
ticketname: any;
gender: any;

sumDistance: any;
sumPeriod: any;
sumPace: any;
genderStatus: any;

sumAllDistance: any;

listTop5Distance: RankingModel [] = [];
listTop5Pace: RankingModel [] = [];
listUserRankDistance: RankingModel [] = [];
listUserRankPace: RankingModel [] = [];
}