import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vrgo-privacypolicyEn',
  templateUrl: './vrgo-privacypolicyEn.component.html',
  styleUrls: ['./vrgo-privacypolicyEn.component.scss']
})
export class VrgoPrivacypolicyEnComponent implements OnInit {

  constructor(
   private router : Router
  ) { }

  ngOnInit() {
  }

  onClickto(){
    this.router.navigate(['']);
  }

}
