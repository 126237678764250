import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { VRGoEventsComponent } from './vrgo/component/vrgo-events/vrgo-events.component';
import { VRGoMainComponent } from './vrgo/component/vrgo-main/vrgo-main.component';
import { VRGoEventDetailComponent } from './vrgo/component/vrgo-event-detail/vrgo-event-detail.component';
import { VRGoRegisterFormComponent } from './vrgo/component/vrgo-register-form/vrgo-register-form.component';

import { VrgoPaymentsResultComponent } from './vrgo/component/vrgo-payments-result/vrgo-payments-result.component';

import { VRGoSummaryRegisterComponent } from './vrgo/component/vrgo-summary-register/vrgo-summary-register.component';
import { VRGoUploadSlipComponent } from './vrgo/component/vrgo-upload-slip/vrgo-upload-slip.component';
import { VRGoOrderTransactionComponent } from './vrgo/component/vrgo-order-transaction/vrgo-order-transaction.component';
import { VRGoCreditCard2C2PComponent } from './vrgo/component/vrgo-credit-card/vrgo-credit-card.component';
import { VRGoOrderDetailComponent } from './vrgo/component/vrgo-order-detail/vrgo-order-detail.component';
import { VRGoUSERVRTicketComponent } from './vrgo/component/vrgo-user-vr-ticket/vrgo-user-vr-ticket.component';
import { VrgoLogUploadComponent } from './vrgo/component/vrgo-log-upload/vrgo-log-upload.component';
import { VrgoProfileComponent } from './vrgo/component/vrgo-profile/vrgo-profile.component';
import { VRGoDownloadEbibComponent } from './vrgo/component/vrgo-download-ebib/vrgo-download-ebib.component';
import { VRGoDownloadECertComponent } from './vrgo/component/vrgo-download-ecert/vrgo-download-ecert.component';
import { VrfoCreditFailComponent } from './vrgo/component/vrfo-credit-fail/vrfo-credit-fail.component';
import { VrfoCreditSuccessComponent } from './vrgo/component/vrfo-credit-success/vrfo-credit-success.component';
import { VRGoEbadgeComponent } from './vrgo/component/vrgo-e-badge/vrgo-e-badge.component';
import { VrgoEventPeviewComponent } from './vrgo/component/vrgo-event-peview/vrgo-event-peview.component';
import { VrgoFaqComponent } from './vrgo/component/vrgo-faq/vrgo-faq.component';
import { VrgoMySubDashboardComponent } from './vrgo/component/vrgo-my-sub-dashboard/vrgo-my-sub-dashboard.component';
import { VrgoMyDashboardComponent } from './vrgo/component/vrgo-my-dashboard/vrgo-my-dashboard.component';
import { VrgoTourisMemberComponent } from './vrgo/component/vrgo-touris-member/vrgo-touris-member.component';
import { VrgoCustomEventComponent } from './vrgo/component/vrgo-custom-event/vrgo-custom-event.component';
import { VrgoUploadAllEventComponent } from './vrgo/component/vrgo-upload-all-event/vrgo-upload-all-event.component';
import { VrgoTicketOnlyComponent } from './vrgo/component/vrgo-ticket-only/vrgo-ticket-only.component';
import { VrgoPrivacypolicyComponent } from './vrgo/component/vrgo-privacypolicy/vrgo-privacypolicy.component';
import { AuthSocialGuard } from './vrgo/auth/auth-social.guard';
import { AuthLiffUploadGuard } from './vrgo/auth/auth-liff-upload.guard';
import { AuthLiffDashboardGuard } from './vrgo/auth/auth-liff-dashboard.guard';
import { AuthLiffProfileGuard } from './vrgo/auth/auth-liff-profile.guard';
import { VrgoPrivacypolicyEnComponent } from './vrgo/component/vrgo-privacypolicy-en/vrgo-privacypolicyEn.component';
import { VrCallbackComponent } from './vrgo/component/vr-callback/vr-callback.component';
export const routes: Routes = [

  {
    path: '',
    component: VRGoMainComponent,
    canActivate: [AuthSocialGuard]
  },
  {
    path: 'flagofnation',
    component: VrgoTicketOnlyComponent
  },
  {
    path: 'events',
    component: VrgoCustomEventComponent,
    canActivate: [AuthSocialGuard],
    pathMatch: 'full',
    data: {
      title: "Events"
    }
  },
  {
    path: 'eventDetailPreview',
    component: VrgoEventPeviewComponent,
    data: {
      title: "Events"
    }
  },
  {
    path: 'registerForm',
    component: VRGoRegisterFormComponent,
    data: {
      title: "register"
    }
  },
  {
    path: 'paymentDirect',
    component: VrgoPaymentsResultComponent,
    data: {
      title: "paymentDirect"
    }
  },
  {
    path: 'summaryRegister',
    component: VRGoSummaryRegisterComponent,
    data: {
      title: "summary"
    }
  },
  {
    path: 'paymentUploadSlip',
    component: VRGoUploadSlipComponent,
    data: {
      title: "upload slip"
    }
  },
  {
    path: 'userOrderTransaction',
    component: VRGoOrderTransactionComponent,
    data: {
      title: "order transaction"
    }
  },
  {
    path: 'logVrupLoad',
    component: VrgoLogUploadComponent,
    data: {
      title: "order transaction"
    }
  },
  {
    path: 'userVRTicket',
    component: VRGoUSERVRTicketComponent,
    data: {
      title: "VR ticket"
    }
  },
  {
    path: 'creditCard2C2P',
    component: VRGoCreditCard2C2PComponent,
    data: {
      title: "payment"
    }
  },
  {
    path: 'orderDetail',
    component: VRGoOrderDetailComponent,
    data: {
      title: "order detail"
    }
  },
  {
    path: 'my-account',
    component: VrgoProfileComponent,
    data: {
      title: "My Account",
      path: "my-account"
    },
    canActivate: [AuthSocialGuard]
  },
  {
    path: 'downloadEbib',
    component: VRGoDownloadEbibComponent,
    data: {
      title: "download Ebib"
    }
  },
  {
    path: 'downloadEcert',
    component: VRGoDownloadECertComponent,
    data: {
      title: "download Ecert"
    }
  },
  {
    path: 'paymentFail',
    component: VrfoCreditFailComponent,
    data: {
      title: "paymentFail"
    }
  },
  {
    path: 'paymentSuccess',
    component: VrfoCreditSuccessComponent,
    data: {
      title: "paymentSuccess"
    }
  },
  {
    path: 'e-badge',
    component: VRGoEbadgeComponent,
    data: {
      title: "e-badge"
    }
  },
  {
    path :'privacypolicy',
    component:VrgoPrivacypolicyEnComponent,
  },
  {
    path :'data-policy',
    component:VrgoPrivacypolicyComponent,
  },
  {
    path: 'faq',
    component: VrgoFaqComponent,
    data: {
      title: 'Faq'
    }
  },
  {
    path: 'my-dashboard',
    component: VrgoMyDashboardComponent,
    data: {
      title: 'My Dashboard',
      path: "my-dashboard"
    },
    canActivate: [AuthSocialGuard]
  },
  {
    path: 'Touris-Member',
    component: VrgoTourisMemberComponent,
    data: {
      title: 'Touris Member'
    }
  },
  {
    path: 'my-sub-dashboard/:orderId/:userId',
    component: VrgoMySubDashboardComponent,
    data: {
      title: 'My sub Dashboard'
    }
  },
  {
    path: 'upload-vr',
    component: VrgoUploadAllEventComponent,
    data: {
      title: "Up load Vr",
      path: "upload-vr"
    },
    // canActivate: [AuthSocialGuard, AuthLiffUploadGuard],
    canActivate: [AuthSocialGuard]
  },
  {
    path: 'callback',
    component: VrCallbackComponent,
    data: {
      title:'callback'
    }
  },
  {
    path: ':name',
    component: VRGoEventDetailComponent,
    data: {
      title: "Events"
    },
    canActivate: [AuthSocialGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
