import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { Order } from '../../model/order.model';
import { ParameterUrl } from '../../share-class/ParameterUrl';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vrgo-order-detail',
  templateUrl: './vrgo-order-detail.component.html',
  styleUrls: ['./vrgo-order-detail.component.scss']
})
export class VRGoOrderDetailComponent implements OnInit {

  @ViewChild('errorMessage', { static: false }) errorMessage;
  @ViewChild('successMessage', { static: false }) successMessage;
  @ViewChild('openUpload', { static: false }) openUpload;

  order: Order;
  orderNumber: string = '';
  orderId: number;
  userId: number;
  ticketId: number;
  fileToUpload: File = null;
  filename: string = '';
  uploaded: boolean = false;
  fileData: File = null;
  previewUrl: any = null;
  iconStatus : string = '';
  imagePreviewStatus : boolean = false;
  msgError: string = "";

  constructor(
    private param: ParameterUrl,
    private httClient: HttpClientService,
    private modalService: NgbModal,
    private router: Router,
    private tokenStored: TokenStorageService,
    private errorMessageModal: NgbActiveModal
  ) { }



  ngOnInit() {
    if (this.param.data != null && this.param.data.order != undefined) {
      this.order = this.param.data.order;
      // console.log('order : '+JSON.stringify(this.order));
      this.orderNumber = this.order.orderNumber;
      this.orderId = this.order.orderId;
      const userDetail = this.tokenStored.getUserProfile();
      if (userDetail != null && userDetail['userId'] != undefined) {
        this.userId = Number(userDetail['userId']);
      }
      this.ticketId = this.order.ticketId;
      // console.log('orderNumber = ',this.orderNumber);
      // console.log('orderId = ',this.orderId);
      if (this.orderNumber != null && this.orderId != null) {
        let param = new Object();
        param['orderId'] = this.orderId;
        param['orderNumber'] = this.orderNumber;
        this.httClient.getUserOrderTransaction(param).subscribe(data => {
          this.order = data;
          // console.log(JSON.stringify(this.order));
        })
      }
    } else {
      this.order = new Order;
      this.router.navigate(['/my-dashboard']);
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.filename = this.fileToUpload.name;
    if(this.fileToUpload.type == 'image/jpeg'){
      // console.log("UPLOAD SUCCESS");
    }else if(this.fileToUpload.type == 'image/png'){
      // console.log("UPLOAD SUCCESS");
    }else{
      this.msgError = "ไฟล์ต้องเป็น .jpg หรือ .png เท่านั้น";
      this.errorMessageModal = this.modalService.open(this.errorMessage, { backdrop: 'static' });
    }
  }
  fileremove() {
    this.filename = null;
  }

  uploadSlip() {
    if (this.filename == '') {
      this.onclickerror();
    } else {
      if(this.fileToUpload.type == 'image/jpeg'){
        this.httClient.uploadSlip(this.fileToUpload, this.orderNumber, this.orderId, this.userId, this.ticketId).subscribe(data => {
          // console.log(data);
          if (data['fileDownloadUri'] != '') {
            this.uploaded = false;
            this.onclickSuccess();
          }
        }, error => {
          console.log(error);
        });
      }else if(this.fileToUpload.type == 'image/png'){
        this.httClient.uploadSlip(this.fileToUpload, this.orderNumber, this.orderId, this.userId, this.ticketId).subscribe(data => {
          // console.log(data);
          if (data['fileDownloadUri'] != '') {
            this.uploaded = false;
            this.onclickSuccess();
          }
        }, error => {
          console.log(error);
        });
      }else{
        this.msgError = "ไฟล์ต้องเป็น .jpg หรือ .png เท่านั้น";
        this.errorMessageModal = this.modalService.open(this.errorMessage, { backdrop: 'static' });
      }
      
    }

  }

  retureBack() {
    this.router.navigate(['./my-dashboard']);
  }

  onclickerror() {
    this.msgError = "กรุณาแนบหลักฐานการชำระเงินของท่าน";
    this.errorMessageModal = this.modalService.open(this.errorMessage, { backdrop: 'static' });
  }

  onclickSuccess() {
    this.modalService.open(this.successMessage, { backdrop: 'static' });
  }

  onclickOk() {
    this.modalService.dismissAll();
    this.router.navigate(['./my-dashboard']);
  }

  close() {
    this.modalService.dismissAll();
  }

  closeErrorMessageModal(){
    this.errorMessageModal.close();
  }

  openUploadModal(){
    this.modalService.open(this.openUpload, {backdrop: 'static'});
  }
  changeIconStatus(){
    this.iconStatus = '';
  }

  fileProgress(fileInput: any) {
    this.iconStatus = '';
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
    this.iconStatus = 'bounce';
  }
  openImagePreview(){
    this.imagePreviewStatus = !this.imagePreviewStatus;
  }
}

